import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '@sputnik/auth'
import { PATHNAME_CONSTS } from '@sputnik/resources/constants'

const RequireAuth = () => {
  const { isAuthExpired, isAuthenticated } = useAuth()
  const location = useLocation()

  if (isAuthenticated && !isAuthExpired()) {
    return <Outlet />
  } else {
    return (
      <Navigate
        state={{ referrer: location?.pathname }}
        to={{
          pathname: PATHNAME_CONSTS.AUTH_PATH,
          search: location?.search ?? '',
        }}
      />
    )
  }
}

export default RequireAuth
