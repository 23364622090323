import { useEffect, useState } from 'react'

const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const [pageWidth, setPageWidth] = useState(typeof window === 'undefined' ? 0 : window.innerWidth)

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent

    pageWidth < 840 ? setMobile(true) : setIsDesktop(true)

    const handleResize = () => {
      const newPageWidth = typeof window === 'undefined' ? 0 : window.innerWidth
      if (newPageWidth !== pageWidth) {
        setPageWidth(newPageWidth)

        const isMobile = !!(userAgent.match(/Android|iPhone|iPad|iPod|IEMobile|Opera Mini/i) || pageWidth < 840)
        const isDesktop = !!((!isMobile && !userAgent.match(/SSR/i)) || pageWidth >= 840)

        setMobile(isMobile)
        setIsDesktop(isDesktop)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [pageWidth])

  return { isDesktop, isMobile }
}

export default useDeviceDetect
