import { useMutation } from '@apollo/client'

import { useCanary } from '@bill/canary.react'
import { useForm } from '@divvy-web/skylab.form'

import { isPhoneNumberVerified } from '@sputnik/components/utils'
import { initialValues } from '@sputnik/pages/FormPages/initialValues'
import NoopMutation from '@sputnik/pages/gql/mutations/NoopMutation.gql'
import { CREDIT_APPLICATION, PAGE_NAME_STRINGS } from '@sputnik/resources/constants'
import { matchEmail } from '@sputnik/utils/dataUtils'
import { getPageMutationQuery, getPageMutationVariables } from '@sputnik/utils/mutationUtils'

import useGetIsLoggedInAsAuthSigner from './useGetIsLoggedInAsAuthSigner'

const noop = () => new Promise((resolve) => resolve())

// pages that do not have textinputs
const noTextInputPages = [
  PAGE_NAME_STRINGS.PAGE_REVIEW_AND_SIGN,
  PAGE_NAME_STRINGS.PAGE_REVIEW_AND_VALIDATE,
  PAGE_NAME_STRINGS.PAGE_DASHBOARD,
  PAGE_NAME_STRINGS.PAGE_SIGNUP,
]

export const useGetAutoSaveMutation = ({ appId, pageName }) => {
  const { formValues, getInitialValue, getFormValue, validationErrors } = useForm()
  const noTextInputPage = noTextInputPages.includes(pageName)
  const showMfaLogic = useCanary('show-mfa-logic')
  const { PAGE_DASHBOARD, PAGE_COMPANY_OWNERS, PAGE_AUTHORIZED_SIGNER, PAGE_REVIEW_AND_SIGN } = PAGE_NAME_STRINGS
  const isAuthSignerPage = pageName === PAGE_AUTHORIZED_SIGNER
  const isReviewAndSignPage = pageName === PAGE_REVIEW_AND_SIGN
  const loggedInUserIsAuthorizedSigner = useGetIsLoggedInAsAuthSigner({ authSignerEmail: getFormValue('email') })
  const gqlMutation = pageName === PAGE_DASHBOARD ? NoopMutation : getPageMutationQuery(pageName, false) // mutation function for this mutation won't be returned by this hook. See return logic
  const gqlValues = noTextInputPage ? initialValues({}, CREDIT_APPLICATION) : initialValues({}, pageName)

  Object.keys(gqlValues).forEach((key) => (gqlValues[key] = formValues?.[key]))

  validationErrors &&
    Object.keys(validationErrors).forEach((key) =>
      getInitialValue(key) ? (gqlValues[key] = getInitialValue(key)) : delete gqlValues[key],
    )

  showMfaLogic &&
    (isAuthSignerPage || isReviewAndSignPage) &&
    loggedInUserIsAuthorizedSigner &&
    !isPhoneNumberVerified(getFormValue) &&
    (gqlValues['phoneNumber'] = getInitialValue('phoneNumber'))

  const gqlPageValues = { [pageName]: gqlValues }
  const gqlVariables =
    pageName === PAGE_DASHBOARD ? {} : getPageMutationVariables(pageName, gqlPageValues, appId, false)

  const saveMutation = useMutation(gqlMutation, {
    variables: { ...gqlVariables.variables, target: 'SAVE_AND_EXIT' },
  })

  if ([...noTextInputPages, PAGE_COMPANY_OWNERS]?.includes(pageName)) return [noop]
  return saveMutation
}

export default useGetAutoSaveMutation
