import React from 'react'

import { FormattedMessage } from '@divvy-web/i18n'
import { FormCheckboxInput } from '@divvy-web/skylab.form'

interface AutoFillAdminInfoToggleProps {
  autoFillAdminDetails?: boolean
  toggleAutoFillAdminInfo: () => void
}

const AutoFillAdminInfoToggle = ({ autoFillAdminDetails, toggleAutoFillAdminInfo }: AutoFillAdminInfoToggleProps) => {
  return (
    <FormCheckboxInput
      isSmall
      checkboxLabel={
        <FormattedMessage
          defaultMessage="Auto fill with your admin's information"
          id='sputnik.AuthorizedSignerForm__fwKNQU'
        />
      }
      checked={autoFillAdminDetails}
      className='autofill-admin-info-toggle fs-unmask'
      control={undefined}
      dataTestId='authorized-signer-admin-info-Toggle'
      formDataTestId={undefined}
      name='_autoFillAdminInfoToggle'
      onChange={toggleAutoFillAdminInfo}
    />
  )
}

export default AutoFillAdminInfoToggle
