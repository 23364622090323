import React, { cloneElement, useState } from 'react'
import { bool, func, node, object, oneOfType, string } from 'prop-types'

import { Dropdown } from '@divvy-web/skylab.dropdown'
import { FormDateInput, FormDropdown, useForm } from '@divvy-web/skylab.form'

import { DropdownInput } from '@sputnik/components'
import { MAJOR_ACCOUNT_CHANGE } from '@sputnik/resources/constants'

import { formElementWrapperStyles } from './FormElementWrapperStyles'

const FormElementWrapper = ({
  children,
  errorCaption,
  hasError = false,
  isCurrency = false,
  isDate = false,
  isMaskedFormInput = false,
  isSuggestion = false,
}) => {
  const {
    props: { name, onBlur, onFocus, placeholder, readOnly },
    type,
  } = children

  const { getFormValue, validationErrors } = useForm()
  const currentValue = getFormValue(name)
  const [hasBlurred, setHasBlurred] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const isMac = getFormValue('_recordType') === MAJOR_ACCOUNT_CHANGE

  let fieldType = 'TextInput'
  if ([FormDropdown, DropdownInput, Dropdown].includes(type)) fieldType = 'Dropdown'
  if (type === FormDateInput) fieldType = 'DateInput'

  const defaultProps = {
    darkened: false,
    onBlur: (event) => {
      setIsFocused(false)
      setHasBlurred(true)
      !!onBlur && onBlur(event)
    },

    onFocus: () => {
      setIsFocused(true)
      !!onFocus && onFocus()
    },
  }

  const isActivated = isFocused || hasBlurred
  const showDatePlaceholderGuide = isActivated && isDate
  const datePlaceHolderGuide = 'MM/DD/YYYY'
  let additionalProps = {}

  if (isCurrency) additionalProps = { placeholder: isActivated ? '$0' : placeholder }
  if (fieldType === 'DateInput' || isDate) {
    const shownPlaceholder = `${currentValue}${datePlaceHolderGuide.slice(currentValue?.length)}`
    additionalProps = {
      placeholder: isActivated ? shownPlaceholder : placeholder,
      placeholderguide: isActivated ? 'MM/DD/YYYY' : '',
    }
  }
  if (hasError) additionalProps = { ...additionalProps, errorCaption, hasError }

  const element = cloneElement(children, { ...defaultProps, ...additionalProps })

  return (
    <div
      css={formElementWrapperStyles({
        fieldType,
        isCurrency,
        isDate,
        isFieldEmpty: !currentValue,
        isFieldValid: !hasBlurred || !(validationErrors?.[name] || hasError),
        isFocused,
        isMac,
        isMaskedFormInput,
        isSuggestion,
        readOnly,
        showDatePlaceholderGuide,
      })}
    >
      {element}
    </div>
  )
}

FormElementWrapper.propTypes = {
  children: oneOfType([string, func, object, node]),
  errorCaption: oneOfType([string, node]),
  hasError: bool,
  isCurrency: bool,
  isDate: bool,
  isMaskedFormInput: bool,
  isSuggestion: bool,
}

export default FormElementWrapper
