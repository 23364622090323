import { css } from '@emotion/core'

import { curry } from '@divvy-web/utils'

export const businessPageStyles = curry(
  (isReview, { mq, type }) => css`
    [class^='business-info-section-text'] {
      margin-bottom: calc(var(--tri-space-250) * -1);
      ${type.triFontDesktopBodyMediumEmphasis}
      ${mq.xSmallMaxWidth({ marginBottom: 0 })}
    }

    #business-name-fields.field-row {
      margin-bottom: var(--tri-space-200);
      ${mq.xxSmallMaxWidth({ minHeight: isReview ? '0' : 'var(--tri-space-2200)' })}
    }

    #business-website-fields.field-row > .field-container {
      ${mq.xSmallMaxWidth({ gap: '0' })}
    }
  `,
)

export const websiteFieldStyles = ({ isFormValid, websiteFieldClicked }) => css`
  .website-field-wrapper {
    margin-bottom: ${!isFormValid && websiteFieldClicked ? 'var(--tri-space-250)' : 'var(--tri-space-100)'};
  }
`
