import React, { ReactNode } from 'react'
import { CreditApplication, CreditApplicationStatus } from '_gql'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'

import useDeviceDetect from '@sputnik/hooks/useDeviceDetect'
import { MAJOR_ACCOUNT_CHANGE } from '@sputnik/resources/constants'

import StatusTileMobileView from './StatusTileMobileView'
import StatusTileWebView from './StatusTileWebView/StatusTileWebView'

interface StatusTileProps {
  creditApp?: CreditApplication
  moreInfoInReview?: boolean
  title: ReactNode
  primaryInfo: ReactNode
  secondaryInfo?: ReactNode
  image: ReactNode
  children?: ReactNode
  isAnErrorTile?: boolean
}

const StatusTile = ({
  creditApp,
  title,
  primaryInfo,
  secondaryInfo,
  image,
  children,
  moreInfoInReview,
  isAnErrorTile = false,
}: StatusTileProps) => {
  const { isMobile } = useDeviceDetect()
  const [makeTestId] = useNameFormatter('StatusTile')
  const { businessInfo, name = '', status, recordType } = creditApp || {}
  const businessName = businessInfo?.legalBusinessName || name

  const getWarningStatuses = (recordType) => {
    if (!status) return []

    if (recordType === MAJOR_ACCOUNT_CHANGE) {
      return [
        CreditApplicationStatus.AWAITING_SIGNATURE,
        CreditApplicationStatus.IN_PROGRESS,
        CreditApplicationStatus.CORRECTION_REQUESTED,
        CreditApplicationStatus.NOT_STARTED,
      ]
    }

    return [
      CreditApplicationStatus.CORRECTION_REQUESTED,
      moreInfoInReview ? '' : CreditApplicationStatus.MORE_INFO_NEEDED, // moreInfoInReview is a special case where we don't want to show the warning icon
    ]
  }

  const isWarningStatus = getWarningStatuses(recordType).includes(status as CreditApplicationStatus)

  if (isMobile) {
    return (
      <div data-testid={makeTestId('mobile')}>
        <StatusTileMobileView
          businessName={businessName}
          image={image}
          isWarningStatus={isWarningStatus || isAnErrorTile}
          primaryInfo={primaryInfo}
          secondaryInfo={secondaryInfo}
          title={title}
        >
          {children}
        </StatusTileMobileView>
      </div>
    )
  }

  return (
    <div data-testid={makeTestId('web')}>
      <StatusTileWebView
        businessName={businessName}
        image={image}
        isWarningStatus={isWarningStatus || isAnErrorTile}
        primaryInfo={primaryInfo}
        secondaryInfo={secondaryInfo}
        title={title}
      >
        {children}
      </StatusTileWebView>
    </div>
  )
}

export default StatusTile
