import React from 'react'

import { useCanary } from '@bill/canary.react'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormTextInput, useForm } from '@divvy-web/skylab.form'
import Spinner from '@divvy-web/skylab.spinner'
import TextLink from '@divvy-web/skylab.textlink'

import { FormButtons, TimeoutWarningModal } from '@sputnik/components'
import FormElementWrapper from '@sputnik/components/FormInputs/FormElementWrapper'
import FormEmailInput from '@sputnik/components/FormInputs/FormEmailInput'
import PrivacyPolicyLink from '@sputnik/components/PrivacyPolicyLink'
import RecaptchaDisclaimer from '@sputnik/components/RecaptchaDisclaimer'
import TermsAndConditionsLink from '@sputnik/components/TermsAndConditionsLink'
import {
  getFormattedNumOfEmployees,
  getFormattedUSPhoneNumber,
  normalizeSmartQuotesAndEmdashes,
} from '@sputnik/components/utils'
import { PAGE_NAME_STRINGS, PRODUCT_CONST_STRINGS, URL } from '@sputnik/resources/constants'

import SignUpBusinessFields from './SignUpBusinessFields'
import { signupFormStyles, signupWrapperStyles, usCountryCodeStyles } from './SignUpFormStyles'

interface SignUpFormProps {
  onNextClick: (values: Record<string, any>) => void
  isSubmitting: boolean
  verifyReCaptcha: (token: string) => void
}

type Form = {
  entityType: string
  numOfEmployees: string
}

type UseForm = <T extends object>() => Record<'getFormValue', <K extends keyof T>(x: K) => T[K]> &
  Record<'formIsValid', boolean> &
  Record<'formValues', T> &
  Record<'getValidationErrors', <K extends keyof T>(x: K) => string | string[]>

const SignUpForm = ({ onNextClick, isSubmitting, verifyReCaptcha }: SignUpFormProps) => {
  const { formIsValid, formValues, getValidationErrors } = (useForm as unknown as UseForm)<Form>()
  const { BILL_PRIVACY_NOTICE, BILL_TERMS_AND_CONDITIONS } = URL
  const showWebBank = useCanary('show-web-bank')
  const { BILL_SPEND_AND_EXPENSE } = PRODUCT_CONST_STRINGS

  const numOfEmployeesError = getValidationErrors('numOfEmployees')
  const getNumOfEmployeesErrorCaption = () => {
    if (typeof numOfEmployeesError === 'string') return numOfEmployeesError
    if (typeof numOfEmployeesError === 'object') return numOfEmployeesError?.[0]
    return null
  }

  const usCountryCode = (
    <div css={usCountryCodeStyles}>
      <FormattedMessage
        defaultMessage='🇺🇸 +1'
        id='sputnik.SignUpForm__sShckg'
      />
    </div>
  )

  return (
    <div css={signupWrapperStyles}>
      <>
        {isSubmitting && (
          <div
            className='spinner-wrapper-form'
            data-testid='new-signup-spinner'
          >
            <Spinner centered />
          </div>
        )}
        {!isSubmitting && (
          <section
            css={signupFormStyles}
            id='new-signup-form'
          >
            <div className='field-row'>
              <div className='field-container fs-unmask'>
                <FormElementWrapper>
                  <FormTextInput
                    className='fs-mask'
                    label={
                      <FormattedMessage
                        defaultMessage='First name'
                        id='sputnik.SignUpForm__pONqz8'
                      />
                    }
                    maxLength={30}
                    name='firstName'
                    normalize={normalizeSmartQuotesAndEmdashes}
                    placeholder={
                      <FormattedMessage
                        defaultMessage='First name'
                        id='sputnik.SignUpForm__pONqz8'
                      />
                    }
                  />
                </FormElementWrapper>
              </div>
              <div className='field-container fs-unmask'>
                <FormElementWrapper>
                  <FormTextInput
                    className='fs-mask'
                    label={
                      <FormattedMessage
                        defaultMessage='Last name'
                        id='sputnik.SignUpForm__txUL0F'
                      />
                    }
                    maxLength={30}
                    name='lastName'
                    normalize={normalizeSmartQuotesAndEmdashes}
                    placeholder={
                      <FormattedMessage
                        defaultMessage='Last name'
                        id='sputnik.SignUpForm__txUL0F'
                      />
                    }
                  />
                </FormElementWrapper>
              </div>
            </div>
            <div className='field-row'>
              <div className='field-container fs-unmasked phone-number'>
                <FormElementWrapper>
                  <FormTextInput
                    className='fs-mask'
                    inputMode='numeric'
                    label={
                      <FormattedMessage
                        defaultMessage='Mobile phone number'
                        id='sputnik.SignUpForm__L0ijz8'
                      />
                    }
                    leftIcon={usCountryCode}
                    maxLength={14}
                    name='phoneNumber'
                    normalize={getFormattedUSPhoneNumber}
                    placeholder={
                      <FormattedMessage
                        defaultMessage='Phone number'
                        id='sputnik.SignUpForm__jdJhOL'
                      />
                    }
                  />
                </FormElementWrapper>
              </div>
              <div className='field-container fs-unmask'>
                <FormEmailInput
                  autoComplete='on'
                  className='signup-email-field fs-mask'
                  label={
                    <FormattedMessage
                      defaultMessage='Your business email'
                      id='sputnik.SignUpForm__IaW/hJ'
                    />
                  }
                  name='email'
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Your business email'
                      id='sputnik.SignUpForm__IaW/hJ'
                    />
                  }
                  readOnly={true}
                  type='email'
                />
              </div>
            </div>
            {/* Business name > 52 characters causes issues with downstream processes */}
            <div className='field-row fs-unmask'>
              <div className='field-container'>
                <FormElementWrapper>
                  <FormTextInput
                    label={
                      <FormattedMessage
                        defaultMessage='Legal business name'
                        id='sputnik.SignUpForm__U/+zt/'
                      />
                    }
                    maxLength={52}
                    name='legalBusinessName'
                    normalize={normalizeSmartQuotesAndEmdashes}
                    placeholder={
                      <FormattedMessage
                        defaultMessage='Legal business name'
                        id='sputnik.SignUpForm__U/+zt/'
                      />
                    }
                  />
                </FormElementWrapper>
              </div>
              <div className='field-container'>
                <FormElementWrapper>
                  <FormTextInput
                    className='numOfEmployees'
                    errorCaption={getNumOfEmployeesErrorCaption()}
                    hasError={!!numOfEmployeesError}
                    inputMode='numeric'
                    label={
                      <FormattedMessage
                        defaultMessage='Number of full time employees'
                        id='sputnik.SignUpForm__9gonsR'
                      />
                    }
                    maxLength={6}
                    name='numOfEmployees'
                    normalize={getFormattedNumOfEmployees}
                    placeholder={
                      <FormattedMessage
                        defaultMessage='Number of full time employees'
                        id='sputnik.SignUpForm__9gonsR'
                      />
                    }
                  />
                </FormElementWrapper>
              </div>
            </div>
            <SignUpBusinessFields
              initialMacBusinessValues={{
                entity: '',
                industry: '',
                naicsCode: '',
              }}
              isMac={false}
              isReview={false}
              readOnly={false}
            />
            <div
              className='acknowledgement fs-unmask'
              data-testid='acknowledgement'
            >
              {!showWebBank && (
                <FormattedMessage
                  defaultMessage="By clicking next, you acknowledge that you’re authorized to provide contact information about your company and any other authorized signer or beneficial owner. You also acknowledge that any contact information provided may be used by {BILL_SPEND_AND_EXPENSE} and/or its affiliates to contact you. By continuing, you agree to {BILL_SPEND_AND_EXPENSE}’s <termsLink>Terms and Conditions</termsLink> and {BILL_SPEND_AND_EXPENSE}'s <privacyLink>Privacy Notice</privacyLink>."
                  id='sputnik.SignUpForm__dUVPpO'
                  values={{
                    privacyLink: (textValue) => (
                      <TextLink
                        href={BILL_PRIVACY_NOTICE}
                        target='_blank'
                      >
                        {textValue}
                      </TextLink>
                    ),
                    BILL_SPEND_AND_EXPENSE,
                    termsLink: (textValue) => (
                      <TextLink
                        href={BILL_TERMS_AND_CONDITIONS}
                        target='_blank'
                      >
                        {textValue}
                      </TextLink>
                    ),
                  }}
                />
              )}
              {showWebBank && (
                <FormattedMessage
                  defaultMessage="By clicking next, you acknowledge that you're authorized to provide contact information about your company and any other authorized signor or beneficial owner. You also acknowledge that any contact information provided may be used to contact you. By continuing, you agree to the {termsLink} and {privacyLink}."
                  id='sputnik.SignUpForm__Ji/+uQ'
                  values={{
                    privacyLink: <PrivacyPolicyLink />,
                    termsLink: <TermsAndConditionsLink />,
                  }}
                />
              )}
            </div>
            <div
              className='recaptcha-wrapper'
              data-testid='recaptcha-wrapper'
            >
              <RecaptchaDisclaimer />
            </div>
            <TimeoutWarningModal pageName={PAGE_NAME_STRINGS.PAGE_SIGNUP} />
          </section>
        )}
      </>
      <div className='submit-wrapper'>
        <FormButtons
          hideExit
          hidePrevious
          dataTestId='signup-'
          disableNext={!formIsValid || !verifyReCaptcha || isSubmitting}
          handleNextClick={() => onNextClick(formValues)}
          showNextSpinner={isSubmitting}
        />
      </div>
    </div>
  )
}

export default SignUpForm
