import React from 'react'

import { FormattedMessage } from '@divvy-web/i18n'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'

import { URL } from '@sputnik/resources/constants'

interface RewardsLinkProps {
  dataTestId?: string
  className?: string
}

const RewardsLink = ({ dataTestId, className }: RewardsLinkProps) => {
  const { BILL_REWARDS_LINK } = URL

  return (
    <TextLink
      className={className}
      dataTestId={dataTestId}
      href={BILL_REWARDS_LINK}
      size={TextLinkSizes.REGULAR}
      target='_blank'
      useDataTestId={dataTestId}
    >
      <FormattedMessage
        defaultMessage='Rewards'
        id='sputnik.RewardsLink__IoFKEy'
      />
    </TextLink>
  )
}

export default RewardsLink
