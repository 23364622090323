import { css } from '@emotion/core'

import { curry } from '@divvy-web/utils'

export const tileCss = curry(
  ({ isRejected }, theme) => css`
    flex: 0 0 400px;

    .Tile {
      height: auto;
      width: 100%;

      .tile-outer-wrapper {
        display: flex;
      }

      .Tile-content {
        background-color: var(--tri-color-fill-primary);
      }

      .drop-tile-content {
        height: 100%;
      }

      .drop-tile-text {
        ${theme.type.triFontDesktopLabelSmall}
        padding: var(--tri-space-200);
      }

      .drop-tile-error {
        ${theme.type.triFontDesktopLabelSmall}
        color: var(--tri-color-text-danger);
        padding: var(--tri-space-200);
      }

      .tile-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;

        .status-title {
          margin-bottom: var(--tri-space-200);

          ${isRejected && 'color: var(--tri-color-text-danger);'}
        }

        .button-container {
          display: flex;
          justify-content: space-between;

          .description-footer {
            ${theme.type.triFontDesktopBodySmallEmphasis};
          }
        }
      }

      .description-wrapper {
        margin-bottom: var(--tri-space-100);
      }

      .rejectionMessage {
        margin-bottom: var(--tri-space-150);
        ${theme.type.triFontDesktopBodyMedium};
        color: var(--tri-color-text-tertiary);
      }

      .description-subheader {
        color: var(--tri-color-text-tertiary);
        margin-bottom: var(--tri-space-200);
      }
    }
  `,
)

export const webCss = curry(
  (theme) => css`
    .tile-title {
      margin-top: var(--tri-space-200);
    }

    .description-subheader {
      ${theme.type.triFontDesktopCurrencyBodyMedium};
    }

    .description-content {
      ${theme.type.triFontDesktopBodyMedium};
    }

    .tile-outer-wrapper {
      gap: var(--tri-space-500);
    }

    .status-title {
      margin-bottom: var(--tri-space-200);
      ${theme.type.triFontDesktopTitleMediumEmphasis}
    }

    img {
      max-width: 150px;
      margin-right: var(--tri-space-300);
    }
  `,
)

export const mobileCss = curry(
  (theme) => css`
    .tile-title {
      margin-top: 0;
    }

    .description-subheader {
      ${theme.type.triFontDesktopCurrencyBodySmall};
    }

    .description-content {
      ${theme.type.triFontDesktopBodySmall}
    }

    .tile-outer-wrapper {
      gap: var(--tri-space-100);
    }

    .status-title {
      margin-bottom: var(--tri-space-50);
      ${theme.type.triFontDesktopTitleSmallEmphasis}
    }

    img {
      max-width: 65px;
      margin-right: var(--tri-space-100);
    }
  `,
)
