import React from 'react'
import { RouteObject } from 'react-router-dom'

import FormWrapper from '@sputnik/components/FormWrapper/FormWrapper'
import RequireAuth from '@sputnik/components/Routes/RequireAuth'
import AcceptingOfferPollingPage from '@sputnik/pages/AcceptingOfferPollingPage'
import ApplicationProcessed from '@sputnik/pages/ApplicationProcessed'
import ApplicationSubmitted from '@sputnik/pages/ApplicationSubmitted'
import Dashboard from '@sputnik/pages/Dashboard'
import GoodFitPage from '@sputnik/pages/GoodFitPage'
import MobileDocumentUpload from '@sputnik/pages/MobileDocumentUpload/MobileDocumentUpload'
import NotGoodFit from '@sputnik/pages/NotGoodFit'
import ResetPassword from '@sputnik/pages/ResetPassword'
import SubmissionErrorPage from '@sputnik/pages/ReviewAndSign/SubmissionErrorPage'
import RightFitSurveyPage from '@sputnik/pages/RightFitSurveyPage'
import SignIn from '@sputnik/pages/SignIn'
import SignUp from '@sputnik/pages/SignUp'
import SubmittingApplicationPollingPage from '@sputnik/pages/SubmittingApplicationPollingPage'
import VerifyCode from '@sputnik/pages/VerifyCode'
import VerifyPassword from '@sputnik/pages/VerifyPassword/VerifyPassword'
import { PATHNAME_CONSTS } from '@sputnik/resources/constants'

export const createSignInRoutes = (): RouteObject[] => {
  return [
    {
      path: PATHNAME_CONSTS.AUTH_PATH,
      element: <SignIn />,
    },
    {
      path: PATHNAME_CONSTS.MIGRATION_PATH,
      element: <SignIn />,
    },
  ]
}

export const createAuthenticationRoutes = (): RouteObject[] => {
  return [
    {
      path: PATHNAME_CONSTS.VERIFY_EMAIL_PATH,
      element: <VerifyCode />,
    },
    {
      path: PATHNAME_CONSTS.VERIFY_CODE_PATH,
      element: <VerifyCode />,
    },
    {
      path: PATHNAME_CONSTS.VERIFY_PASSWORD_PATH,
      element: <VerifyPassword />,
    },
  ]
}

export const createAuthenticatedRoutes = (): RouteObject[] => {
  return [
    {
      element: <RequireAuth />,
      children: [
        ...createDashboardRoutes(),
        ...createApplicationRoutes(),
        ...createRightFitSurveyRoutes(),
        {
          path: PATHNAME_CONSTS.MOBILE_DOCUMENT_UPLOAD_PATH,
          element: <MobileDocumentUpload />,
        },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },
        {
          path: PATHNAME_CONSTS.SIGN_UP_PATH,
          element: <SignUp />,
        },
      ],
    },
  ]
}

const createRightFitSurveyRoutes = (): RouteObject[] => {
  return [
    {
      path: PATHNAME_CONSTS.RIGHT_FIT_SURVEY_PATH,
      element: <RightFitSurveyPage />,
    },
    {
      path: PATHNAME_CONSTS.GOOD_FIT_PATH,
      element: <GoodFitPage />,
    },
    {
      path: PATHNAME_CONSTS.NOT_GOOD_FIT_PATH,
      element: <NotGoodFit />,
    },
  ]
}

const createDashboardRoutes = (): RouteObject[] => {
  return [
    {
      path: '/',
      element: <Dashboard />,
    },
    {
      path: PATHNAME_CONSTS.DASHBOARD_PATH,
      element: <Dashboard />,
    },
  ]
}

const createApplicationRoutes = (): RouteObject[] => {
  return [
    {
      path: '/app/:appId/section',
      children: [
        {
          path: 'success',
          element: <ApplicationSubmitted />,
        },
        {
          path: PATHNAME_CONSTS.SUBMITTING_APPLICATION,
          element: <SubmittingApplicationPollingPage />,
        },
        {
          path: PATHNAME_CONSTS.ACCEPTING_OFFER,
          element: <AcceptingOfferPollingPage />,
        },
        {
          path: PATHNAME_CONSTS.SUBMISSION_ERROR_PATH,
          element: <SubmissionErrorPage />,
        },
        {
          path: 'processed/*',
          element: <ApplicationProcessed />,
        },
        {
          path: '*',
          element: <FormWrapper />,
        },
      ],
    },
    {
      path: '/app/success',
      element: <ApplicationSubmitted />,
    },
  ]
}
