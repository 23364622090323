import React from 'react'

import { FormattedMessage } from '@divvy-web/i18n'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'

import { URL } from '@sputnik/resources/constants'

interface PrivacyPolicyLinkProps {
  dataTestId?: string
  className?: string
}

const PrivacyPolicyLink = ({ dataTestId, className }: PrivacyPolicyLinkProps) => {
  const { BILL_PRIVACY_NOTICE } = URL
  return (
    <TextLink
      className={className}
      dataTestId={dataTestId}
      href={BILL_PRIVACY_NOTICE}
      size={TextLinkSizes.REGULAR}
      target='_blank'
      useDataTestId={dataTestId}
    >
      <FormattedMessage
        defaultMessage='Privacy Policy'
        id='sputnik.PrivacyPolicyLink__vx0nkZ'
      />
    </TextLink>
  )
}

export default PrivacyPolicyLink
