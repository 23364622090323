import React from 'react'
import { css } from '@emotion/core'
import { array, func } from 'prop-types'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import Chip from '@divvy-web/skylab.chip'

const InviteCollaboratorsChipList = ({ allCollaboratorEmails, onEmailRemovalClick, creditApplicationPeopleEmails }) => {
  const [makeTestId, getClassName] = useNameFormatter('InviteCollaboratorsChipList')

  return (
    <div
      css={chipListCss}
      data-testid={makeTestId('')}
    >
      <div>
        <div className={getClassName('title')}>
          <FormattedMessage
            defaultMessage='Current collaborators'
            id='sputnik.InviteCollaboratorsChipList__iSeTDg'
          />
        </div>
        <div className={getClassName('description')}>
          <FormattedMessage
            defaultMessage='You can remove a collaborator and they will no longer have access to view this application, the information they inputed will be saved.'
            id='sputnik.InviteCollaboratorsChipList__x7p2YM'
          />
        </div>
        <div className={getClassName('email-chip-container')}>
          {allCollaboratorEmails?.map((email) => (
            <div
              key={`InviteCollaboratorsChipList-${email}`}
              className={getClassName('chip')}
            >
              {/* TODO: add a tooltip for the disabled state */}
              <Chip
                isTextChip
                disabled={creditApplicationPeopleEmails.includes(email)}
                label={email}
                onClick={() => onEmailRemovalClick(email)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

InviteCollaboratorsChipList.propTypes = {
  allCollaboratorEmails: array,
  creditApplicationPeopleEmails: array,
  onEmailRemovalClick: func,
}

const chipListCss = ({ type }) => css`
  .InviteCollaboratorsChipList-title {
    ${type.triFontDesktopBodyMediumEmphasis}
    margin-top: var(--tri-space-700);
  }

  .InviteCollaboratorsChipList-description {
    margin-bottom: var(--tri-space-400);
  }

  .InviteCollaboratorsChipList-email-chip-container {
    height: 100px;
  }

  .InviteCollaboratorsChipList-chip {
    margin-bottom: var(--tri-space-150);
  }
`

export default InviteCollaboratorsChipList
