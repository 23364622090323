import React from 'react'
import { css } from '@emotion/core'

import { FormattedMessage } from '@divvy-web/i18n'
import TextLink from '@divvy-web/skylab.textlink'

const officeDesk = 'https://app.divvy.co/assets/illustrations/office_desk'
const officeDeskSrcSet = `${officeDesk}@2x.png, ${officeDesk}@1x.png, ${officeDesk}@3x.png`

const officeDeskMobile = 'https://app.divvy.co/assets/illustrations/office-desk-01-mobile'
const officeDeskMobileSrcSet = `${officeDeskMobile}@2x.png, ${officeDeskMobile}@1x.png, ${officeDeskMobile}@3x.png`

const ErrorPage = () => {
  return (
    <div css={pageStyle}>
      <div css={mainContent}>
        <img
          alt='binoculars'
          className='error-page-image'
          data-testid='image-404'
          srcSet={officeDeskSrcSet}
        />
        <img
          alt='office-desk'
          className='error-page-mobile-image'
          data-testid='image-404-mobile'
          srcSet={officeDeskMobileSrcSet}
        />
        <div
          css={msg}
          data-testid='message-404'
        >
          <FormattedMessage
            defaultMessage='Looks like something went wrong, please try again later.'
            id='sputnik.ErrorPage__uU7tNC'
          />
        </div>
        <TextLink
          css={link}
          data-testid='link-to-dashboard'
          href='/'
        >
          <FormattedMessage
            defaultMessage='Back to application dashboard'
            id='sputnik.ErrorPage__MgGyu8'
          />
        </TextLink>
      </div>
    </div>
  )
}

const msg = ({ type }) => css`
  ${type.triFontDesktopTitleSmallEmphasis}
  width: 90%;
  text-align: center;
  margin-bottom: var(--tri-space-400);
`

const mainContent = ({ mq }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .error-page-image {
    ${mq.xxSmallMaxWidth({
      display: 'none',
    })}
  }

  .error-page-mobile-image {
    max-width: 247px;
    padding-bottom: var(--tri-space-400);
    ${mq.xSmallMinWidth({
      display: 'none',
    })}
  }
`

const pageStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const link = css`
  margin-top: var(--tri-space-100);
  text-decoration: none;
`

export default ErrorPage
