import React, { useEffect, useRef } from 'react'
import { func } from 'prop-types'
import { mountRootParcel, start } from 'single-spa'

start()

const unmountIfMounted = (parcelRef) => {
  let parcel = parcelRef.current
  if (!parcel) {
    return
  }
  const status = parcel.getStatus()
  if (status === 'MOUNTED') {
    parcel.unmount()
    parcel = null
  }
}

const FederatedSingleSpaApp = ({ loaderFn, ...moreProps }) => {
  const ref = useRef()
  const parcelRef = useRef()

  useEffect(() => {
    if (!ref.current) {
      return
    }

    loaderFn().then((p) => {
      parcelRef.current = mountRootParcel(p, {
        domElement: ref.current,
        ...moreProps,
      })
    })
    return () => {
      unmountIfMounted(parcelRef)
    }
  }, [loaderFn, moreProps])

  return <div ref={ref} />
}

FederatedSingleSpaApp.propTypes = {
  loaderFn: func,
}

export default FederatedSingleSpaApp
