import { useLazyQuery } from '@apollo/client'

import GetRequiredDocs from '@sputnik/pages/Dashboard/gql/GetRequiredDocs.gql'
import { logError } from '@sputnik/utils/loggerUtils'

const useGetRequiredDocs = (salesforceCreditId) =>
  useLazyQuery(GetRequiredDocs, {
    // Do not update the initial cache so we can compare initially requested docs versus remaining docs
    fetchPolicy: 'no-cache',
    onError: (e) => {
      logError({
        attributes: {
          action: 'GetRequiredDocs',
          message: 'Error while refetching required documents from the uploader',
          result: e,
        },
        eventName: 'Refetch',
      })
    },
    variables: { salesforceCreditId },
  })

export default useGetRequiredDocs
