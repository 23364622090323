import React, { ReactNode } from 'react'
import { css } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'

interface PhoneVerificationMobileViewPopsheetProps {
  isShowing: boolean
  onClose?: () => void
  title: NonNullable<ReactNode>
  actions: ReactNode[]
  children: ReactNode
}
const PhoneVerificationMobileViewPopsheet = ({
  isShowing,
  title,
  actions,
  children,
  onClose,
}: PhoneVerificationMobileViewPopsheetProps) => {
  const [getClassName, makeTestId] = useNameFormatter('PhoneVerificationMobileViewPopsheet')

  return (
    <PopSheetControl isShowing={isShowing}>
      <PopSheet
        actions={
          onClose && (
            <BasicButton
              dataTestId={makeTestId('on-close-btn')}
              type={BASIC_BUTTON_TYPE_OUTLINED}
              onClick={onClose}
            >
              <FormattedMessage
                defaultMessage='Cancel'
                id='sputnik.PhoneVerificationMobileViewPopsheet__47FYwb'
              />
            </BasicButton>
          )
        }
        className={getClassName('popsheet')}
        css={styles}
        footer={<>{actions}</>}
        title={title}
      >
        {children}
      </PopSheet>
    </PopSheetControl>
  )
}

const styles = css`
  [class*='primary-button'] {
    border-radius: 0;
    height: var(--tri-space-600);
    width: 100%;
    border-top: 1px solid var(--neutral-soft);
  }

  [class*='secondary-button'] {
    border-radius: 0;
    height: var(--tri-space-600);
    width: 100%;
    border-top: 1px solid var(--neutral-soft);
  }

  .RequestSmsCodeModal-info-text {
    margin-bottom: var(--tri-space-450);
  }

  .BasicButton-spinner {
    width: fit-content;
  }

  .BasicButton-spinner-progress-svg-small {
    border: none;
  }
`

export default PhoneVerificationMobileViewPopsheet
