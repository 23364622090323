import React from 'react'
import { useNavigate } from 'react-router-dom'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'

import ImageWrapper from '@sputnik/components/ImageWrapper'
import OneColorSidebar from '@sputnik/components/OneColorSidebar/OneColorSidebar'

import { AcceptOfferCss } from './acceptOfferStyles'

const AcceptOffer = () => {
  const [getClassName, makeTestId] = useNameFormatter('AcceptOffer')
  const navigate = useNavigate()

  const booksLadyPath = 'https://app.divvy.co/assets/illustrations/book-02'
  const booksLadySrcSet = `${booksLadyPath}@3x.png, ${booksLadyPath}@2x.png, ${booksLadyPath}@1x.png`

  const onCloseClick = () => {
    navigate('/')
  }

  return (
    <div
      css={AcceptOfferCss}
      data-testid={makeTestId('')}
    >
      <section className={getClassName('main-content-section')}>
        <div
          className={getClassName('text-and-mobile-image')}
          data-testid={makeTestId('text-and-mobile-image')}
        >
          {/* The image directly below is only to be rendered once the mobile media query is hit */}
          <ImageWrapper
            alt='books'
            className='accept-offer-mobile-image'
            imageName='accept-offer-mobile'
          />
          <h1
            className={getClassName('title')}
            data-testid={makeTestId('title')}
          >
            <FormattedMessage
              defaultMessage="Here's what to expect next"
              id='sputnik.AcceptOffer__Npp+53'
            />
          </h1>
          <p
            className={getClassName('body')}
            data-testid={makeTestId('body')}
          >
            <FormattedMessage
              defaultMessage="We’ll verify your identity and business information. This may take 3-5 days. If we need more information, we’ll email you. After you're approved, your credit will be ready to use."
              id='sputnik.AcceptOffer__vfUjyN'
            />
          </p>
          <BasicButton
            dataTestId={makeTestId('dashboard')}
            onClick={onCloseClick}
          >
            <FormattedMessage
              defaultMessage='Close'
              id='sputnik.AcceptOffer__BSij1a'
            />
          </BasicButton>
        </div>
      </section>
      <OneColorSidebar srcImage={booksLadySrcSet} />
    </div>
  )
}

export default AcceptOffer
