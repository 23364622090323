import React from 'react'
import { bool, func, node } from 'prop-types'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'
import { useForm } from '@divvy-web/skylab.form'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'
import TextLink from '@divvy-web/skylab.textlink'

import { popsheetStyles } from './styles'

const ResetPasswordPopsheet = ({ children, headerImage, isShowing, onClose, isLoading, onSubmit, subtitle }) => {
  const [getClassName, makeTestId] = useNameFormatter('ResetPasswordPopsheet')
  const { validationErrors, getFormValue } = useForm()

  const actionButton = (
    <BasicButton
      dataTestId={makeTestId('submit')}
      disabled={!!validationErrors || isLoading}
      showSpinner={isLoading}
      onClick={() => onSubmit(getFormValue('userEmail'))}
    >
      <FormattedMessage
        defaultMessage='Submit'
        id='sputnik.ResetPasswordPopsheet__wSZR47'
      />
    </BasicButton>
  )

  return (
    <PopSheetControl
      css={popsheetStyles}
      isShowing={isShowing}
    >
      <PopSheet
        className={getClassName('')}
        dataTestId={makeTestId('')}
        footer={actionButton}
        subtitle={subtitle}
        title={headerImage}
      >
        <div className={getClassName('')}>
          <TextLink
            className={getClassName('back-button')}
            dataTestId={makeTestId('back-button')}
            onClick={onClose}
          >
            <FormattedMessage
              defaultMessage='Back'
              id='sputnik.ResetPasswordPopsheet__cyR7Kh'
            />
          </TextLink>
          {children}
        </div>
      </PopSheet>
    </PopSheetControl>
  )
}

ResetPasswordPopsheet.propTypes = {
  children: node,
  headerImage: node,
  isShowing: bool,
  isLoading: bool,
  onClose: func,
  onSubmit: func,
  subtitle: node,
}

export default ResetPasswordPopsheet
