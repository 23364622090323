import React from 'react'
import { css } from '@emotion/core'
import { bool, func } from 'prop-types'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_COLOR_NEUTRAL, BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'

import ImageWrapper from '@sputnik/components/ImageWrapper'

const VariableCreditLineInfoMobilePopsheet = ({ isShowing, toggleVariableCreditLineInfoPopsheet }) => {
  const [getClassName, makeTestId] = useNameFormatter('VariableCreditLineInfoMobilePopsheet')

  const dismissButton = (
    <BasicButton
      className={getClassName('variable-popsheet-footer-button')}
      color={BASIC_BUTTON_COLOR_NEUTRAL}
      dataTestId={makeTestId('dismiss-variable-popsheet')}
      type={BASIC_BUTTON_TYPE_FLAT}
      onClick={() => toggleVariableCreditLineInfoPopsheet()}
    >
      <FormattedMessage
        defaultMessage='Dismiss'
        id='sputnik.VariableCreditLineInfoMobilePopsheet__TDaF6J'
      />
    </BasicButton>
  )

  return (
    <PopSheetControl isShowing={isShowing}>
      <PopSheet
        className={getClassName('')}
        css={variableCreditLineMobilePopsheetCss}
        dataTestId={makeTestId('')}
        footer={dismissButton}
        subtitle={
          <FormattedMessage
            defaultMessage="What is a 'variable credit line'?"
            id='sputnik.VariableCreditLineInfoMobilePopsheet__x83Whl'
          />
        }
        title={
          <ImageWrapper
            alt='line-graph-with-data'
            className='popsheet-title-image'
            imageName='pre-approved-dual-offer-data-line-graph'
          />
        }
      >
        <p className={getClassName('variable-popsheet-body-text')}>
          <FormattedMessage
            defaultMessage='This type of credit line features a limit that may increase or decrease over time based on the cash balance of your designated bank account.  We use a secure, digital banking connection to review your account balance(s), determine your limit, and adjust that limit if needed.'
            id='sputnik.VariableCreditLineInfoMobilePopsheet__cpmi2l'
          />
        </p>
      </PopSheet>
    </PopSheetControl>
  )
}

VariableCreditLineInfoMobilePopsheet.propTypes = {
  isShowing: bool,
  toggleVariableCreditLineInfoPopsheet: func,
}

const variableCreditLineMobilePopsheetCss = ({ type }) => css`
  display: flex;
  flex-direction: column;

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-2800);
  }

  .VariableCreditLineInfoMobilePopsheet-popsheet-title-image {
    max-width: var(--tri-space-2800);
  }

  .VariableCreditLineInfoMobilePopsheet-header {
    text-align: center;
  }

  .VariableCreditLineInfoMobilePopsheet-header-subtitle {
    ${type.triFontDesktopTitleMediumEmphasis};
    color: var(--tri-color-text-primary);
  }

  .VariableCreditLineInfoMobilePopsheet-variable-popsheet-footer-button {
    border-radius: 0;
    height: var(--tri-space-1100);
    width: 100%;
    border-top: 1px solid var(--tri-color-stroke-tertiary);
  }
`

export default VariableCreditLineInfoMobilePopsheet
