import { dateStringToEpoch } from '@sputnik/components/utils'
import {
  businessInfoTaxIdValidation,
  cityValidation,
  emailValidation,
  illegalCharactersForBusinessNameMessage,
  illegalCharactersForHumanNameMessage,
  illegalCharactersForPreferredNameMessage,
  legalCharactersForBusinessNamePattern,
  legalCharactersForHumanNamePattern,
  legalCharactersForPreferredNamePattern,
  phoneNumberValidation,
  postalCodeValidation,
  streetValidation,
  websiteValidation,
} from '@sputnik/utils/validationUtils'

const isMigrationOrMac = (attributes) => {
  return attributes._recordType === 'major_account_change' || attributes._recordType === 'migration'
}

const firstOrLastNameValidation = (nameType) => ({
  format: {
    message: illegalCharactersForHumanNameMessage,
    pattern: legalCharactersForHumanNamePattern,
  },
  length: {
    maximum: 30,
    message: `${nameType} name may not have more than 30 characters`,
  },
  presence: {
    message: `Please enter a ${nameType.toLowerCase()} name for the admin`,
  },
})

const formationDateValidation = (value) => {
  const currentDateEpoch = dateStringToEpoch(value)
  const today = new Date()
  if (currentDateEpoch && currentDateEpoch > today?.getTime()) {
    return {
      type: {
        message: 'Formation date cannot be in the future',
        type: () => false,
      },
    }
  }
  return {
    length: {
      is: 10,
      message: 'Please enter a valid date',
    },
    presence: {
      message: 'Please enter a valid date',
    },
  }
}

const preferredFullNameValidation = (attributes) => {
  const adminFirstName = attributes['adminFirstName']
  const adminLastName = attributes['adminLastName']
  const adminNameLength = adminFirstName?.length + adminLastName?.length
  if (adminNameLength > 21) {
    return {
      format: {
        message: illegalCharactersForHumanNameMessage,
        pattern: legalCharactersForHumanNamePattern,
      },
      length: {
        maximum: 21,
        message: 'Full name may not have more than 21 characters',
      },
      presence: {
        message: 'Please enter name for the admin',
      },
    }
  } else {
    return {}
  }
}

const legalBusinessNameValidationErrorMessage = 'Please enter a valid legal business name under 200 characters'
const preferredBusinessNameValidationErrorMessage = 'Please enter a valid preferred business name under 21 characters'

export default {
  adminEmail: (value, attributes) => {
    return isMigrationOrMac(attributes) || emailValidation(value)
  },
  adminFirstName: (value, attributes) => isMigrationOrMac(attributes) || firstOrLastNameValidation('First'),
  adminLastName: (value, attributes) => isMigrationOrMac(attributes) || firstOrLastNameValidation('Last'),
  adminPhoneNumber: (value, attributes) => {
    return isMigrationOrMac(attributes) || phoneNumberValidation
  },
  adminPreferredFullName: (_value, attributes) =>
    isMigrationOrMac(attributes) || preferredFullNameValidation(attributes),
  formationDate: (value, _attributes) => formationDateValidation(value),
  legalBusinessName: {
    format: {
      flags: 'i',
      message: illegalCharactersForBusinessNameMessage,
      pattern: legalCharactersForBusinessNamePattern,
    },
    length: {
      maximum: 200,
      message: legalBusinessNameValidationErrorMessage,
      minimum: 1,
    },
  },
  mailingAddressCity: (_, attributes) => cityValidation(!!attributes?.validateMailAddressFormFields),
  mailingAddressPostalCode: (value, attributes) => {
    if (!attributes?.validateMailAddressFormFields) return null
    return postalCodeValidation({ prefix: 'mailing' })(value, attributes)
  },
  mailingAddressState: (_, attributes) => {
    if (!attributes?.validateMailAddressFormFields) return null
    return {
      presence: {
        message: 'Please select a state',
      },
    }
  },
  mailingAddressStreet: (_, attributes) => streetValidation(!!attributes?.validateMailAddressFormFields, false),
  mailingAddressStreetAdditional: streetValidation(false, false),
  ownership: {
    presence: {
      message: 'Please select a value for ownership',
    },
  },
  physicalAddressCity: cityValidation(),
  physicalAddressPostalCode: postalCodeValidation({ prefix: 'physical' }),
  physicalAddressState: {
    presence: {
      message: 'Please select a state',
    },
  },
  physicalAddressStreet: streetValidation(),
  physicalAddressStreetAdditional: streetValidation(false),
  preferredAccountName: {
    format: {
      flags: 'i',
      message: illegalCharactersForPreferredNameMessage,
      pattern: legalCharactersForPreferredNamePattern,
    },
    length: {
      maximum: 21,
      message: preferredBusinessNameValidationErrorMessage,
      minimum: 1,
    },
  },
  taxIdDisplay: (_, attributes) => businessInfoTaxIdValidation(attributes),
  website: websiteValidation(),
}
