import React, { useMemo } from 'react'
import { bool } from 'prop-types'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormTextInput } from '@divvy-web/skylab.form'

import MaskedPasswordInput from '@sputnik/components/FormInputs/MaskedPasswordInput'

const CreatePasswordContent = ({ isMobile, shouldShowUsername }) => {
  // TODO: replace isMobile prop with const { isMobile } = useDeviceDetect()
  const classNameInitial = useMemo(
    () => (isMobile ? 'CreatePasswordContentMobile' : 'CreatePasswordContent'),
    [isMobile],
  )

  const [getClassName, makeTestId] = useNameFormatter(classNameInitial)

  return (
    <div className={getClassName('')}>
      {shouldShowUsername && (
        <div className='username-field-row'>
          <FormTextInput
            readOnly
            autoComplete='none'
            className='fs-mask'
            dataTestId={makeTestId('username')}
            formfieldClassName={getClassName('username-input')}
            label={
              <FormattedMessage
                defaultMessage='Username/email'
                id='sputnik.CreatePasswordContent__PTRyKX'
              />
            }
            name='username'
            placeholder={
              <FormattedMessage
                defaultMessage='Username/email'
                id='sputnik.CreatePasswordContent__PTRyKX'
              />
            }
          />
        </div>
      )}
      <div className='password-wrapper'>
        <MaskedPasswordInput
          autoComplete='none'
          className='fs-mask'
          dataTestId={makeTestId('password')}
          label={
            <FormattedMessage
              defaultMessage='Enter your password'
              id='sputnik.CreatePasswordContent__2GFjIN'
            />
          }
          name='passwordDisplay'
          placeholder={
            <FormattedMessage
              defaultMessage='Password'
              id='sputnik.CreatePasswordContent__5sg7KC'
            />
          }
        />
        <MaskedPasswordInput
          shouldDisablePaste
          autoComplete='none'
          className='fs-mask'
          dataTestId={makeTestId('confirm-password')}
          label={
            <FormattedMessage
              defaultMessage='Confirm password'
              id='sputnik.CreatePasswordContent__8HimVK'
            />
          }
          name='confirmPasswordDisplay'
          placeholder={
            <FormattedMessage
              defaultMessage='Confirm password'
              id='sputnik.CreatePasswordContent__8HimVK'
            />
          }
        />
      </div>
    </div>
  )
}

CreatePasswordContent.propTypes = { isMobile: bool, shouldShowUsername: bool }

export default CreatePasswordContent
