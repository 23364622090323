import React from 'react'
import { Resolver, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { css } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'
import { FormDropdown, validationResolver } from '@divvy-web/skylab.form'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'

import { getEmailFromStorage } from '@sputnik/auth'
import MiscPageWrapper from '@sputnik/components/MiscPageWrapper/MiscPageWrapper'
import PageViewWrapper from '@sputnik/components/PageViewWrapper/PageViewWrapper'
import { useScreenForCreditApplication } from '@sputnik/pages/gql/mutations/ScreenForCreditApplication.gql'
import { PAGE_NAME_STRINGS, PATHNAME_CONSTS, PRODUCT_CONST_STRINGS } from '@sputnik/resources/constants'
import { logError, logInfo } from '@sputnik/utils/loggerUtils'

import {
  annualRevenueOptions,
  CLEARED,
  creditRangeOptions,
  currentCashOptions,
  yearsInBusinessOptions,
} from './rightFitSurveyPageUtils'

type RightFitForm = {
  yearsInBusiness: string
  annualRevenue: string
  currentCash: string
  creditRange: string
}
const RightFitSurveyPage = () => {
  const [makeTestId, getClassName] = useNameFormatter('RightFitSurveyPage')
  const applicantEmail = getEmailFromStorage()
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any
  const navigate = useNavigate()

  const validationMessage = (
    <FormattedMessage
      defaultMessage='Please select a value'
      id='sputnik.RightFitSurveyPage__nl+GDR'
    />
  )

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm<RightFitForm>({
    resolver: validationResolver(
      {
        yearsInBusiness: {
          presence: {
            message: validationMessage,
          },
        },
        annualRevenue: {
          presence: {
            message: validationMessage,
          },
        },
        currentCash: {
          presence: {
            message: validationMessage,
          },
        },
        creditRange: {
          presence: {
            message: validationMessage,
          },
        },
      },
      { fullMessages: false },
    ) as Resolver<RightFitForm>,
    defaultValues: {
      yearsInBusiness: '',
      annualRevenue: '',
      currentCash: '',
      creditRange: '',
    },
    mode: 'onChange',
  })

  const [screenForCreditApplication, { loading: isScreeningForCreditApplication }] = useScreenForCreditApplication({
    onCompleted: (data) => {
      const isCleared = data?.screenForCreditApplication?.decision === CLEARED

      logInfo({
        attributes: {
          action: 'screenForCreditApplication',
          message: `Customer was ${data?.screenForCreditApplication?.decision}`,
          result: `Sending user to path ${
            isCleared ? PATHNAME_CONSTS.GOOD_FIT_PATH : PATHNAME_CONSTS.NOT_GOOD_FIT_PATH
          }`,
        },
        eventName: 'screenForCreditApplicationSuccess',
      })

      isCleared ? navigate(PATHNAME_CONSTS.GOOD_FIT_PATH) : navigate(PATHNAME_CONSTS.NOT_GOOD_FIT_PATH)
    },
    onError: (error) => {
      logError({
        attributes: {
          action: 'screenForCreditApplication',
          message: error.message,
          result: 'Error',
        },
        eventName: 'screenForCreditApplicationError',
      })
      showDangerToast(
        <FormattedMessage
          defaultMessage='An error occurred while screening for credit application.'
          id='sputnik.RightFitSurveyPage__r8J2sE'
        />,
      )
      reset()
    },
  })

  const { PAGE_RIGHT_FIT_SURVEY } = PAGE_NAME_STRINGS

  const handleSubmitRightFitSurvey = (formValues) => {
    const { yearsInBusiness, annualRevenue, currentCash, creditRange } = formValues

    screenForCreditApplication({
      variables: {
        screeningDecisionInput: {
          applicantEmail: (applicantEmail as string) || '',
          yearsInBusiness,
          annualRevenue,
          currentCash,
          creditRange,
        },
      },
    })
  }

  const placeholder = (
    <FormattedMessage
      defaultMessage='Select'
      id='sputnik.RightFitForm__kQAf2d'
    />
  )

  return (
    <div css={programFitFormCss}>
      <PageViewWrapper pageName={PAGE_RIGHT_FIT_SURVEY}>
        <MiscPageWrapper
          headingStyles={null}
          pageName={PAGE_RIGHT_FIT_SURVEY}
          pageStyles={null}
          pageSubTitle={
            <FormattedMessage
              defaultMessage='Let’s review a few things up front to make sure we’re a match.'
              id='sputnik.RightFitSurveyPage__dw1JHc'
            />
          }
          pageTitle={
            <FormattedMessage
              defaultMessage='Is {BILL_SPEND_AND_EXPENSE} the right fit for you?'
              id='sputnik.RightFitSurveyPage__hhobD+'
              values={{
                BILL_SPEND_AND_EXPENSE: PRODUCT_CONST_STRINGS.BILL_SPEND_AND_EXPENSE,
              }}
            />
          }
        >
          <form
            className={getClassName('Form')}
            data-testid={makeTestId('Form')}
          >
            <FormDropdown
              autoSelectOnBlur
              className={getClassName('')}
              control={control}
              dataTestId={makeTestId('years-in-business')}
              formDataTestId={makeTestId('years-in-business')}
              id={makeTestId('years-in-business')}
              isSearchable={false}
              label={
                <FormattedMessage
                  defaultMessage='How many years has your company been in business?'
                  id='sputnik.RightFitSurveyPage__aif9by'
                />
              }
              name='yearsInBusiness'
              options={yearsInBusinessOptions}
              placeholder={placeholder}
            />
            <FormDropdown
              autoSelectOnBlur
              className={getClassName('annualRevenue')}
              control={control}
              dataTestId={makeTestId('annual-revenue')}
              formDataTestId={makeTestId('annual-revenue')}
              id={makeTestId('annual-revenue')}
              isSearchable={false}
              label={
                <FormattedMessage
                  defaultMessage="What is your company's annual revenue?"
                  id='sputnik.RightFitSurveyPage__nGj4xG'
                />
              }
              name='annualRevenue'
              options={annualRevenueOptions}
              placeholder={placeholder}
            />
            <FormDropdown
              autoSelectOnBlur
              className={getClassName('currentCash')}
              control={control}
              dataTestId={makeTestId('current-cash')}
              formDataTestId={makeTestId('current-cash')}
              id={makeTestId('current-cash')}
              isSearchable={false}
              label={
                <FormattedMessage
                  defaultMessage="What is the current cash balance in your company's bank account?"
                  id='sputnik.RightFitSurveyPage__pFyoUn'
                />
              }
              name='currentCash'
              options={currentCashOptions}
              placeholder={placeholder}
            />
            <FormDropdown
              autoSelectOnBlur
              className={getClassName('creditRange')}
              control={control}
              dataTestId={makeTestId('credit-range')}
              formDataTestId={makeTestId('credit-range')}
              id={makeTestId('credit-range')}
              isSearchable={false}
              label={
                <FormattedMessage
                  defaultMessage="What is the estimated personal credit score of your company's authorized signer?"
                  id='sputnik.RightFitSurveyPage__Vki+JY'
                />
              }
              name='creditRange'
              options={creditRangeOptions}
              placeholder={placeholder}
            />
            <div className='fs-unmask form-footer-note'>
              <FormattedMessage
                defaultMessage='Note: the authorized signer is the person who is responsible for signing the credit application.'
                id='sputnik.RightFitSurveyPage__iPWaQT'
              />
            </div>
            <div className='navigation-buttons'>
              <BasicButton
                className='primary-button'
                color='action'
                dataTestId={makeTestId('')}
                disabled={isScreeningForCreditApplication || !isValid}
                onClick={handleSubmit(handleSubmitRightFitSurvey)}
              >
                <FormattedMessage
                  defaultMessage='Next'
                  id='sputnik.RightFitSurveyPage__9+Ddtu'
                />
              </BasicButton>
            </div>
          </form>
        </MiscPageWrapper>
      </PageViewWrapper>
    </div>
  )
}

const programFitFormCss = ({ mq, type }) => css`
  .RightFitSurveyPage-Form {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--tri-space-1500);
    gap: var(--tri-space-300);
    ${mq.xSmallMaxWidth({
      gap: '0',
    })}
  }

  .FormField-value {
    max-width: 320px;
  }

  .FormField-label {
    ${type.triFontDesktopBodySmallEmphasis}
  }

  .form-footer-note {
    margin-top: var(--tri-space-300);
    ${type.triFontDesktopBodySmall}
  }

  // styles for mobile buttons
  .navigation-buttons {
    position: fixed;
    right: 25%;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    margin: var(--tri-space-500) 0 var(--tri-space-500) 0;
    padding-right: 15%;
    gap: var(--tri-space-400);

    ${mq.largeMaxWidth({ right: 0 })}

    ${mq.xSmallMaxWidth({
      gap: '0',
      height: 'var(--tri-space-1100)',
      justifyContent: 'space-between',
      margin: 0,
      padding: 0,
      right: 0,
      width: '100%',
    })}
  }

  .primary-button {
    ${mq.xSmallMaxWidth({ flexGrow: '1', minWidth: '50%' })};
  }

  .navigation-buttons button {
    ${mq.xSmallMaxWidth({ borderRadius: '0', height: '100%', width: '100%' })};
  }
`

export default RightFitSurveyPage
