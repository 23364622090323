import React from 'react'
import { css, Global } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'

import ContactUsButton from '@sputnik/components/ContactUsButton'
import { PRODUCT_CONST_STRINGS } from '@sputnik/resources/constants'
import { logInfo } from '@sputnik/utils/loggerUtils'

const roadBarricadeImgPath = 'https://app.divvy.co/assets/illustrations/road-barricade'
const roadBarricadeImgSrc = `${roadBarricadeImgPath}@1x.png`
const roadBarricadeImgSrcSet = `${roadBarricadeImgPath}@3x.png, ${roadBarricadeImgPath}@2x.png, ${roadBarricadeImgPath}@1x.png`

const MaintenanceScreen = () => {
  const [getClassName, makeTestId] = useNameFormatter('MaintenanceScreen')

  const onGoToGetDivvyClicked = () => {
    logInfo({
      attributes: {
        action: 'goToSpend&Expense',
        result: `Sending user to path: https://www.getdivvy.com`,
      },
      eventName: 'RedirectToSpend&Expense',
    })
    window.location.href = 'https://www.getdivvy.com'
  }

  return (
    <div
      className={getClassName('')}
      css={maintenanceScreenStyles}
      data-testid={makeTestId('')}
    >
      <img
        alt='road-barricade'
        className={getClassName('road-barricade-img')}
        data-testid={makeTestId('img')}
        src={roadBarricadeImgSrc}
        srcSet={roadBarricadeImgSrcSet}
      />
      <div
        className={getClassName('heading')}
        data-testid={makeTestId('heading')}
      >
        <FormattedMessage
          defaultMessage="We'll be back soon"
          id='sputnik.MaintenanceScreen__GM4TRa'
        />
      </div>
      <div
        className={getClassName('sub-heading')}
        data-testid={makeTestId('sub-heading')}
      >
        <FormattedMessage
          defaultMessage="We're working on making the {BILL_SPEND_AND_EXPENSE} application better for our customers. Please check back later to access your application."
          id='sputnik.MaintenanceScreen__QQRA1N'
          values={{ BILL_SPEND_AND_EXPENSE: PRODUCT_CONST_STRINGS.BILL_SPEND_AND_EXPENSE }}
        />
        <br />
        <FormattedMessage
          defaultMessage='Thanks for your patience.'
          id='sputnik.MaintenanceScreen__hYQpHO'
        />
      </div>
      <div
        className={getClassName('cta-container')}
        data-testid={makeTestId('cta-conatiner')}
      >
        <ContactUsButton />
        <BasicButton
          dataTestId={makeTestId('goto-divvy')}
          onClick={onGoToGetDivvyClicked}
        >
          <FormattedMessage
            defaultMessage='Go to getdivvy.com'
            id='sputnik.MaintenanceScreen__S2SVKG'
          />
        </BasicButton>
      </div>
      <Global
        styles={css`
          .grecaptcha-badge {
            display: none;
          }
        `}
      />
    </div>
  )
}

const maintenanceScreenStyles = ({ mq, type }) => css`
  max-width: 346px;
  margin: 0 auto;
  text-align: center;

  .MaintenanceScreen {
    &-road-barricade-img {
      margin-top: var(--tri-space-2200);
      width: 200px;

      ${mq.xxSmallMaxWidth({ marginTop: 'var(--tri-space-600)' })}
    }

    &-heading {
      ${type.triFontDesktopTitleSmallEmphasis}
      margin-bottom: var(--tri-space-100);
    }

    &-cta-container {
      display: flex;
      justify-content: space-between;
      margin-top: var(--tri-space-500);
    }
  }
`

export default MaintenanceScreen
