import React, { ReactNode } from 'react'
import { css } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import Modal from '@divvy-web/skylab.modal'

import ImageWrapper from '@sputnik/components/ImageWrapper'

interface PhoneVerificationModalProps {
  isShowing: boolean
  children: ReactNode
  title: ReactNode
  actionButtons: ReactNode[]
  onCloseClick?: () => void
}

const PhoneVerificationModal = ({
  isShowing,
  onCloseClick,
  actionButtons,
  children,
  title,
}: PhoneVerificationModalProps) => {
  const [getClassName, makeTestId] = useNameFormatter('PhoneVerificationModal')

  return (
    <Modal
      actions={actionButtons}
      className={getClassName('')}
      css={modalStyles}
      dataTestId={makeTestId('')}
      headerColor='accent'
      headerImage={
        <ImageWrapper
          alt='password-lock'
          dataTestId={makeTestId('lock-img')}
          imageName='signup-create-password-modal'
        />
      }
      isShowing={isShowing}
      title={title}
      onCloseClick={onCloseClick}
    >
      {children}
    </Modal>
  )
}

const modalStyles = css`
  .PhoneVerificationModal-main {
    width: 700px;
  }

  .Modal-header .PhoneVerificationModal-header-text {
    justify-content: center;
  }

  .RequestSmsCodeModal-phone-number-display-header {
    margin-top: var(--tri-space-300);
  }
`

export default PhoneVerificationModal
