import React, { useCallback } from 'react'
import { css } from '@emotion/core'
import { bool, func, object } from 'prop-types'

import { FormattedCurrency } from '@divvy-web/i18n'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Tile from '@divvy-web/skylab.tile'

import BdcLogo from './BdcLogo'
import ExternalConnectionLogo from './ExternalConnectionLogo'

const ExternalBankInfoCard = ({
  bankInfo = {},
  disabled = false,
  isDeletable = false,
  isLoading = false,
  isSelected = false,
  onSelectBankInfo,
  onSetToDeleteBankInfo = () => {},
}) => {
  const {
    accountAvailableBalance,
    accountNickname,
    accountNumberDisplay,
    accountType,
    bankName,
    externalConnectionGuid,
    id,
    source,
  } = bankInfo

  const isMXConnection = source === 'MONEY_MOVER'
  const isShowDeleteIcon = isDeletable && externalConnectionGuid
  const formattedAccountAvailableBalance = accountAvailableBalance || ''

  const handleClick = useCallback(() => {
    onSelectBankInfo(id)
  }, [id, onSelectBankInfo])

  const connectionLogo = isMXConnection ? (
    <ExternalConnectionLogo
      connectionInfo={bankInfo}
      isLoading={isLoading}
    />
  ) : (
    <BdcLogo
      isReversed
      className='logo'
    />
  )

  const secondaryDetailsSectionText = accountNickname || accountType || ''

  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation()
      onSetToDeleteBankInfo(bankInfo)
    },
    [bankInfo, onSetToDeleteBankInfo],
  )

  return (
    <div css={chipContainerStyles}>
      <Tile
        css={externalBankInfoChipStyles(disabled, isLoading)}
        disabled={isLoading || disabled}
        isSelected={isSelected}
        selectedColor='success'
        onClick={handleClick}
      >
        {connectionLogo}
        <div className='card-content-container'>
          <div className='info'>
            <div className='info-section'>
              <div className='bank-primary-details'>
                {`${bankName} ${secondaryDetailsSectionText} ${accountNumberDisplay}`}
              </div>
            </div>
            {formattedAccountAvailableBalance && (
              <div className='info-section'>
                <div className='balance'>
                  <FormattedMessage
                    defaultMessage='Available Balance: '
                    id='sputnik.ExternalBankInfoCard__Q2PxTj'
                  />
                  <FormattedCurrency
                    minimumFractionDigits={0}
                    value={formattedAccountAvailableBalance}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Tile>
      {isShowDeleteIcon && (
        <BasicButton
          color='neutral'
          icon='trash'
          name='settings'
          type={BASIC_BUTTON_TYPE_FLAT}
          onClick={handleDelete}
        />
      )}
    </div>
  )
}

ExternalBankInfoCard.propTypes = {
  bankInfo: object,
  disabled: bool,
  isDeletable: bool,
  isLoading: bool,
  isSelected: bool,
  onSelectBankInfo: func,
  onSetToDeleteBankInfo: func,
}

export default ExternalBankInfoCard

const chipContainerStyles = css`
  display: flex;
  align-items: center;
  margin-bottom: var(--tri-space-200);
  gap: var(--tri-space-100);
`

const externalBankInfoChipStyles =
  (disabled, isLoading) =>
  ({ type }) =>
    css`
      width: 400px;

      .Tile-content {
        padding: var(--tri-space-200);
        display: flex;
        flex-direction: row;
        align-items: center;
        ${disabled ? 'background: var(--tri-color-text-secondary-inverse);' : ''}
        ${isLoading || disabled ? 'cursor: default;' : ''}
      }

      .Tile-content:hover {
        ${disabled ? 'box-shadow: none;' : ''}
      }

      .card-content-container {
        width: 85%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .info {
        display: flex;
        flex-direction: column;
      }

      .info-section {
        padding-left: var(--tri-space-100);
        display: flex;
        padding: 3.5;
      }

      .bank-primary-details {
        display: flex;
        gap: var(--tri-space-100);
        ${type.triFontDesktopBodyMediumEmphasis}
        ${disabled ? 'color: var(--tri-color-text-tertiary)' : ''}
      }

      .balance {
        ${type.triFontDesktopBodySmall}
        color: var(--tri-color-text-tertiary);
      }
    `
