import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { FormattedMessage } from '@divvy-web/i18n'
import { useForm } from '@divvy-web/skylab.form'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'

import RequestSmsCodeModal from '@sputnik/components/RequestSmsCodeModal'
import SmsAccessCodeForm from '@sputnik/components/SmsAccessCodeForm'
import { useRequestPhoneVerification } from '@sputnik/pages/gql/mutations/RequestPhoneVerification.gql'
import { logError, logInfo } from '@sputnik/utils/loggerUtils'

interface PhoneVerificationModalContainerProps {
  submitPageMutation: () => void
}

type Form = {
  phoneNumber: string
  authorizedSignerId: string
}
type UseForm = <T extends object>() => Record<'getFormValue', <K extends keyof T>(x: K) => T[K]>

const PhoneVerificationModalContainer = ({ submitPageMutation }: PhoneVerificationModalContainerProps) => {
  const { appId } = useParams()
  const { getFormValue } = (useForm as unknown as UseForm)<Form>()
  const [searchParams, setSearchParams] = useSearchParams()
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any

  const [requestPhoneVerification, { loading }] = useRequestPhoneVerification({
    variables: {
      salesforceCreditId: appId || '',
      phoneNumber: getFormValue('phoneNumber'),
      personId: getFormValue('authorizedSignerId'),
    },
    onCompleted: () => {
      logInfo({
        attributes: {
          action: 'handleSendSmsCode',
          result: 'Send code button has been clicked',
        },
        eventName: 'RequestPhoneVerificationCode',
      })

      searchParams.delete('phoneVerificationRequired')
      setSearchParams(
        { ...Object.fromEntries(searchParams), verifySmsCodePending: 'true' },
        { state: { phoneNumber: getFormValue('phoneNumber'), authorizedSignerId: getFormValue('authorizedSignerId') } },
      )
    },
    onError: (e) => {
      logError({
        attributes: {
          action: 'handleSendSmsCode',
          message: e?.message,
          result: 'Error requesting phone verification code',
        },
        eventName: 'RequestPhoneVerificationCode',
      })
      if (e?.message?.includes('exceeded rate limit')) {
        showDangerToast(
          <FormattedMessage
            defaultMessage='You have exceeded the rate limit for sending verification codes. Please try in a few minutes.'
            id='sputnik.PhoneVerificationModalContainer__2XK5P0'
          />,
        )
      } else {
        showDangerToast(
          <FormattedMessage
            defaultMessage='Error requesting phone verification code'
            id='sputnik.PhoneVerificationModalContainer__0ttUuJ'
          />,
        )
      }
    },
  })

  return (
    <>
      {!!searchParams?.get('phoneVerificationRequired') && (
        <RequestSmsCodeModal
          isLoading={loading}
          requestPhoneVerification={requestPhoneVerification}
        />
      )}
      {!!searchParams.get('verifySmsCodePending') && (
        <SmsAccessCodeForm
          isLoadingResendCode={loading}
          requestPhoneVerification={requestPhoneVerification}
          submitPageMutation={submitPageMutation}
        />
      )}
    </>
  )
}

export default PhoneVerificationModalContainer
