import React from 'react'
import { css } from '@emotion/core'
import { bool, func, node, string } from 'prop-types'

import BasicButton from '@divvy-web/skylab.basicbutton'

const InlineBanner = ({ dataTestId, isVisible, message, onClose, title }) => {
  return (
    <div
      css={outerCss}
      data-testid={dataTestId}
    >
      <div
        className={isVisible ? 'banner-visible' : 'banner-dismissed'}
        css={bannerCss}
      >
        <div className='banner-content'>
          <b>{title}</b>
          <div>{message}</div>
        </div>
        <div className='dismiss-button'>
          <BasicButton
            data-testid='inline-banner-dismiss'
            icon='close'
            name='close'
            type='flat'
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  )
}

InlineBanner.propTypes = {
  dataTestId: string,
  isVisible: bool.isRequired,
  message: node.isRequired,
  onClose: func.isRequired,
  title: node.isRequired,
}

const outerCss = () => css`
  .banner-dismissed {
    transition: max-height 500ms ease-in-out, margin 500ms ease-in-out, opacity 500ms ease-in-out;
    opacity: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
  }
  .banner-visible {
    opacity: 1;
    max-height: 480px;
  }
`

const bannerCss = ({ type }) => css`
  ${type.triFontDesktopBodySmall}
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: var(--tri-color-fill-accent-primary);
  margin: var(--tri-space-300) 0 var(--tri-space-400) 0;
  position: relative;

  .banner-content {
    flex-direction: column;
    align-items: center;
    padding: var(--tri-space-250) var(--tri-space-750) var(--tri-space-300) var(--tri-space-400);
  }

  .dismiss-button {
    justify-self: flex-end;
    position: absolute;
    right: 0;
    margin-right: var(--tri-space-2500);
  }
`

export default InlineBanner
