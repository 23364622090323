import React, { createContext, ReactNode, useRef } from 'react'

import { useForm } from '@divvy-web/skylab.form'

interface PhoneNumberFieldInputContextType {
  inputRef: React.RefObject<HTMLInputElement>
  clearAndScrollToInput: () => void
}

export const PhoneNumberFieldInputContext = createContext<PhoneNumberFieldInputContextType | null>(null)

interface usePhoneNumberFieldInputProviderProps {
  children: ReactNode
}

type Form = {
  phoneNumber: string
}
type UseForm = <T extends object>() => Record<'setFormValue', <K extends keyof T>(x: K, value: T[K]) => void>

const PhoneNumberFieldInputProvider = ({ children }: usePhoneNumberFieldInputProviderProps) => {
  const { setFormValue } = (useForm as unknown as UseForm)<Form>()
  const inputRef = useRef<HTMLInputElement>(null)

  const clearAndScrollToInput = () => {
    setFormValue('phoneNumber', '')
    setTimeout(() => {
      inputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 300)
  }

  const context = { inputRef, clearAndScrollToInput }
  return <PhoneNumberFieldInputContext.Provider value={context}>{children}</PhoneNumberFieldInputContext.Provider>
}

export default PhoneNumberFieldInputProvider
