import React from 'react'
import { css } from '@emotion/core'
import { string } from 'prop-types'

import { FormattedMessage } from '@divvy-web/i18n'
import { curry } from '@divvy-web/utils'

import ImageWrapper from '@sputnik/components/ImageWrapper'

const MobileDocumentUploadStatus = ({ page }) => {
  const pageContent = {
    complete: {
      image: <ImageWrapper imageName='mobile-upload-status-success' />,
      subTitle: (
        <FormattedMessage
          defaultMessage='Your document(s) has been uploaded, please continue by returning to your computer dashboard.'
          id='sputnik.MobileDocumentUploadStatus__cBTc8G'
        />
      ),
      title: (
        <FormattedMessage
          defaultMessage='You uploaded your documents successfully!'
          id='sputnik.MobileDocumentUploadStatus__+1jOCR'
        />
      ),
    },
    error: {
      image: <ImageWrapper imageName='mobile-upload-status-error' />,
      subTitle: (
        <FormattedMessage
          defaultMessage='We were not able to upload your document. Please try again soon, or contact BILL.'
          id='sputnik.MobileDocumentUploadStatus__j0gjIR'
        />
      ),
      title: (
        <FormattedMessage
          defaultMessage='Oops! Something went wrong'
          id='sputnik.MobileDocumentUploadStatus__kiU3pa'
        />
      ),
    },
    invalidLink: {
      image: <ImageWrapper imageName='mobile-upload-status-expired' />,
      subTitle: (
        <FormattedMessage
          defaultMessage='This link has expired. Please request a new link from your dashboard.'
          id='sputnik.MobileDocumentUploadStatus__d4xHv3'
        />
      ),
      title: (
        <FormattedMessage
          defaultMessage='This link is not valid'
          id='sputnik.MobileDocumentUploadStatus__Eu2oG+'
        />
      ),
    },
  }
  const { image, subTitle, title } = pageContent[page]

  return (
    <div
      className='fs-unmask'
      css={documentUploadPageStatusWrapperCss}
    >
      <div className='document-selector-img-wrapper'>
        <div className='background-image'>
          <ImageWrapper imageName='splotch_white_02' />
        </div>
        <div className='main-image'>{image}</div>
      </div>
      <div className='big-title'>{title}</div>
      <div className='sub-text'>{subTitle}</div>
    </div>
  )
}

MobileDocumentUploadStatus.propTypes = {
  page: string.isRequired,
}

const documentUploadPageStatusWrapperCss = curry(
  ({ type }) => css`
    background-color: var(--tri-color-fill-accent-primary);
    height: 100%;
    padding: 0 var(--tri-space-400) 0 var(--tri-space-400);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--tri-space-200);

    .big-title {
      ${type.triFontDesktopBodyLargeEmphasis}
    }

    .sub-text {
      ${type.triFontDesktopBodyMedium}
    }

    .document-selector-img-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: var(--tri-space-900);
      margin-bottom: var(--tri-space-300);
      margin-left: var(--tri-space-200);

      .background-image {
        img {
          max-width: 320px;
          max-height: 350px;
        }
      }
      .main-image {
        position: absolute;
        left: 0;
        top: 84px;
        z-index: 2;
        img {
          max-width: 337px;
        }
      }
    }

    .document-selector-img-wrapper > img {
      max-width: 100%;
      max-height: 100%;
    }
  `,
)

MobileDocumentUploadStatus.propTypes = {
  page: string,
}

export default MobileDocumentUploadStatus
