import React from 'react'
import { css } from '@emotion/core'

import Scrim from '@divvy-web/skylab.scrim'

interface LoadingLogoProps {
  isShowing: boolean
}

const LoadingLogo = ({ isShowing }: LoadingLogoProps) => {
  return (
    <Scrim
      useEscHandler
      isShowing={isShowing}
    >
      <div css={loadingLogoStyles}>
        <div className='loading-logo-img-wrapper'>
          <img
            alt='divvy-loading-logo'
            src={`https://app.divvy.co/assets/animations/brand-loading.gif`}
          />
        </div>
      </div>
    </Scrim>
  )
}

const loadingLogoStyles = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .loading-logo-img-wrapper {
    background: white;
    border-radius: 50%;
    img {
      max-width: 136px;
    }
  }
`
export default LoadingLogo
