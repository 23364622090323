import React from 'react'
import { css } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'

import ContactUsButton from '@sputnik/components/ContactUsButton'
import GoToDashboardButton from '@sputnik/components/GoToDashboardButton'
import ImageWrapper from '@sputnik/components/ImageWrapper'

const SubmissionErrorPage = () => {
  const [makeTestId] = useNameFormatter('SubmissionErrorPage')
  return (
    <div css={pageStyle}>
      <ImageWrapper
        alt='banana-peel'
        dataTestId={makeTestId('banana-peel-img')}
        imageName='banana-peel'
      />
      <h1
        css={header}
        data-testid='message-submission-error'
      >
        <FormattedMessage
          defaultMessage='Whoops!'
          id='sputnik.SubmissionErrorPage__xWgftU'
        />
      </h1>
      <div className='subtitle'>
        <FormattedMessage
          defaultMessage='It looks like there may have been an issue.'
          id='sputnik.SubmissionErrorPage__2/vBeS'
        />
      </div>
      <div css={statusButtonWrapperCss}>
        <GoToDashboardButton
          componentName={makeTestId('')}
          dataTestId={makeTestId('')}
        />
        <ContactUsButton className='secondary-button' />
      </div>
    </div>
  )
}

const header = ({ type }) => css`
  ${type.triFontDesktopTitleLargeEmphasis};
  text-align: center;
  margin-top: calc(var(--tri-space-800) * -1);
`

const pageStyle = ({ mq }) => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-area: content;
  .program-image {
    ${mq.xxSmallMaxWidth({ width: '90%' })}
  }

  .subtitle {
    ${mq.xxSmallMaxWidth({ padding: '0 var(--tri-space-500)', textAlign: 'center' })}
  }
`

const statusButtonWrapperCss = css`
  display: flex;
  padding: var(--tri-space-300) 0 0;
  gap: var(--tri-space-300);
`

export default SubmissionErrorPage
