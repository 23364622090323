/* global __DivvyEnvironment */
import React, { useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { bool, object, string } from 'prop-types'

import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { RemoteModule } from '@divvy-web/utils.dynamic-remotes'
import { error, info, warn } from '@divvy-web/utils.log'

import FederatedSingleSpaApp from '@sputnik/remotes/FederatedSingleSpa/FederatedSingleSpaApp'
import { themes, TrinityThemeContainer } from '@sputnik/remotes/TrinityThemeContainer/TriThemeContainer'
import { getBillUrl } from '@sputnik/utils/billUtils'
import { logInfo } from '@sputnik/utils/loggerUtils'

import { wrapperStyles } from './styles'

const GLOBAL_TOP_NAV_REMOTE = {
  exposedModule: 'global-top-nav',
  remoteEntryUrl: `${__DivvyEnvironment.REMOTE_GLOBAL_TOP_NAV_PATH}`,
  scope: 'nav',
}

const CREDIT_DASHBOARD = 'CREDIT_DASHBOARD'

const GlobalTopNav = ({ apolloClient, bdcOrgId, companyName, isConsole }) => {
  RemoteModule.init({ error, info, squadForLogs: 'vibranium', warn })
  const navigate = useNavigate()
  const makeTestId = useMakeTestId('GlobalTopNav')
  const loaderFn = useMemo(() => RemoteModule.import(GLOBAL_TOP_NAV_REMOTE), [])
  const billBaseUrl = getBillUrl(bdcOrgId)
  const initTime = useRef(Date.now())

  const insightsBaseUrl = __DivvyEnvironment.BILL_INSIGHTS_URL
  const forecastingBaseUrl = __DivvyEnvironment.BILL_FORECASTING_URL

  const goToBill = () => {
    const billHref = isConsole ? `${billBaseUrl}/neo/console/ConsoleClients` : `${billBaseUrl}/neo/overview`

    logInfo({
      attributes: {
        action: 'handleGoToBill',
        result: `User navigated to: ${billHref}`,
      },
      eventName: 'NavigatePayablesReceivables',
    })

    window.location.href = billHref
  }

  const logout = () => {
    logInfo({
      attributes: {
        action: 'logout',
        result: `Log out and send user to ${billBaseUrl}/Logout`,
      },
      eventName: 'GlobalTopNavLogOut',
    })
    window.location.href = `${billBaseUrl}/Logout`
  }

  const routeToAp = (path) => {
    logInfo({
      attributes: {
        action: 'routeToAp',
        result: `redirect user to ${billBaseUrl}${path}`,
      },
      eventName: 'GlobalTopNavRouteToAp',
    })
    window.location.href = `${billBaseUrl}${path}`
  }

  const routeToEx = (path) => {
    navigate(path)
  }

  const customProps = {
    props: {
      apolloClient: apolloClient,
      initLoadTime: initTime.current,
      billBaseUrl,
      companyName,
      host: CREDIT_DASHBOARD,
      insightsBaseUrl,
      forecastingBaseUrl,
      onClickBillLink: goToBill,
      onLogout: logout,
      orgId: bdcOrgId,
      routeToAp,
      routeToEx,
      showGlobalTodos: false,
    },
    withThemeContainer: true,
  }

  return (
    <TrinityThemeContainer
      data-testid={makeTestId('theme-container')}
      theme={themes.BILLTEMP}
    >
      <div
        css={wrapperStyles}
        data-testid={makeTestId('')}
      >
        <FederatedSingleSpaApp
          loaderFn={() => loaderFn}
          {...customProps}
        />
      </div>
    </TrinityThemeContainer>
  )
}

GlobalTopNav.propTypes = {
  apolloClient: object,
  bdcOrgId: string,
  companyName: string,
  isConsole: bool,
}

export default GlobalTopNav
