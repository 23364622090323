import React, { ReactNode } from 'react'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import Icon from '@divvy-web/skylab.icon'
import Tile from '@divvy-web/skylab.tile'

import { statusTileContentMobileCss } from '@sputnik/pages/Dashboard/StatusTile/StatusTile/statusTileStyles'

interface StatusTileMobileViewProps {
  businessName: string
  primaryInfo: ReactNode
  secondaryInfo: ReactNode
  image: ReactNode
  children?: ReactNode
  title: ReactNode
  isWarningStatus: boolean
}

const StatusTileMobileView = ({
  businessName,
  primaryInfo,
  secondaryInfo,
  image,
  children,
  isWarningStatus,
  title,
}: StatusTileMobileViewProps) => {
  const [getClassName, makeTestId] = useNameFormatter('StatusTileMobileView')

  return (
    <div
      css={statusTileContentMobileCss}
      data-testid={makeTestId('')}
    >
      <div className='fs-unmask'>
        {businessName && (
          <div
            className={getClassName('business-name')}
            data-testid={makeTestId('business-name')}
          >
            {businessName}
          </div>
        )}
        <Tile>
          <div className={getClassName('content')}>
            <div className={getClassName('info-container')}>
              <div>
                <div className={getClassName('main-title')}>
                  {title}
                  {isWarningStatus && (
                    <Icon
                      className={getClassName('')}
                      name='warningColor'
                      size='small'
                    />
                  )}
                </div>
                <div className={getClassName('description')}>{secondaryInfo}</div>
              </div>
              <div className={getClassName('image')}>{image}</div>
            </div>

            <div
              className={isWarningStatus ? 'status-error-text' : 'status-sub-header'}
              data-testid={makeTestId('primary-info')}
            >
              {primaryInfo}
            </div>
            {children && <div className={getClassName('button-container')}>{children}</div>}
          </div>
        </Tile>
      </div>
    </div>
  )
}

export default StatusTileMobileView
