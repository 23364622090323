/* global __DivvyEnvironment */
import { ApolloLink, HttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { ApolloClient, split } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'

import { logError, logInfo } from '@sputnik/utils/loggerUtils'

import { getBillUrl, getTankUrl } from './billUtils'

const fetcher = (arg1, arg2 = null) => {
  return window.fetch(arg1, arg2)
}

const hasUnauthorizedGraphQLError = (graphQLErrors) => {
  if (!graphQLErrors) return false
  return graphQLErrors.some((error) => {
    const errorCode = error?.extensions?.exception?.code || error?.code
    return errorCode === 401
  })
}

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      if (!!error && !!error?.statusCode && error?.statusCode >= 500) {
        console.error(`Status code ${error.statusCode} on query name ${operation?.operationName}`)
        return true
      }
      return false
    },
  },
})

export const getBillApolloClient = (bdcOrgId) => {
  if (!bdcOrgId) return null
  const cache = new InMemoryCache({})
  const tankUrl = getTankUrl(bdcOrgId)
  const billBaseUrl = getBillUrl(bdcOrgId)
  const httpLink = new HttpLink({
    credentials: 'include',
    fetch: fetcher,
    uri: tankUrl,
  })
  const ffaashttpLink = new HttpLink({
    fetch: fetcher,
    uri: __DivvyEnvironment?.BILL_FFAAS_GQL,
  })

  const ffaashttpSplitLink = split((operation) => operation.getContext().ffaas === true, ffaashttpLink, httpLink)

  return new ApolloClient({
    cache: cache,
    link: ApolloLink.from([
      retryLink,
      onError((errors) => {
        const { graphQLErrors, networkError, operation } = errors

        if (graphQLErrors) {
          graphQLErrors.forEach((error) => {
            const errorCode = error?.extensions?.exception?.code || error?.code
            const errorMessage = error?.extensions?.exception?.message || error?.message
            const result = error?.extensions?.exception || error
            logError({
              attributes: {
                action: operation?.operationName,
                errorCode: errorCode,
                message: 'Graphql error',
                result: result,
              },
              eventName: 'Apollo Call',
              message: errorMessage,
            })
          })
        }

        const isUnauthorized =
          networkError?.statusCode === 401 ||
          networkError?.statusCode === 403 ||
          hasUnauthorizedGraphQLError(graphQLErrors)

        if (isUnauthorized) {
          console.warn('Unauthorized')
          logInfo({
            attributes: {
              action: 'getBillApolloClient',
              result: `Log out and send user to ${billBaseUrl}/Logout`,
            },
            eventName: 'UnauthorizedBillLogOut',
          })
          window.location.href = `${billBaseUrl}/Logout`
        }
      }),
      ffaashttpSplitLink,
    ]),
    name: 'sputnik',
    resolvers: {},
    version: __DivvyEnvironment?.DEPLOY_VERSION ?? 'dev',
  })
}
