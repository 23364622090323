/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/
import type * as _Types from '_gql'
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ScreenForCreditApplication_screenForCreditApplication_ScreeningDecision = {
  readonly __typename: 'ScreeningDecision'
  readonly decision: _Types.ApplicationScreeningDecision | null
  readonly decisionReason: string | null
}

export type ScreenForCreditApplication_Mutation = {
  readonly __typename: 'Mutation'
  readonly screenForCreditApplication: ScreenForCreditApplication_screenForCreditApplication_ScreeningDecision | null
}

export type ScreenForCreditApplicationVariables = _Types.Exact<{
  screeningDecisionInput?: _Types.InputMaybe<_Types.ScreeningDecisionInput>
}>

export type ScreenForCreditApplication = ScreenForCreditApplication_Mutation

export const ScreenForCreditApplicationDocument = gql`
  mutation ScreenForCreditApplication($screeningDecisionInput: ScreeningDecisionInput) {
    screenForCreditApplication(screeningDecisionInput: $screeningDecisionInput) {
      decision
      decisionReason
    }
  }
`
export type ScreenForCreditApplicationMutationFn = Apollo.MutationFunction<
  ScreenForCreditApplication,
  ScreenForCreditApplicationVariables
>

/**
 * __useScreenForCreditApplication__
 *
 * To run a mutation, you first call `useScreenForCreditApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScreenForCreditApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [screenForCreditApplication, { data, loading, error }] = useScreenForCreditApplication({
 *   variables: {
 *      screeningDecisionInput: // value for 'screeningDecisionInput'
 *   },
 * });
 */
export function useScreenForCreditApplication(
  baseOptions?: Apollo.MutationHookOptions<ScreenForCreditApplication, ScreenForCreditApplicationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ScreenForCreditApplication, ScreenForCreditApplicationVariables>(
    ScreenForCreditApplicationDocument,
    options,
  )
}
export type ScreenForCreditApplicationHookResult = ReturnType<typeof useScreenForCreditApplication>
export type ScreenForCreditApplicationMutationResult = Apollo.MutationResult<ScreenForCreditApplication>
export type ScreenForCreditApplicationMutationOptions = Apollo.BaseMutationOptions<
  ScreenForCreditApplication,
  ScreenForCreditApplicationVariables
>
