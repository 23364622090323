import React from 'react'
import { css } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormCheckboxInput } from '@divvy-web/skylab.form'
import { useForm } from '@divvy-web/skylab.form'

import TermsAndConditionsLink from '@sputnik/components/TermsAndConditionsLink'
import { getIsDSABusinesOnly } from '@sputnik/components/utils'

type Form = {
  creditReportConsent: boolean
  dsaVersion: string
}

type UseForm = <T extends object>() => Record<'getFormValue', <K extends keyof T>(x: K) => T[K]> &
  Record<'setFormValue', <K extends keyof T>(x: K, value: T[K]) => void>

const FCRATerms = () => {
  const [getClassName, makeTestId] = useNameFormatter('FCRATerms')
  const { getFormValue, setFormValue } = (useForm as unknown as UseForm)<Form>()
  const creditReportConsent = getFormValue('creditReportConsent')
  const dsaVersion = getFormValue('dsaVersion')
  const handleTermsClick = (e) => {
    setFormValue('creditReportConsent', !!e?.target?.checked)
  }
  return (
    <div css={termsCss}>
      <FormCheckboxInput
        checkboxLabel={
          <FormattedMessage
            defaultMessage='I have read and agree to the following:'
            id='sputnik.FCRATerms__RNICJi'
          />
        }
        checked={!!creditReportConsent}
        dataTestId={makeTestId('checkbox')}
        name='creditReportConsent'
        onChange={handleTermsClick}
      />
      <div className={getClassName('list')}>
        <ul>
          <li>
            <TermsAndConditionsLink
              alternateLinkString={
                <FormattedMessage
                  defaultMessage='{isBusinessOnly, select, 
                  true {Business credit report consent}
                  other {Credit report consent}
                  }'
                  id='sputnik.FCRATerms__b1sY5l'
                  values={{ isBusinessOnly: getIsDSABusinesOnly(dsaVersion) }}
                />
              }
              className={getClassName('credit-reporting-link')}
              dataTestId={makeTestId('credit-reporting-link')}
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage='I certify to the best of my knowledge that the information provided above is complete and correct.'
              id='sputnik.FCRATerms__kfpX/1'
            />
          </li>
        </ul>
      </div>
    </div>
  )
}

const termsCss = ({ type }) => css`
  max-width: 100%;
  .FCRATerms-list {
    ul {
      margin-left: var(--tri-space-650);
    }
  }
  ${type.triFontDesktopBodySmall}
  color: var(--tri-color-text-primary)
`
export default FCRATerms
