import React from 'react'

import { FormattedMessage } from '@divvy-web/i18n'

import AuthorizedSignerForm from '@sputnik/pages/AuthorizedSigner/AuthorizedSignerForm'
import BusinessInfoForm from '@sputnik/pages/BusinessInfo/BusinessInfoForm'
import CompanyOwnersForm from '@sputnik/pages/CompanyOwners/CompanyOwnersForm'
import FinanceInfoForm from '@sputnik/pages/FinanceInfo/FinanceInfoForm'
import ReviewAndSign from '@sputnik/pages/ReviewAndSign/ReviewAndSign'
import { PAGE_NAME_STRINGS } from '@sputnik/resources/constants'

const {
  PAGE_BUSINESS_INFO,
  PAGE_FINANCE_INFO,
  PAGE_AUTHORIZED_SIGNER,
  PAGE_COMPANY_OWNERS,
  PAGE_REVIEW_AND_SIGN,
  PAGE_REVIEW_AND_VALIDATE,
} = PAGE_NAME_STRINGS

export const regularPages = (
  isMigration = false,
  isRequestingCorrection = false,
  isMajorAccountChange = false,
  showWebBank = false,
) => {
  return [
    {
      component: (props) => <BusinessInfoForm {...props} />,
      correctionSection: 'BUSINESS',
      description: (businessName) => {
        if (!showWebBank) {
          return (
            <FormattedMessage
              defaultMessage='{hasBusinessName, select,
              true {Welcome to the first page of the credit application for {businessName}}
              other {Welcome to the first page of the credit application}
              }'
              id='sputnik.pages__sp1cP2'
              values={{ hasBusinessName: !!businessName, businessName }}
            />
          )
        }
        if (showWebBank) {
          return (
            <FormattedMessage
              defaultMessage='{hasBusinessName, select,
              true {Please provide details for {businessName}}
              other {Please provide details for your business}
              }'
              id='sputnik.pages__lJz2ZN'
              values={{ hasBusinessName: !!businessName, businessName }}
            />
          )
        }
      },
      hidePrevious: true,
      mutationKey: PAGE_BUSINESS_INFO,
      name: PAGE_BUSINESS_INFO,
      route: 'business-info',
      showInviteCollaboratorsLink: true,
      title: (
        <FormattedMessage
          defaultMessage='Business information'
          id='sputnik.pages__jMVWGg'
        />
      ),
    },
    {
      component: (props) => <FinanceInfoForm {...props} />,
      correctionSection: 'FINANCIAL',
      description: (
        <FormattedMessage
          defaultMessage='Let’s talk money.'
          id='sputnik.pages__DwoEG5'
        />
      ),
      exclude: isMigration || isMajorAccountChange,
      hidePrevious: false,
      mutationKey: PAGE_FINANCE_INFO,
      name: PAGE_FINANCE_INFO,
      route: 'finance-info',
      showInviteCollaboratorsLink: true,
      title: (
        <FormattedMessage
          defaultMessage='Financial information'
          id='sputnik.pages__ayfPbb'
        />
      ),
    },
    {
      component: (props) => <AuthorizedSignerForm {...props} />,
      correctionSection: 'AS',
      description: (
        <FormattedMessage
          defaultMessage='We’re going to need a signature soon.'
          id='sputnik.pages__BZUE0K'
        />
      ),
      hidePrevious: isRequestingCorrection,
      mutationKey: PAGE_AUTHORIZED_SIGNER,
      name: PAGE_AUTHORIZED_SIGNER,
      route: 'authorized-signer',
      showInviteCollaboratorsLink: true,
      title: (
        <FormattedMessage
          defaultMessage='Authorized signer'
          id='sputnik.pages__2DRSsN'
        />
      ),
    },
    {
      component: (props) => <CompanyOwnersForm {...props} />,
      correctionSection: 'BO',
      description: (
        <FormattedMessage
          defaultMessage='{showWebBank, select, 
              true {This is anyone who owns directly or indirectly at least 25% of the company.}
              other {This is anyone who owns at least 25% of the company.}}'
          id='sputnik.pages__bh8hel'
          values={{ showWebBank }}
        />
      ),
      hidePrevious: isRequestingCorrection,
      mutationKey: PAGE_COMPANY_OWNERS,
      name: PAGE_COMPANY_OWNERS,
      route: 'company-owners',
      showInviteCollaboratorsLink: true,
      title: (
        <FormattedMessage
          defaultMessage='Company owners'
          id='sputnik.pages__FvqLu2'
        />
      ),
    },
    {
      component: (props) => <ReviewAndSign {...props} />,
      description: isRequestingCorrection ? (
        ''
      ) : (
        <FormattedMessage
          defaultMessage='You made it!'
          id='sputnik.pages__Kxz1FB'
        />
      ),
      hidePrevious: isMigration || isRequestingCorrection || isMajorAccountChange,
      mutationKey: isMigration ? PAGE_REVIEW_AND_VALIDATE : PAGE_REVIEW_AND_SIGN,
      name: PAGE_REVIEW_AND_SIGN,
      route: 'review-and-sign',
      showInviteCollaboratorsLink: false,
      title: isMigration ? (
        <FormattedMessage
          defaultMessage='Review and validate'
          id='sputnik.pages__Y9JQyE'
        />
      ) : (
        <FormattedMessage
          defaultMessage='Review and sign'
          id='sputnik.pages__sedqFw'
        />
      ),
    },
  ]
    .filter(({ exclude }) => !exclude)
    .map((page, index) => ({ ...page, index }))
}
