import React from 'react'

import { createTrinityReactAdaptor } from '@bdc-libs/trinity.react-utils'
import { THEMES, TriThemeContainer } from '@bdc-libs/trinity.themes'

const namespace = 'SPUTNIK'

const adapt = createTrinityReactAdaptor(namespace, React)
export const TrinityThemeContainer = adapt(TriThemeContainer)
export const themes = THEMES
