import React from 'react'
import { useQuery } from '@apollo/client'
import { bool, string } from 'prop-types'

import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormTextInput, useForm } from '@divvy-web/skylab.form'

import { DropdownInput, MaskedFormInput } from '@sputnik/components/FormInputs'
import FormElementWrapper from '@sputnik/components/FormInputs/FormElementWrapper'
import { dateStringToEpoch, formatDateString, getFormattedDate } from '@sputnik/components/utils'
import GetCountryCodes from '@sputnik/pages/gql/GetCountryCodes.gql'
import { countriesMapper } from '@sputnik/utils/countryUtils'

const PassportInfo = ({
  alwaysShowError = false,
  companyOwnersClass = '',
  namePrefix = '',
  readOnly,
  dataTestId,
  pathName,
}) => {
  const { setFormValue } = useForm()
  const { data } = useQuery(GetCountryCodes)
  const issuingCountries = countriesMapper(data?.countryCodes)
  const makeTestId = useMakeTestId('passport-information', dataTestId)

  const handleBlur = (event) => {
    const { value } = event.target
    // If the year is entered as two digits, format it correcly
    if (value?.length === 8) {
      const epochDate = dateStringToEpoch(value)
      setFormValue(namePrefix + 'passportExpiration', getFormattedDate(epochDate))
    }
  }

  const onDateInputChange = (evt) => {
    const { value } = evt.target
    setFormValue(namePrefix + 'passportExpiration', formatDateString(value))
  }

  return (
    <>
      <div className={`field-row ${companyOwnersClass}`}>
        <div className={`field-container fs-unmask ${companyOwnersClass}`}>
          <FormElementWrapper>
            <MaskedFormInput
              alwaysShowError={alwaysShowError}
              className='fs-mask'
              dataTestId={makeTestId('passportId')}
              formFieldClassName='passportId'
              label={
                <FormattedMessage
                  defaultMessage='Passport number'
                  id='sputnik.PassportInfo__7DeEPg'
                />
              }
              name={namePrefix + 'passportNumberDisplay'}
              pathName={pathName}
              placeholder={
                <FormattedMessage
                  defaultMessage='Passport number'
                  id='sputnik.PassportInfo__7DeEPg'
                />
              }
              readOnly={readOnly}
            />
          </FormElementWrapper>
        </div>
        <div className={`field-container fs-mask ${companyOwnersClass}`}>
          <FormElementWrapper>
            <DropdownInput
              isSearchable
              alwaysShowError={alwaysShowError}
              autoComplete='none'
              className='issuingCountry'
              dataTestId={makeTestId('issuingCountry')}
              items={issuingCountries}
              label={
                <FormattedMessage
                  defaultMessage='Issuing Country'
                  id='sputnik.PassportInfo__K192VN'
                />
              }
              name={namePrefix + 'passportCountry'}
              placeholder={
                <FormattedMessage
                  defaultMessage='Issuing country'
                  id='sputnik.PassportInfo__GdcAVX'
                />
              }
              readOnly={readOnly}
            />
          </FormElementWrapper>
        </div>
      </div>
      <div className={`field-row ${companyOwnersClass}`}>
        <div className={`field-container ${companyOwnersClass}`}>
          <div className='passport-expiration-container'>
            <FormElementWrapper isDate>
              <FormTextInput
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                className='passportExpiration fs-exclude'
                dataTestId={makeTestId('passportExpiration')}
                inputMode='numeric'
                label={
                  <FormattedMessage
                    defaultMessage='Expiration date'
                    id='sputnik.PassportInfo__CICBj0'
                  />
                }
                name={namePrefix + 'passportExpiration'}
                placeholder={
                  <FormattedMessage
                    defaultMessage='Expiration date'
                    id='sputnik.PassportInfo__CICBj0'
                  />
                }
                readOnly={readOnly}
                onBlur={(e) => handleBlur(e)}
                onChange={(e) => onDateInputChange(e)}
              />
            </FormElementWrapper>
          </div>
        </div>
      </div>
    </>
  )
}

PassportInfo.propTypes = {
  alwaysShowError: bool,
  companyOwnersClass: string,
  dataTestId: string.isRequired,
  namePrefix: string,
  pathName: string.isRequired,
  readOnly: bool,
}

export default PassportInfo
