import React from 'react'
import { css } from '@emotion/core'
import { GetCreditApplicationTilaInfo_creditApplication_CreditApplication as CreditApplicationType } from 'src/pages/gql/GetCreditApplicationTilaInfo.gql'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import CheckboxInput from '@divvy-web/skylab.checkboxinput'
import Spinner from '@divvy-web/skylab.spinner'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'

import PrivacyPolicyLink from '@sputnik/components/PrivacyPolicyLink'
import TermsAndConditionsLink from '@sputnik/components/TermsAndConditionsLink'
import { useGetDsaAccessTokenLazyQuery } from '@sputnik/pages/gql/GetDsaAccessToken.gql'
import { termsMapper } from '@sputnik/pages/ReviewAndSign/utils'
import { MIGRATION } from '@sputnik/resources/constants'
import { logError } from '@sputnik/utils/loggerUtils'

interface ApplicationTermsProps {
  creditApplication: CreditApplicationType
  hasAgreedToTerms: boolean
  disableApplicationTerms: boolean
  onCheckboxClick: (e: React.ChangeEvent) => void
}

const ApplicationTerms = ({
  hasAgreedToTerms,
  disableApplicationTerms,
  creditApplication,
  onCheckboxClick,
}: ApplicationTermsProps) => {
  const [getClassName, makeTestId] = useNameFormatter('ApplicationTerms')
  const { dsaVersion, recordType, financeInfo, salesforceCreditId } = creditApplication || {}
  const termLink = dsaVersion && `${termsMapper[recordType][dsaVersion]}`
  const isMigration = recordType === MIGRATION
  const bankAccountLastFour = financeInfo?.bankInfo?.accountNumberLastFour || ''

  const [getDsaAccessToken, { loading: isLoadingDsaAccessToken }] = useGetDsaAccessTokenLazyQuery({
    variables: { creditApplicationId: salesforceCreditId },
    onCompleted: (data) => {
      const generatedDsaDocumentUrl = `${window.__DivvyEnvironment.ONBOARDING_BACKEND}/dsa/${salesforceCreditId}?dsa_token=${data?.dsaAccessToken?.dsaToken}`
      window.open(generatedDsaDocumentUrl, '_blank', 'noopener, noreferrer')
    },
    onError: (error: { message: any }) => {
      logError({
        attributes: {
          action: 'getDsaAccessToken',
          message: error?.message,
          result: 'Error getting DSA access token to generate DSA document',
        },
        eventName: 'applicationTerms',
      })
    },
  })

  if (!creditApplication || isLoadingDsaAccessToken) return <Spinner centered />

  const handleCreditTermsClick = () => {
    getDsaAccessToken()
  }
  return (
    <div
      css={termsCss}
      data-testid={makeTestId('')}
    >
      <CheckboxInput
        checked={hasAgreedToTerms}
        dataTestId={makeTestId('checkbox')}
        disabled={disableApplicationTerms}
        isSmall={false}
        label={
          <FormattedMessage
            defaultMessage='I have read and agree to the following:'
            id='sputnik.ApplicationTerms__RNICJi'
          />
        }
        name='applicationAgreementTerms'
        onChange={onCheckboxClick}
      />
      <div className={getClassName('list')}>
        <ul>
          <li>
            <TextLink
              className={getClassName('creditTerms-link')}
              dataTestId={makeTestId('creditTerms-link')}
              size={TextLinkSizes.REGULAR}
              onClick={handleCreditTermsClick}
            >
              <FormattedMessage
                defaultMessage='Credit terms'
                id='sputnik.ApplicationTerms__bz/EmN'
              />
            </TextLink>
          </li>
          <li>
            <TermsAndConditionsLink dataTestId={makeTestId('terms-and-conditions-link')} />
          </li>
          <li>
            <PrivacyPolicyLink dataTestId={makeTestId('privacy-policy-link')} />
          </li>
          <li>
            <FormattedMessage
              defaultMessage='<textlink>ACH authorization</textlink>{hasBankAccount, select,
              true { (account ending in {bankAccountLastFour})}
              other {}
              }'
              id='sputnik.ApplicationTerms__BQ+Tma'
              values={{
                textlink: (chunks) => (
                  <TextLink
                    className={getClassName('ach-auth-link')}
                    dataTestId={makeTestId('ach-auth-link')}
                    href={termLink || ''}
                    size={TextLinkSizes.REGULAR}
                    target='_blank'
                  >
                    {chunks}
                  </TextLink>
                ),
                hasBankAccount: !!bankAccountLastFour,
                bankAccountLastFour,
              }}
            />
          </li>
          {isMigration && (
            <li>
              <FormattedMessage
                defaultMessage='I certify to the best of my knowledge that the information provided above is complete and correct.'
                id='sputnik.ApplicationTerms__6Qd2rG'
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

const termsCss = ({ type }) => css`
  max-width: 100%;
  .ApplicationTerms-list {
    ul {
      margin-left: var(--tri-space-650);
    }
  }
  ${type.triFontDesktopBodyMedium}
  color: var(--tri-color-text-primary)

  .ApplicationTerm-list {
    margin-top: var(--tri-space-150);
    ${type.triFontDesktopBodySmall}
  }
`
export default ApplicationTerms
