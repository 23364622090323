import React, { useContext, useState } from 'react'
import { css } from '@emotion/core'
import { array, bool, func, node, string } from 'prop-types'

import useDidMount from '@divvy-web/hooks.usedidmount'
import { useGetClassName } from '@divvy-web/hooks.usenameformatter'
import Dropdown from '@divvy-web/skylab.dropdown'
import { FormContext } from '@divvy-web/skylab.form'
import FormField from '@divvy-web/skylab.formfield'
import ListItem from '@divvy-web/skylab.listitem'
import { find, identity, isEmpty, propEq } from '@divvy-web/utils'

import { getRegExStartOfString } from '@sputnik/utils/regexUtils'

const DropdownInput = ({
  alwaysShowError,
  caption,
  className,
  dataTestId,
  disabled,
  errorCaption,
  hasError,
  isSearchable,
  items,
  label,
  name,
  placeholder,
  ...props
}) => {
  useDidMount(() => {
    const nodes = document.querySelectorAll('[id^=downshift-]')
    nodes.forEach((node) => {
      node.autocomplete = '__nope'
      node.setAttribute('name', Date.now().toString())
    })
  })

  const { formValues, getValidationErrors, setFormValue } = useContext(FormContext)
  const [inputValue, setInputValue] = useState('')

  const validationError = getValidationErrors(name)
  const selectedValue = formValues[name]
  const selectedItem = find(propEq('value', selectedValue))(items)

  const getClassName = useGetClassName('FormDropdown', className)

  const renderDropdownItem = (item, index, getItemProps, highlightedIndex) => (
    <ListItem
      key={index}
      className='fs-mask'
      isHighlighted={highlightedIndex === index}
      isSelected={selectedItem && selectedItem.value === item.value}
      label={item.label}
      value={item.value}
      {...getItemProps({
        index,
        item: item,
        key: item.value,
      })}
    />
  )

  const renderDropdownItems = ({ getItemProps, highlightedIndex }) => {
    if (isEmpty(inputValue)) {
      return items.map((item, index) => renderDropdownItem(item, index, getItemProps, highlightedIndex))
    }

    const matchString = inputValue?.toLowerCase()
    const matcher = getRegExStartOfString(matchString)
    return items.reduce((itemsArray, item) => {
      matcher.test(item.label.toLowerCase()) &&
        itemsArray.push(renderDropdownItem(item, itemsArray.length, getItemProps, highlightedIndex))

      return itemsArray
    }, [])
  }

  const handleSelectionChange = (data) => {
    setFormValue(name, data?.value ?? '')
  }

  const handleInputChange = (inputVal) => {
    setInputValue(inputVal)
  }

  return (
    <div css={dropdownStyles}>
      <FormField
        className={getClassName('field')}
        dataTestId={dataTestId}
        inputId={props.name}
        label={label}
      >
        <Dropdown
          darkened
          hideSearchIcon
          alwaysShowError={alwaysShowError}
          caption={caption}
          className={getClassName('dropdown')}
          dataTestId={dataTestId}
          disabled={disabled}
          errorCaption={errorCaption || validationError}
          hasError={hasError || !!validationError}
          inputValue={inputValue}
          isSearchable={isSearchable}
          placeholder={placeholder}
          value={selectedItem}
          onInputValueChanged={handleInputChange}
          onSelect={handleSelectionChange}
          {...props}
        >
          {renderDropdownItems}
        </Dropdown>
      </FormField>
    </div>
  )
}

const dropdownStyles = css`
  & .SingleSelectDropdown-input {
    min-width: auto;
    width: 100%;
  }

  & ul {
    background: var(--tri-color-fill-primary);
    max-height: 320px;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
  }
`

DropdownInput.propTypes = {
  alwaysShowError: bool,
  caption: node,
  className: string,
  dataTestId: string,
  disabled: bool,
  errorCaption: node,
  hasError: bool,
  isSearchable: bool,
  items: array,
  label: node.isRequired,
  name: string,
  placeholder: node,
  value: string,
}

export default DropdownInput
