import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { css } from '@emotion/core'
import { bool } from 'prop-types'

import useDidMount from '@divvy-web/hooks.usedidmount'
import { FormattedMessage } from '@divvy-web/i18n'
import Banner, { BANNER_TYPE_ERROR } from '@divvy-web/skylab.banner'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import { FormContext, FormTextInput } from '@divvy-web/skylab.form'
import Modal from '@divvy-web/skylab.modal'
import Spinner from '@divvy-web/skylab.spinner'
import TextLink from '@divvy-web/skylab.textlink'
import { curry } from '@divvy-web/utils'

import { DropdownInput, FormEmailInput, MaskedFormInput } from '@sputnik/components'
import ExternalBankDeleteModal from '@sputnik/components/ExternalBankDeleteModal/ExternalBankDeleteModal'
import FormElementWrapper from '@sputnik/components/FormInputs/FormElementWrapper'
import {
  getFormattedMaskableNumericString,
  normalizeCurrency,
  normalizeSmartQuotesAndEmdashes,
} from '@sputnik/components/utils'
import useExternalBankOptions from '@sputnik/hooks/useExternalBankOptions'
import useFinanceInfoHooks from '@sputnik/hooks/useFinanceInfoHooks'
import RemoveConnection from '@sputnik/pages/gql/mutations/RemoveConnection.gql'
import { formStyles } from '@sputnik/pages/styles/applicationPageSharedStyles'
import { PAGE_NAME_STRINGS } from '@sputnik/resources/constants'
import { logError, logInfo } from '@sputnik/utils/loggerUtils'
import { RawCreditAppContext } from '@sputnik/utils/RawCreditAppContext'

import ExternalBankInfoCard from './ExternalBankInfoCard'
import { MANUAL_ID, MANUAL_SOURCE } from './financeInfoConstants'
import MoneyMoverMXWidget from './MoneyMoverMXWidget'
import { BI_MONTHLY, MONTHLY, WEEKLY } from './utils/rewardUtils'

const FinanceInfoForm = ({ alwaysShowError = false, isReview = false, readOnly = false }) => {
  const bottomRef = useRef(null)

  const { appId } = useParams()
  const { companyDivvyUuid } = useContext(RawCreditAppContext)

  const { formIsValid, formValues, getFormValue, setFormValue } = useContext(FormContext)
  const { hasOutsourcedAccountant, selectedExternalId } = formValues

  const {
    clearSelectedExternalId,
    closeUnavailableBankModal,
    handleUnverifiedBankOptions,
    isUnavailableBankModalShowing,
    setSelectedExternalId,
    switchToManualBankOption,
  } = useFinanceInfoHooks()

  const isExternalBankSelected = !!selectedExternalId && selectedExternalId !== MANUAL_ID

  const {
    allAvailableBankOptions,
    availableBdcBankOptions,
    availableMxBankOptions,
    didLoadExternalOptions,
    didLoadMxOptions,
    isSyncingBankConnection,
    loadingMxBankOptions,
    refetchConnections,
    setIsSyncingBankConnection,
    setMxBankOptionNotJustAdded,
    syncBankConnectionAndUpdateInternalState,
    unavailableMxBankOptions,
  } = useExternalBankOptions(companyDivvyUuid, isReview, handleUnverifiedBankOptions, isExternalBankSelected)

  const showExternalSelection = useMemo(
    () =>
      allAvailableBankOptions?.length > 0 || loadingMxBankOptions?.length > 0 || unavailableMxBankOptions?.length > 0,
    [allAvailableBankOptions?.length, loadingMxBankOptions?.length, unavailableMxBankOptions?.length],
  )

  const unavailableMxOptionsExist = useMemo(
    () => unavailableMxBankOptions?.length > 0,
    [unavailableMxBankOptions?.length],
  )

  const [removeConnection] = useMutation(RemoveConnection)

  const [isMXConnectionError, setIsMXConnectionError] = useState(false)
  const [showNamesInputs, setShowNamesInputs] = useState(hasOutsourcedAccountant === 'yes')
  const [isShowingMXWidget, setIsShowingMXWidget] = useState(false)
  const [institutionName, setInstitutionName] = useState()

  const [toDeleteBankInfo, setToDeleteBankInfo] = useState({})

  const showManualBankInput = useMemo(() => selectedExternalId === MANUAL_ID, [selectedExternalId])

  const isShowNoExternalAccountSelectedError = !formIsValid && !getFormValue('selectedExternalId')
  const hasMxBankOptions =
    unavailableMxBankOptions?.length > 0 || availableMxBankOptions?.length > 0 || loadingMxBankOptions?.length > 0

  const hasBankValidationError = alwaysShowError && !getFormValue('selectedExternalId')

  const onSyncError = useCallback(
    (error) => {
      setIsSyncingBankConnection(false)
      setIsShowingMXWidget(false)
      setIsMXConnectionError(true)
      if (!isExternalBankSelected) {
        switchToManualBankOption()
      }
      logError({
        attributes: {
          action: 'onConnectionAttempted',
          message: `MX Bank connection failed for credit app id: ${appId}`,
          result: error,
        },
        eventName: 'BankManager',
      })
    },
    [appId, isExternalBankSelected, setIsSyncingBankConnection, switchToManualBankOption],
  )

  useEffect(() => {
    if (!isReview && (!selectedExternalId || selectedExternalId === MANUAL_ID)) {
      const autoSelect = availableMxBankOptions.filter((c) => c.justAdded)
      if (autoSelect?.length === 1) {
        setSelectedExternalId(autoSelect[0].id)
        setMxBankOptionNotJustAdded(autoSelect[0].id)
      }
    }
  }, [availableMxBankOptions, isReview, selectedExternalId, setMxBankOptionNotJustAdded, setSelectedExternalId])

  useEffect(() => {
    if (
      !isReview &&
      !selectedExternalId &&
      didLoadExternalOptions &&
      didLoadMxOptions &&
      allAvailableBankOptions?.length === 0 &&
      unavailableMxOptionsExist &&
      !isMXConnectionError
    ) {
      switchToManualBankOption()
    }
  }, [
    allAvailableBankOptions?.length,
    didLoadExternalOptions,
    didLoadMxOptions,
    isMXConnectionError,
    isReview,
    selectedExternalId,
    switchToManualBankOption,
    unavailableMxOptionsExist,
  ])

  const executeScrollToManualBankInfo = () => bottomRef.current?.scrollIntoView({ behavior: 'smooth' })

  useDidMount(() => {
    if (
      !isReview &&
      !getFormValue('annualRevenue') &&
      !getFormValue('desiredCredit') &&
      !getFormValue('expectedSpend') &&
      getFormValue('hasOutsourcedAccountant') !== 'yes'
    ) {
      setFormValue('hasOutsourcedAccountant', undefined)
    }
  })

  useEffect(() => {
    if (!isReview && showManualBankInput && !getFormValue('bankName')) {
      executeScrollToManualBankInfo()
    }
  }, [getFormValue, isReview, showManualBankInput])

  useEffect(() => {
    setShowNamesInputs(hasOutsourcedAccountant === 'yes')
    if (!showNamesInputs) {
      const fields = ['oaFirstName', 'oaLastName', 'oaEmail']
      fields.forEach((field) => setFormValue(field, ''))
    }
  }, [hasOutsourcedAccountant, setFormValue, showNamesInputs])

  useEffect(() => {
    if (selectedExternalId !== MANUAL_ID) {
      const externalBank = allAvailableBankOptions?.find(({ id }) => id === selectedExternalId)
      // check if it actually found the bank since it may still be loading
      if (externalBank) {
        setFormValue('selectedBankInfo', externalBank)
        setFormValue('source', externalBank.source)
      }
    } else {
      setFormValue('selectedBankInfo', { source: MANUAL_SOURCE })
      setFormValue('source', MANUAL_SOURCE)
    }
  }, [allAvailableBankOptions, selectedExternalId, setFormValue])

  const onMXWidgetClose = useCallback(() => {
    setIsShowingMXWidget(false)
  }, [])

  const onMXWidgetManualSelection = useCallback(() => {
    setIsShowingMXWidget(false)
    switchToManualBankOption()
  }, [switchToManualBankOption])

  const onWidgetConnectionReady = useCallback(
    (connectionGuid, userGuid) => {
      logInfo({
        attributes: {
          action: 'onConnectionAttempted',
          message: `MX Bank connection attempted on credit app id: ${appId} for institution: ${institutionName}`,
          result: 'Attempted MX bank connection',
        },
        eventName: 'BankManager',
      })

      syncBankConnectionAndUpdateInternalState(connectionGuid, userGuid, onSyncError)
    },
    [appId, institutionName, onSyncError, syncBankConnectionAndUpdateInternalState],
  )

  const onConnectionRetry = () => {
    setIsMXConnectionError(false)
    setSelectedExternalId(undefined)
    setIsShowingMXWidget(true)
  }

  const handleConnectionDelete = (externalConnectionGuid) => {
    removeConnection({ variables: { externalConnectionGuid: externalConnectionGuid } })
      .then((res) => {
        logInfo({
          attributes: {
            action: 'removeMXConnection',
            result: 'Successfully removed MX bank connection',
          },
          eventName: 'SignUp',
        })
        refetchConnections(toDeleteBankInfo, clearSelectedExternalId)
      })
      .finally((_) => setToDeleteBankInfo({}))
  }

  const externalConnectionBtnText = showExternalSelection ? (
    <FormattedMessage
      defaultMessage='Connect another bank account'
      id='sputnik.FinanceInfoForm__ZOod+C'
    />
  ) : (
    <FormattedMessage
      defaultMessage='Connect business bank account'
      id='sputnik.FinanceInfoForm__9TSu2U'
    />
  )

  const genericErrorBannerContent = (
    <>
      <FormattedMessage
        defaultMessage='We were unable to automatically fetch the bank information we need, please enter your bank information manually below '
        id='sputnik.FinanceInfoForm__p+BDjK'
      />
      <TextLink onClick={onConnectionRetry}>
        <FormattedMessage
          defaultMessage='OR retry connecting your bank account.'
          id='sputnik.FinanceInfoForm__S4ZnGY'
        />
      </TextLink>
    </>
  )

  const unavailableBankErrorBannerContent = (
    <>
      <FormattedMessage
        defaultMessage='Thanks for connecting your bank account! Your bank has provided most of the information we need, but we’ll need your help with the remaining details. Please enter this information below '
        id='sputnik.FinanceInfoForm__8XQBnQ'
      />
      <TextLink onClick={onConnectionRetry}>
        <FormattedMessage
          defaultMessage='OR try connecting another bank account.'
          id='sputnik.FinanceInfoForm__hAq671'
        />
      </TextLink>
    </>
  )

  const modalActionButtons = [
    <BasicButton
      key='close'
      dataTestId='finance-info-form-unavailable-info-modal'
      onClick={closeUnavailableBankModal}
    >
      <FormattedMessage
        defaultMessage='Close'
        id='sputnik.FinanceInfoForm__rbrahO'
      />
    </BasicButton>,
  ]

  return (
    <div
      css={(theme) => [
        formStyles({ isReview, readOnly }, theme),
        financeInfoStyles({ hasBankValidationError, readOnly }, theme),
      ]}
      data-testid='finance-info-form'
    >
      {isUnavailableBankModalShowing && (
        <Modal
          actions={modalActionButtons}
          dataTestId='finance-info-form-unavailable-info-modal'
          headerColor='accent'
          isShowing={isUnavailableBankModalShowing}
          title={
            <FormattedMessage
              defaultMessage='Looks like we need a little more info'
              id='sputnik.FinanceInfoForm__1XlcsJ'
            />
          }
          onCloseClick={closeUnavailableBankModal}
        >
          <p>
            <FormattedMessage
              defaultMessage='Thanks for connecting your bank account! This will help speed things along. While your bank provided most of the info we need, we’ll need your help with a few remaining details. Please provide these details in order to continue.'
              id='sputnik.FinanceInfoForm__+oa8kG'
            />
          </p>
        </Modal>
      )}
      <ExternalBankDeleteModal
        bankInfo={toDeleteBankInfo}
        onCancel={setToDeleteBankInfo}
        onConfirm={handleConnectionDelete}
      />
      <div className='finance-form-field-wrapper'>
        {!isReview && isMXConnectionError && (
          <div className='field-row fs-unmask'>
            <Banner
              bannerType={BANNER_TYPE_ERROR}
              messageContent={genericErrorBannerContent}
              titleText='We need a little more infomation from you'
            />
          </div>
        )}

        <div className='field-row fs-unmask'>
          <div className='field-container'>
            <FormElementWrapper isCurrency>
              <FormTextInput
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                dataTestId='finance-info-form-annual-rev'
                label={
                  <FormattedMessage
                    defaultMessage='Business annual revenue'
                    id='sputnik.FinanceInfoForm__od5mjc'
                  />
                }
                name='annualRevenue'
                normalize={normalizeCurrency}
                placeholder={
                  <FormattedMessage
                    defaultMessage='Business annual revenue'
                    id='sputnik.FinanceInfoForm__od5mjc'
                  />
                }
                readOnly={readOnly}
              />
            </FormElementWrapper>
          </div>
          <div className='field-container'>
            <FormElementWrapper isCurrency>
              <FormTextInput
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                dataTestId='finance-info-form-credit'
                label={
                  <FormattedMessage
                    defaultMessage='Your desired line of credit'
                    id='sputnik.FinanceInfoForm__bU4xAd'
                  />
                }
                name='desiredCredit'
                normalize={normalizeCurrency}
                placeholder={
                  <FormattedMessage
                    defaultMessage='What is your desired line of credit?'
                    id='sputnik.FinanceInfoForm__/q2w3G'
                  />
                }
                readOnly={readOnly}
              />
            </FormElementWrapper>
          </div>
        </div>
        <div className='field-row fs-unmask'>
          <div className='field-container'>
            <FormElementWrapper isCurrency>
              <FormTextInput
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                dataTestId='finance-info-form-expected-spend'
                label={
                  <FormattedMessage
                    defaultMessage='Expected monthly spend'
                    id='sputnik.FinanceInfoForm__dsX8S3'
                  />
                }
                name='expectedSpend'
                normalize={normalizeCurrency}
                placeholder={
                  <FormattedMessage
                    defaultMessage='What is your expected monthly spend?'
                    id='sputnik.FinanceInfoForm__LywRNT'
                  />
                }
                readOnly={readOnly}
              />
            </FormElementWrapper>
          </div>

          <div
            className='field-container'
            id='billing-cycle-field'
          >
            <FormElementWrapper>
              <DropdownInput
                alwaysShowError={alwaysShowError || readOnly}
                dataTestId='rewards-selector'
                isSearchable={false}
                items={[
                  { label: 'Weekly', value: WEEKLY },
                  { label: 'Twice a month', value: BI_MONTHLY },
                  { label: 'Monthly', value: MONTHLY },
                ]}
                label={
                  <FormattedMessage
                    defaultMessage='Billing cycle'
                    id='sputnik.FinanceInfoForm__Z7xDUp'
                  />
                }
                name='billingCycle'
                placeholder='Preferred billing/rewards cycle'
                readOnly={readOnly}
              />
            </FormElementWrapper>
          </div>
        </div>
        {isReview || (
          <div className='field-row fs-unmask'>
            <div
              className='field-container fs-unmask'
              id='has-outsourced-accountant'
            >
              <FormElementWrapper>
                <DropdownInput
                  alwaysShowError={alwaysShowError}
                  dataTestId='finance-info-form-outsourced-accountant'
                  isSearchable={false}
                  items={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                  ]}
                  label={
                    <FormattedMessage
                      defaultMessage='Outsourced accountant'
                      id='sputnik.FinanceInfoForm__h8O5Rm'
                    />
                  }
                  name='hasOutsourcedAccountant'
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Outsourced accountant'
                      id='sputnik.FinanceInfoForm__h8O5Rm'
                    />
                  }
                  readOnly={readOnly}
                />
              </FormElementWrapper>
            </div>
          </div>
        )}

        {isReview && isShowNoExternalAccountSelectedError && (
          <div className='field-row error-text'>
            <FormattedMessage
              defaultMessage='No bank account selected. Please edit section to select bank information.'
              id='sputnik.FinanceInfoForm__noz0Td'
            />
          </div>
        )}

        {showNamesInputs && (
          <>
            <div className='field-row fs-unmask'>
              <div
                className='field-container'
                id='oa-first'
              >
                <FormElementWrapper>
                  <FormTextInput
                    alwaysShowError={alwaysShowError}
                    autoComplete='none'
                    className='fs-mask'
                    dataTestId='outsourced-accountant-form-firstName'
                    label={
                      <FormattedMessage
                        defaultMessage="Accountant's first name"
                        id='sputnik.FinanceInfoForm__Bp9Anw'
                      />
                    }
                    name='oaFirstName'
                    normalize={normalizeSmartQuotesAndEmdashes}
                    placeholder={
                      <FormattedMessage
                        defaultMessage="Accountant's first name"
                        id='sputnik.FinanceInfoForm__Bp9Anw'
                      />
                    }
                    readOnly={readOnly}
                  />
                </FormElementWrapper>
              </div>
              <div
                className='field-container'
                id='oa-last'
              >
                <FormElementWrapper>
                  <FormTextInput
                    alwaysShowError={alwaysShowError}
                    autoComplete='none'
                    className='lastName fs-mask'
                    dataTestId='outsourced-accountant-form-lastName'
                    label={
                      <FormattedMessage
                        defaultMessage="Accountant's last name"
                        id='sputnik.FinanceInfoForm__ucA1p0'
                      />
                    }
                    name='oaLastName'
                    normalize={normalizeSmartQuotesAndEmdashes}
                    placeholder={
                      <FormattedMessage
                        defaultMessage="Accountant's last name"
                        id='sputnik.FinanceInfoForm__ucA1p0'
                      />
                    }
                    readOnly={readOnly}
                  />
                </FormElementWrapper>
              </div>
            </div>

            <div className='field-row'>
              <div
                className='field-container fs-unmask'
                id='oa-email'
              >
                <FormEmailInput
                  alwaysShowError={alwaysShowError}
                  autoComplete='on'
                  className='fs-mask'
                  dataTestId='outsourced-accountant-form-workEmail'
                  label={
                    <FormattedMessage
                      defaultMessage="Accountant's work email"
                      id='sputnik.FinanceInfoForm__82Lj8u'
                    />
                  }
                  name='oaEmail'
                  placeholder={
                    <FormattedMessage
                      defaultMessage="Accountant's work email"
                      id='sputnik.FinanceInfoForm__82Lj8u'
                    />
                  }
                  readOnly={readOnly}
                  type='email'
                />
              </div>
            </div>
          </>
        )}
      </div>

      {!isReview && (isSyncingBankConnection || !didLoadMxOptions || !didLoadExternalOptions) && (
        <>
          <br />
          <Spinner />
        </>
      )}

      {!isReview && !showExternalSelection && hasBankValidationError && (
        <div className='validation-error-caption'>
          <FormattedMessage
            defaultMessage='Please select a bank or enter bank information manually'
            id='sputnik.FinanceInfoForm__mt4lMR'
          />
        </div>
      )}

      {hasMxBankOptions && !isReview && didLoadExternalOptions && (
        <>
          {unavailableMxOptionsExist && !isMXConnectionError ? (
            <>
              <br />
              <div className='field-row fs-unmask'>
                <Banner
                  bannerType={BANNER_TYPE_ERROR}
                  messageContent={unavailableBankErrorBannerContent}
                  titleText='We need a little more infomation from you'
                />
              </div>
            </>
          ) : (
            <div className={hasBankValidationError ? 'mx-validation-error-caption' : 'mx-external-bank-description'}>
              <FormattedMessage
                defaultMessage='Please select the bank account you would like as your default bank account.'
                id='sputnik.FinanceInfoForm__Gg1MhM'
              />
            </div>
          )}

          {unavailableMxBankOptions.map(
            (option, index) =>
              option && (
                <ExternalBankInfoCard
                  key={index}
                  disabled
                  bankInfo={option}
                  onSelectBankInfo={() => {}}
                />
              ),
          )}

          {loadingMxBankOptions?.map(
            (option, index) =>
              option && (
                <ExternalBankInfoCard
                  key={index}
                  isLoading
                  bankInfo={option}
                  onSelectBankInfo={() => {}}
                />
              ),
          )}

          {availableMxBankOptions.map(
            (option, index) =>
              option && (
                <ExternalBankInfoCard
                  key={index}
                  bankInfo={option}
                  isDeletable={true}
                  isSelected={selectedExternalId === option.id}
                  onSelectBankInfo={setSelectedExternalId}
                  onSetToDeleteBankInfo={setToDeleteBankInfo}
                />
              ),
          )}
        </>
      )}

      {availableBdcBankOptions?.length > 0 && !isReview && (
        <>
          {!hasMxBankOptions && (
            <>
              <div
                className={hasBankValidationError ? 'bdc-validation-error-caption' : 'bdc-external-bank-description'}
              >
                <FormattedMessage
                  defaultMessage='These accounts were transfered from your bill.com account. You must select one of these accounts or add a new account.'
                  id='sputnik.FinanceInfoForm__WGOJLQ'
                />
              </div>
            </>
          )}

          {availableBdcBankOptions.map(
            (option, index) =>
              option && (
                <ExternalBankInfoCard
                  key={index}
                  bankInfo={option}
                  isSelected={selectedExternalId === option.id}
                  onSelectBankInfo={setSelectedExternalId}
                />
              ),
          )}
        </>
      )}
      {!isReview && !!isMXConnectionError && (
        <>
          <br />
          <br />
        </>
      )}
      {isReview || (
        <>
          {!isMXConnectionError && !unavailableMxOptionsExist && didLoadMxOptions && (
            <div className='connect-bank-button fs-unmask'>
              <BasicButton
                type={BASIC_BUTTON_TYPE_OUTLINED}
                onClick={(_) => setIsShowingMXWidget(true)}
              >
                {externalConnectionBtnText}
              </BasicButton>
            </div>
          )}
          <>
            {!showManualBankInput && (
              <div className='connect-manually-button fs-unmask'>
                <TextLink
                  isDark
                  dataTestId='finance-info-manual-toggle'
                  onClick={switchToManualBankOption}
                >
                  <FormattedMessage
                    defaultMessage='Enter your bank info manually'
                    id='sputnik.FinanceInfoForm__7lUpH+'
                  />
                </TextLink>
              </div>
            )}
          </>
        </>
      )}

      <div className='finance-form-field-wrapper'>
        {showManualBankInput && (
          <>
            <div className='field-row bank-names fs-unmask'>
              <div className='field-container'>
                <FormElementWrapper>
                  <FormTextInput
                    alwaysShowError={unavailableMxOptionsExist || alwaysShowError}
                    autoComplete='none'
                    className='fs-mask'
                    dataTestId='finance-info-form-bank-name'
                    label={
                      <FormattedMessage
                        defaultMessage='Bank name'
                        id='sputnik.FinanceInfoForm__QOLh4y'
                      />
                    }
                    name='bankName'
                    placeholder={
                      <FormattedMessage
                        defaultMessage='Business bank name'
                        id='sputnik.FinanceInfoForm__qMS7VK'
                      />
                    }
                    readOnly={readOnly}
                  />
                </FormElementWrapper>
              </div>
              <div className='field-container'>
                <FormElementWrapper>
                  <FormTextInput
                    alwaysShowError={unavailableMxOptionsExist || alwaysShowError}
                    autoComplete='none'
                    className='fs-mask'
                    dataTestId='finance-info-form-account-holder-name'
                    label={
                      <FormattedMessage
                        defaultMessage='Business name on checking account'
                        id='sputnik.FinanceInfoForm__XXCGCr'
                      />
                    }
                    name='accountHolderName'
                    normalize={normalizeSmartQuotesAndEmdashes}
                    placeholder={
                      <FormattedMessage
                        defaultMessage='Name on business bank account'
                        id='sputnik.FinanceInfoForm__Z3n3AT'
                      />
                    }
                    readOnly={readOnly}
                  />
                </FormElementWrapper>
              </div>
            </div>

            <div className='field-row fs-unmask'>
              <div className='field-container'>
                <FormElementWrapper>
                  <FormTextInput
                    alwaysShowError={unavailableMxOptionsExist || alwaysShowError}
                    autoComplete='none'
                    className='fs-mask'
                    dataTestId='finance-info-form-routing-number'
                    inputMode='numeric'
                    label={
                      <FormattedMessage
                        defaultMessage='Business routing number'
                        id='sputnik.FinanceInfoForm__aNS9aO'
                      />
                    }
                    maxLength={9}
                    name='routingNumber'
                    normalize={getFormattedMaskableNumericString}
                    placeholder={
                      <FormattedMessage
                        defaultMessage='Business routing number'
                        id='sputnik.FinanceInfoForm__aNS9aO'
                      />
                    }
                    readOnly={readOnly}
                  />
                </FormElementWrapper>
              </div>

              <div
                ref={bottomRef}
                className='field-container'
              >
                <MaskedFormInput
                  alwaysShowError={unavailableMxOptionsExist || alwaysShowError}
                  className='fs-mask'
                  dataTestId='finance-info-form-account-number'
                  inputMode='numeric'
                  label={
                    <FormattedMessage
                      defaultMessage='Business checking account number'
                      id='sputnik.FinanceInfoForm__tQvJ08'
                    />
                  }
                  maxLength={17}
                  name='accountNumberDisplay'
                  normalize={getFormattedMaskableNumericString}
                  pathName={PAGE_NAME_STRINGS.PAGE_FINANCE_INFO}
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Business account number'
                      id='sputnik.FinanceInfoForm__khTZGl'
                    />
                  }
                  readOnly={readOnly}
                />
              </div>
            </div>
          </>
        )}
      </div>

      {isShowingMXWidget && (
        <MoneyMoverMXWidget
          companyId={companyDivvyUuid}
          isShowing={isShowingMXWidget}
          setInstitutionName={setInstitutionName}
          onClose={onMXWidgetClose}
          onMXWidgetManualSelection={onMXWidgetManualSelection}
          onReady={onWidgetConnectionReady}
          onSyncError={onSyncError}
        />
      )}
    </div>
  )
}

FinanceInfoForm.propTypes = {
  alwaysShowError: bool,
  isReview: bool,
  readOnly: bool,
}

export default FinanceInfoForm

const financeInfoStyles = curry(
  ({ readOnly }, { mq, type }) =>
    css`
      .Banner {
        padding-left: 0;
        padding-right: 0;
      }

      .bdc-validation-error-caption {
        margin-top: var(--tri-space-500);
        margin-bottom: var(--tri-space-400);
        max-width: 568px;
        color: var(--tri-color-text-danger);
        ${type.triFontDesktopBodySmallEmphasis};
      }

      .mx-validation-error-caption {
        margin-top: var(--tri-space-500);
        margin-bottom: var(--tri-space-400);
        color: var(--tri-color-text-danger);
        ${type.triFontDesktopBodySmallEmphasis};
      }

      .validation-error-caption {
        margin-top: var(--tri-space-700);
        margin-bottom: calc(var(--tri-space-400) * -1);
        color: var(--tri-color-text-danger);
        ${type.triFontDesktopBodySmallEmphasis};
      }

      .mx-external-bank-description {
        padding: var(--tri-space-400) 0;
        max-width: 560px;
        ${type.triFontDesktopBodySmall}
      }

      .bdc-external-bank-description {
        padding: var(--tri-space-400) 0;
        max-width: 560px;
        ${type.triFontDesktopBodySmall}
      }

      .connect-bank-button {
        margin-top: var(--tri-space-900);
        margin-bottom: var(--tri-space-600);
        ${mq.xSmallMaxWidth({ marginTop: 'var(--tri-space-500)' })}
      }

      .connect-bank-button > button {
        ${mq.xSmallMaxWidth({ width: '100%' })}
      }

      .connect-manually-button button {
        margin-bottom: var(--tri-space-300);
        color: var(--tri-color-text-primary);
        text-decoration: underline;
      }

      .Icon-warningFilled {
        color: var(--tri-color-fill-warning);
      }

      .bank-names {
        margin-top: ${readOnly && 'var(--tri-space-150)'};
        ${readOnly && mq.xSmallMaxWidth({ marginTop: 'var(--tri-space-300)' })}
      }

      .error-text {
        color: var(--tri-color-text-danger);
        ${type.triFontDesktopBodySmall}
      }
    `,
)
