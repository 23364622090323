import React, { ReactNode } from 'react'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedCurrency, FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'
import Icon from '@divvy-web/skylab.icon'
import Tile from '@divvy-web/skylab.tile'

import ImageWrapper from '@sputnik/components/ImageWrapper'
import useDeviceDetect from '@sputnik/hooks/useDeviceDetect'
import { offerTileMobileCss, offerTileWebCss } from '@sputnik/pages/SuccessPreApprovedOffers/offerTileStyles'

interface OfferTileProps {
  onTileClick: () => void
  approvedAmount?: number
  approvedAmountPlaceHolder?: ReactNode
  bottomCaption: ReactNode
  image: string
  infoChip: ReactNode
  topCaption: ReactNode
  title: ReactNode
  dataTestId: string
}

const OfferTile = ({
  onTileClick,
  approvedAmount,
  approvedAmountPlaceHolder,
  bottomCaption,
  image,
  infoChip,
  topCaption,
  title,
  dataTestId,
}: OfferTileProps) => {
  const { isMobile } = useDeviceDetect()
  const [getClassName, makeTestId] = useNameFormatter('OfferTile')

  if (isMobile) {
    return (
      <Tile
        className={getClassName('')}
        css={offerTileMobileCss}
        dataTestId={makeTestId(`${dataTestId}`)}
        onClick={onTileClick}
      >
        <div className={getClassName('image-and-text-wrapper')}>
          <div>
            <ImageWrapper
              alt='security-lock'
              imageName={image}
            />
          </div>
          <div
            className={getClassName('offer-tile-approved-amount')}
            data-testid={makeTestId('offer-tile-approved-amount')}
          >
            {!!approvedAmount && (
              <div className={getClassName('mobile-amount')}>
                <FormattedCurrency
                  maximumFractionDigits={0}
                  minimumFractionDigits={2}
                  value={approvedAmount}
                />
              </div>
            )}
            {approvedAmountPlaceHolder && (
              <div className={getClassName('mobile-amount-placeholder-text')}>{approvedAmountPlaceHolder}</div>
            )}
            <div className={getClassName('mobile-title')}>{title}</div>
            <p className={getClassName('top-caption')}>{topCaption}</p>
          </div>
        </div>
        <div className={getClassName('footer')}>
          <Icon
            name='chevronRight'
            size='large'
          />
        </div>
      </Tile>
    )
  }

  return (
    <Tile
      className={getClassName('')}
      css={offerTileWebCss}
      dataTestId={makeTestId(`${dataTestId}`)}
      onClick={onTileClick}
    >
      <div className={getClassName('info-chip')}>{infoChip}</div>
      <div className={getClassName('image-and-text-wrapper')}>
        <div>
          <ImageWrapper
            alt='security-lock'
            imageName={image}
          />
        </div>
        <div
          className={getClassName('offer-tile-approved-amount')}
          data-testid={makeTestId('offer-tile-approved-amount')}
        >
          {!!approvedAmount && (
            <div className={getClassName('mobile-amount')}>
              <FormattedCurrency
                maximumFractionDigits={0}
                minimumFractionDigits={2}
                value={approvedAmount}
              />
            </div>
          )}
          {approvedAmountPlaceHolder && (
            <div className={getClassName('amount-placeholder-text')}>{approvedAmountPlaceHolder}</div>
          )}
        </div>
        <div className={getClassName('desktop-title')}>{title}</div>
        <div className={getClassName('captions')}>
          <p className={getClassName('top-caption')}>{topCaption}</p>
          <p className={getClassName('bottom-caption')}>{bottomCaption}</p>
        </div>
      </div>
      <div className={getClassName('footer')}>
        <BasicButton
          className={getClassName('review-offer')}
          dataTestId={dataTestId}
        >
          <FormattedMessage
            defaultMessage='View offer details'
            id='sputnik.OfferTile__PaI1h+'
          />
        </BasicButton>
      </div>
    </Tile>
  )
}

export default OfferTile
