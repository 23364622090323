import React, { ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'
import { useForm } from '@divvy-web/skylab.form'

import AccessCodeField from '@sputnik/components/AccessCodeField/AccessCodeField'
import PhoneVerificationMobileViewPopsheet from '@sputnik/components/PhoneVerificationMobileViewPopsheet'
import PhoneVerificationModal from '@sputnik/components/PhoneVerificationModal'
import useDeviceDetect from '@sputnik/hooks/useDeviceDetect'

interface SmsAccessCodeModalContentProps {
  hasError: boolean
  loadingResendCode: boolean
  loadingSubmitCode: boolean
  processDescription: ReactNode
  handleResendClick: () => void
  handleSubmitAccessCode: (accessCode: string) => void
}

type Form = {
  accessCode: string
}

type UseForm = <T extends object>() => Record<'formIsValid', boolean> & Record<'formValues', T>

const SmsAccessCodeModalContent = ({
  hasError,
  handleSubmitAccessCode,
  handleResendClick,
  processDescription,
  loadingResendCode,
  loadingSubmitCode,
}: SmsAccessCodeModalContentProps) => {
  const { isDesktop } = useDeviceDetect()
  const { formIsValid, formValues } = (useForm as unknown as UseForm)<Form>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [getClassName, makeTestId] = useNameFormatter('SmsAccessCodeModalContent')

  const handleModalClose = () => {
    searchParams.delete('verifySmsCodePending')
    searchParams.delete('actionSaveAndExit')
    setSearchParams(Object.fromEntries(searchParams))
  }

  const title = (
    <>
      <FormattedMessage
        defaultMessage='Enter verification code'
        id='sputnik.SmsAccessCodeModalContent__C5F7en'
      />
    </>
  )

  const actionButtons = [
    <BasicButton
      key='submit-access-code'
      className={getClassName('primary-button')}
      dataTestId={makeTestId('submit-access-code-btn')}
      disabled={!formIsValid || hasError || loadingSubmitCode || loadingResendCode}
      showSpinner={loadingSubmitCode || loadingResendCode}
      onClick={() => handleSubmitAccessCode(formValues?.accessCode)}
    >
      <FormattedMessage
        defaultMessage='Verify'
        id='sputnik.SmsAccessCodeModalContent__q5Xl0M'
      />
    </BasicButton>,
  ]

  return (
    <>
      {isDesktop && (
        <PhoneVerificationModal
          actionButtons={actionButtons}
          isShowing={!!searchParams.get('verifySmsCodePending')}
          title={title}
          onCloseClick={handleModalClose}
        >
          <AccessCodeField
            disabled={loadingSubmitCode || loadingResendCode}
            handleResendClick={handleResendClick}
            hasError={hasError}
            processDescription={processDescription}
          />
        </PhoneVerificationModal>
      )}
      {!isDesktop && (
        <PhoneVerificationMobileViewPopsheet
          actions={actionButtons}
          isShowing={!!searchParams.get('verifySmsCodePending')}
          title={title}
          onClose={handleModalClose}
        >
          <AccessCodeField
            disabled={loadingSubmitCode || loadingResendCode}
            handleResendClick={handleResendClick}
            hasError={hasError}
            processDescription={processDescription}
          />
        </PhoneVerificationMobileViewPopsheet>
      )}
    </>
  )
}

export default SmsAccessCodeModalContent
