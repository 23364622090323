import React from 'react'

import { FormattedMessage } from '@divvy-web/i18n'
import { FormCheckboxInput } from '@divvy-web/skylab.form'

interface IdTypeToggleProps {
  isUsingPassport?: boolean
  toggleSsn: () => void
}

const IdTypeToggle = ({ isUsingPassport, toggleSsn }: IdTypeToggleProps) => (
  <FormCheckboxInput
    isSmall
    checkboxLabel={
      <FormattedMessage
        defaultMessage='Non US citizen and does not have SSN.'
        id='sputnik.AuthorizedSignerForm__h5FGaJ'
      />
    }
    checked={isUsingPassport}
    className='passport-toggle fs-unmask'
    control={undefined}
    dataTestId='authorized-signer-form-passportToggle'
    formDataTestId={undefined}
    name='_passportToggle'
    onChange={toggleSsn}
  />
)

export default IdTypeToggle
