import { URL } from '@sputnik/resources/constants'

const { TERMS_AND_SERVICES_BUS, TERMS_AND_SERVICES_CRB_BUS, TERMS_AND_SERVICES_CRB_JS, TERMS_AND_SERVICES_JS } = URL

export const mapAppSections = {
  authorizedSigner: 'AUTH_SIGNER',
  businessInfo: 'BUSINESS_INFO',
  companyOwners: 'COMPANY_OWNERS',
}

export const termsMapper = {
  major_account_change: {
    business_only: TERMS_AND_SERVICES_BUS,
    crb_business_only: TERMS_AND_SERVICES_CRB_BUS,
    crb_j_s: TERMS_AND_SERVICES_CRB_JS,
    j_s: TERMS_AND_SERVICES_JS,
    wex_business_only: TERMS_AND_SERVICES_BUS,
    wex_j_s: TERMS_AND_SERVICES_JS,
  },
  migration: {
    business_only: TERMS_AND_SERVICES_CRB_BUS,
    crb_business_only: TERMS_AND_SERVICES_CRB_BUS,
    crb_j_s: TERMS_AND_SERVICES_CRB_JS,
    j_s: TERMS_AND_SERVICES_CRB_JS,
  },
  new: {
    business_only: TERMS_AND_SERVICES_BUS,
    crb_business_only: TERMS_AND_SERVICES_CRB_BUS,
    crb_j_s: TERMS_AND_SERVICES_CRB_JS,
    j_s: TERMS_AND_SERVICES_JS,
    wex_business_only: TERMS_AND_SERVICES_BUS,
    wex_j_s: TERMS_AND_SERVICES_JS,
  },
}

// add more types as they are needed
export const DSA_VERSION_TYPES = {
  CRB_B_O: 'crb_business_only',
  WEX_B_O: 'wex_business_only',
}
