import { css } from '@emotion/core'

export const successFrozenCreditContainer = ({ mq, type }) => css`
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .main-container {
    flex: 2;
    margin: 0;
    padding: 0;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .main-container::-webkit-scrollbar {
    display: none;
  }

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-2700);
    ${mq.xSmallMinWidth({ display: 'none' })};
  }

  .SuccessFrozenCredit {
    &-content-text {
      max-width: 640px;
    }

    &-what-this-means {
      ${type.triFontDesktopXSmallEmphasis}
      margin-bottom: var(--tri-space-200);
    }

    &-credit-locked {
      ${type.triFontDesktopTitleSmallEmphasis}
      margin-bottom: var(--tri-space-300);
    }

    &-copy {
      color: var(--tri-color-text-secondary);
      margin-bottom: var(--tri-space-200);
    }

    &-ice-cream-image {
      max-height: 350px;
      ${mq.xxSmallMaxWidth({
        maxWidth: '120px',
      })}
    }

    &-footer-button {
      ${mq.xxSmallMaxWidth({
        borderRadius: '0',
        height: '100%',
        width: '100%',
      })}
    }

    &-page-content {
      padding: 0 var(--tri-space-500) 0 var(--tri-space-1700);
      display: flex;
      flex-direction: column;
      height: 100%;
      ${mq.xSmallMaxWidth({
        padding: 'var(--tri-space-200)',
      })}
    }

    &-body {
      margin: auto;
      padding: var(--tri-space-450) 0;
      ${mq.xxSmallMaxWidth({
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 var(--tri-space-200) var(--tri-space-1100)',
      })}
    }

    &-body-title {
      ${type.triFontDesktopTitleLargeEmphasis}
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopTitleSmallEmphasis,
      })}
    }

    &-body-subtitle {
      padding-top: var(--tri-space-400);
      ${type.triFontDesktopBodyLarge}
      color: var(--tri-color-text-secondary);
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopBodySmall,
      })}
    }

    &-body-caption {
      padding-top: var(--tri-space-300);
      ${type.triFontDesktopBodyMedium}
      color: var(--tri-color-text-secondary);
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopBodySmall,
      })}
      ${mq.xLargeMinWidth({
        width: 'min(85%, 1000px)',
      })}
    }

    &-buttons-and-links {
      padding-top: var(--tri-space-400);
      display: flex;
      gap: var(--tri-space-300);
      ${mq.xxSmallMaxWidth({
        alignSelf: 'flex-start',
        paddingBottom: 'var(--tri-space-500)',
      })}
    }

    &-close {
      ${mq.xxSmallMaxWidth({
        borderRadius: '0',
        bottom: '0',
        height: '88px',
        left: '0',
        position: 'fixed',
        width: '100%',
      })}
    }

    &-desktop-experian-link {
      text-decoration: none;
      ${mq.xxSmallMaxWidth({
        display: 'none',
      })}
    }

    &-mobile-experian-link {
      padding-top: var(--tri-space-200);
      text-decoration: none;
      ${mq.xSmallMinWidth({
        display: 'none',
      })}
    }

    &-application-dashboard-link {
      text-decoration: none;
    }

    &-frozen-credit-mobile-image-div {
      ${mq.xxSmallMaxWidth({
        paddingBottom: 'var(--tri-space-200)',
      })}
    }
  }

  .sidebar-image {
    position: absolute;
    bottom: -28px;
    ${mq.xSmallMinWidth({
      right: '-70px',
    })}
  }
`

// SuccessFrozenCreditPopsheetStyles
export const successFrozenCreditPopsheetContainer = ({ type, mq }) => css`
  display: flex;

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-3000);
    ${mq.xSmallMinWidth({ display: 'none' })};
  }

  .SuccessFrozenCreditPopsheet {
    &-mobile-popsheet-image-wrapper {
      text-align: center;
      padding-bottom: var(--tri-space-300);
    }

    &-popsheet-body-title {
      ${type.triFontDesktopTitleMedium}
      padding-bottom: var(--tri-space-200);
    }

    &-popsheet-copy {
      padding-bottom: var(--tri-space-300);
    }

    &-experian-freeze-center-link {
      text-decoration: none;
    }

    &-learn-more-link {
      padding-bottom: var(--tri-space-300);
    }

    &-go-to-experian-button {
      position: fixed;
      border-radius: 0;
      bottom: 0;
      left: 0;
      height: 88px;
      width: 100%;
    }

    &-frozen-credit-popsheet-content {
      padding-bottom: var(--tri-space-1100);
    }

    &-frozen-credit-popsheet-header-actions-group {
      justify-content: end;
    }
  }
`

export const modalCss = css`
  .SuccessFrozenCredit {
    &-frozen-credit-modal-main {
      max-width: 544px;
    }

    &-frozen-credit-modal-actions {
      justify-content: space-between;
    }
  }
`
