import React, { useContext } from 'react'
import { bool, func, number } from 'prop-types'

import { FormattedMessage } from '@divvy-web/i18n'
import Avatar from '@divvy-web/skylab.avatar'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import { FormContext } from '@divvy-web/skylab.form'
import TextLink from '@divvy-web/skylab.textlink'

import { ownerButtonCss, ownerCaptionCss, sectionViewCss } from './OwnerCardStyles'

const OwnerCard = ({
  deleteOwner,
  isNonOwnerAuthorizedSigner,
  ownerIdx,
  setSelectedOwner,
  toggleAuthSignerAsOwner,
}) => {
  const { getFormValue, validationErrors } = useContext(FormContext)
  const ownerPrefix = isNonOwnerAuthorizedSigner ? '' : `owner${ownerIdx}_`
  const isAuthorizedSigner = !isNonOwnerAuthorizedSigner && getFormValue(ownerPrefix + 'isAuthorizedSigner')
  const isOwnerCreated = !isNonOwnerAuthorizedSigner && getFormValue(ownerPrefix + 'isCreated')
  const firstName = getFormValue(ownerPrefix + 'firstName')
  const lastName = getFormValue(ownerPrefix + 'lastName')
  const initials = [firstName, lastName].map((name) => name && name[0]).join('')

  const handleDelete = (e) => {
    e.stopPropagation()
    if (isNonOwnerAuthorizedSigner || isAuthorizedSigner) {
      toggleAuthSignerAsOwner()
    } else {
      deleteOwner(ownerIdx)
    }
  }

  const hasOwnerErrors = Reflect.ownKeys(validationErrors || {}).some((key) => key.startsWith(ownerPrefix))

  if (!isOwnerCreated && !isNonOwnerAuthorizedSigner) return null

  return (
    <div css={sectionViewCss(isNonOwnerAuthorizedSigner)}>
      <button
        css={ownerButtonCss(isNonOwnerAuthorizedSigner)}
        disabled={isNonOwnerAuthorizedSigner}
        onClick={(_) => setSelectedOwner(ownerIdx)}
      >
        <Avatar
          className='fs-mask'
          fallbackText={initials}
        >
          <div className='ownerTextSection'>
            <div className='ownerHeadingText'>
              <span>
                {firstName} {lastName}
              </span>
            </div>
            <div
              className='fs-unmask'
              css={ownerCaptionCss(hasOwnerErrors, isNonOwnerAuthorizedSigner)}
            >
              <OwnerCaption
                hasOwnerErrors={hasOwnerErrors}
                isAuthorizedSigner={isAuthorizedSigner}
                isNonOwnerAuthorizedSigner={isNonOwnerAuthorizedSigner}
                ownerOrder={ownerIdx + 1}
              />
            </div>
          </div>
        </Avatar>
      </button>
      {isNonOwnerAuthorizedSigner && (
        <TextLink onClick={toggleAuthSignerAsOwner}>
          <FormattedMessage
            defaultMessage='Add as owner'
            id='sputnik.OwnerCard__mQE+G5'
          />
        </TextLink>
      )}
      {isNonOwnerAuthorizedSigner || (
        <BasicButton
          icon='trash'
          name='removeOwner'
          type={BASIC_BUTTON_TYPE_FLAT}
          onClick={handleDelete}
        />
      )}
    </div>
  )
}

OwnerCard.propTypes = {
  deleteOwner: func,
  isNonOwnerAuthorizedSigner: bool,
  ownerIdx: number,
  setSelectedOwner: func,
  toggleAuthSignerAsOwner: func.isRequired,
}

const OwnerCaption = ({ hasOwnerErrors, isAuthorizedSigner, isNonOwnerAuthorizedSigner, ownerOrder }) => {
  if (isNonOwnerAuthorizedSigner) {
    return (
      <FormattedMessage
        defaultMessage='Not an owner'
        id='sputnik.OwnerCard__JgKGg8'
      />
    )
  }

  const ownerLabel = (
    <FormattedMessage
      defaultMessage='Owner {ownerOrder}{authSignerText}'
      id='sputnik.OwnerCard__u/+P5K'
      values={{
        authSignerText: isAuthorizedSigner ? ' & Authorized Signer' : '',
        ownerOrder: ownerOrder,
      }}
    />
  )

  if (!hasOwnerErrors) return ownerLabel

  return (
    <FormattedMessage
      defaultMessage='There is an error on {ownerLabel} that must be fixed before moving on.'
      id='sputnik.OwnerCard__mFttRt'
      values={{ ownerLabel }}
    />
  )
}

OwnerCaption.propTypes = {
  hasOwnerErrors: bool.isRequired,
  isAuthorizedSigner: bool.isRequired,
  isNonOwnerAuthorizedSigner: bool,
  ownerOrder: number.isRequired,
}

export default OwnerCard
