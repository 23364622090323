import React from 'react'
import { bool, func, node } from 'prop-types'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import { useForm } from '@divvy-web/skylab.form'
import Modal from '@divvy-web/skylab.modal'

import { modalStyles } from './styles'

const CreatePasswordModal = ({ children, headerImage, isLoading, isShowing, onLogout, onSubmit, title }) => {
  const [getClassName, makeTestId] = useNameFormatter('CreatePasswordModal')
  const { getFormValue, validationErrors } = useForm()

  const actionButtons = [
    <BasicButton
      key='logout-action'
      dataTestId={makeTestId('logout')}
      type={BASIC_BUTTON_TYPE_OUTLINED}
      onClick={onLogout}
    >
      <FormattedMessage
        defaultMessage='Log Out'
        id='sputnik.CreatePasswordModal__H0JBH6'
      />
    </BasicButton>,
    <BasicButton
      key='submit-action'
      dataTestId={makeTestId('submit')}
      disabled={!!validationErrors || isLoading}
      showSpinner={isLoading}
      onClick={() => onSubmit(getFormValue('password'))}
    >
      <FormattedMessage
        defaultMessage='Submit'
        id='sputnik.CreatePasswordModal__wSZR47'
      />
    </BasicButton>,
  ]

  return (
    <Modal
      actions={actionButtons}
      css={modalStyles}
      dataTestId={makeTestId('')}
      headerColor='accent'
      headerImage={headerImage}
      isShowing={isShowing}
      shouldCloseOnOutsideClick={false}
      title={title}
    >
      <div className={getClassName('')}>{children}</div>
    </Modal>
  )
}

CreatePasswordModal.propTypes = {
  children: node,
  headerImage: node,
  isLoading: bool,
  isShowing: bool,
  onLogout: func,
  onSubmit: func,
  title: node,
}

export default CreatePasswordModal
