import React, { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FetchResult } from '@apollo/client'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import DataField from '@divvy-web/skylab.datafield'
import { useForm } from '@divvy-web/skylab.form'

import PhoneVerificationMobileViewPopsheet from '@sputnik/components/PhoneVerificationMobileViewPopsheet'
import PhoneVerificationModal from '@sputnik/components/PhoneVerificationModal'
import useDeviceDetect from '@sputnik/hooks/useDeviceDetect'
import { RequestPhoneVerification_Mutation } from '@sputnik/pages/gql/mutations/RequestPhoneVerification.gql'
import { PhoneNumberFieldInputContext } from '@sputnik/utils/PhoneNumberFieldInputProvider'

interface RequestSmsCodeModalProps {
  requestPhoneVerification: () => Promise<FetchResult<RequestPhoneVerification_Mutation>>
  isLoading: boolean
}

type Form = {
  phoneNumber: string
  authorizedSignerId: string
}
type UseForm = <T extends object>() => Record<'getFormValue', <K extends keyof T>(x: K) => T[K]>

const RequestSmsCodeModal = ({ requestPhoneVerification, isLoading }: RequestSmsCodeModalProps) => {
  const { isDesktop } = useDeviceDetect()
  const { getFormValue } = (useForm as unknown as UseForm)<Form>()
  const phoneNumberFieldInputContext = useContext(PhoneNumberFieldInputContext)
  const scrollToPhoneNumberField = phoneNumberFieldInputContext?.clearAndScrollToInput
  const [searchParams, setSearchParams] = useSearchParams()
  const [getClassName, makeTestId] = useNameFormatter('RequestSmsCodeModal')
  const isShowing = !!searchParams?.get('phoneVerificationRequired')

  const handleTryAnotherNumberClick = () => {
    searchParams.delete('phoneVerificationRequired')
    searchParams.delete('actionSaveAndExit')
    setSearchParams(Object.fromEntries(searchParams))
    scrollToPhoneNumberField?.()
  }

  const title = (
    <FormattedMessage
      defaultMessage='Verify mobile phone number'
      id='sputnik.RequestSmsCodeModal__C5F7en'
    />
  )

  const actionButtons = [
    <BasicButton
      key='change-number'
      className={getClassName('primary-button')}
      dataTestId={makeTestId('try-another-number-btn')}
      disabled={isLoading}
      type={BASIC_BUTTON_TYPE_OUTLINED}
      onClick={handleTryAnotherNumberClick}
    >
      <FormattedMessage
        defaultMessage='Try another number'
        id='sputnik.RequestSmsCodeModal__AfmrGS'
      />
    </BasicButton>,
    <BasicButton
      key='send-code'
      className={getClassName('secondary-button')}
      dataTestId={makeTestId('send-code-btn')}
      disabled={isLoading}
      showSpinner={isLoading}
      onClick={requestPhoneVerification}
    >
      <FormattedMessage
        defaultMessage='Send code'
        id='sputnik.RequestSmsCodeModal__MTwUmz'
      />
    </BasicButton>,
  ]

  const phoneNumberDataField = (
    <>
      <div
        className={getClassName('info-text fs-unmask')}
        data-testid={makeTestId('info-text')}
      >
        <FormattedMessage
          defaultMessage="We'll send you a verification code to make sure it's really you when you sign in or access sensitive information."
          id='sputnik.RequestSmsCodeModal__D8u91n'
        />
      </div>
      <DataField
        className={getClassName('phone-number-display')}
        dataTestId={makeTestId('phone-number-display')}
        header={
          <FormattedMessage
            defaultMessage='Mobile phone number'
            id='sputnik.RequestSmsCodeModal__L0ijz8'
          />
        }
      >
        {getFormValue('phoneNumber')}
      </DataField>
    </>
  )

  return (
    <>
      {isDesktop && (
        <PhoneVerificationModal
          actionButtons={actionButtons}
          isShowing={isShowing}
          title={title}
        >
          {phoneNumberDataField}
        </PhoneVerificationModal>
      )}
      {!isDesktop && (
        <PhoneVerificationMobileViewPopsheet
          actions={actionButtons}
          isShowing={isShowing}
          title={title}
        >
          {phoneNumberDataField}
        </PhoneVerificationMobileViewPopsheet>
      )}
    </>
  )
}

export default RequestSmsCodeModal
