import React from 'react'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'

import { onContactUsClick } from './contactUsUtils'

interface ContactUsButtonProps {
  className?: string
}

const ContactUsButton = ({ className }: ContactUsButtonProps) => {
  const [getClassName, makeTestId] = useNameFormatter('ContactUsButton')

  return (
    <BasicButton
      className={getClassName(`${className}`)}
      dataTestId={makeTestId('contact-us')}
      type={BASIC_BUTTON_TYPE_OUTLINED}
      onClick={onContactUsClick}
    >
      <FormattedMessage
        defaultMessage='Contact us'
        id='sputnik.ContactUsButton__hZOGLS'
      />
    </BasicButton>
  )
}

export default ContactUsButton
