import React, { useContext, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedCurrency, FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Spinner from '@divvy-web/skylab.spinner'
import TextLink from '@divvy-web/skylab.textlink'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'

import ApplicationAgreements from '@sputnik/components/ApplicationAgreements'
import { ApplicationAgreementsProps } from '@sputnik/components/ApplicationAgreements/ApplicationAgreements'
import CustomerAssistanceButton from '@sputnik/components/CustomerAssistanceButton'
import GoToDashboardButton from '@sputnik/components/GoToDashboardButton'
import ImageWrapper from '@sputnik/components/ImageWrapper'
import OneColorSidebar from '@sputnik/components/OneColorSidebar'
import useDeviceDetect from '@sputnik/hooks/useDeviceDetect'
import { getSideBarImage } from '@sputnik/pages/ApplicationProcessed/applicationProcessedUtils'
import { DecisionContext } from '@sputnik/pages/ApplicationProcessed/DecisionsContext'
import { useGetCreditApplicationTilaInfo } from '@sputnik/pages/gql/GetCreditApplicationTilaInfo.gql'
import { useSelectedDecision } from '@sputnik/pages/gql/mutations/CreditLineOfferSelectedDecision.gql'
import { PATHNAME_CONSTS } from '@sputnik/resources/constants'
import { logError, logInfo } from '@sputnik/utils/loggerUtils'

import { standardCreditLineOfferAcceptanceCss } from './standardCreditLineOfferAcceptanceStyles'

const CROSS_SELL = 'cross_sell'

const StandardCreditLineOfferAcceptance = () => {
  const navigate = useNavigate()
  const { appId } = useParams()
  const { pathname } = useLocation()
  const { isMobile } = useDeviceDetect()
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any
  const [getClassName, makeTestId] = useNameFormatter('StandardCreditLineOfferAcceptance')
  const { standardLineOffer, desiredCredit, isLoggedInUserAuthorizedSigner, authSignerEmail } =
    useContext(DecisionContext)
  const { approvedAmountInCents, underwritingMethod, id: decisionId, offerType } = standardLineOffer || {}
  const { data, loading: isLoadingTilaInfo } = useGetCreditApplicationTilaInfo({
    fetchPolicy: 'network-only',
    variables: {
      creditApplicationId: appId as string,
      underwritingDecisionUuid: decisionId,
      underwritingOfferType: offerType,
      skipTila: !isLoggedInUserAuthorizedSigner,
    },
    // we don't need any of this information if the user is not the authorized signer
    skip: !standardLineOffer || !isLoggedInUserAuthorizedSigner || !appId,
  })
  const [selectedDecision, { loading: isSelectedDecisionLoading }] = useSelectedDecision({
    onCompleted: () => {
      logInfo({
        attributes: {
          action: 'selectedDecision',
          result: `accepts offer for secured credit line and send to polling (/app/${appId}/section/${PATHNAME_CONSTS.ACCEPTING_OFFER})`,
        },
        eventName: 'StandardCreditLineOfferAcceptance',
      })

      navigate({ pathname: `/app/${appId}/section/${PATHNAME_CONSTS.ACCEPTING_OFFER}` })
    },
    onError: (e) => {
      logError({
        attributes: {
          action: 'selectedDecision',
          message: e?.message,
          result: 'Error accepting offer for standard credit line',
        },
        eventName: 'StandardCreditLineOfferAcceptance',
      })

      showDangerToast(
        <FormattedMessage
          defaultMessage='There was an error accepting your offer.'
          id='sputnik.StandardCreditLineOfferAcceptance__oRIVFo'
        />,
        {
          autoHideDelay: 5000,
        },
      )
    },
  })

  const isSingleOffer = !pathname.includes('credit-line-offers')

  const isCrossSell = underwritingMethod === CROSS_SELL
  const isCounterOffer = approvedAmountInCents !== desiredCredit
  const isCrossSellOrCounterOffer = isCounterOffer || isCrossSell

  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)
  const creditApplication = (data?.creditApplication as ApplicationAgreementsProps['creditApplication']) || {}
  const tila: { content?: string; meta?: { signedAt?: string } } = creditApplication?.tila || {}
  const isLoading = isLoadingTilaInfo || isSelectedDecisionLoading
  const hasTilaContent = !!tila?.content
  const hasAgreedToTila = !!tila?.meta?.signedAt
  const disabledWithTila = (!hasAgreedToTerms ||
    isLoading ||
    (hasTilaContent && !hasAgreedToTila) ||
    !creditApplication) as boolean
  const disabledWithoutTila = (!hasAgreedToTerms || isLoading) as boolean
  const disableAcceptOfferButton = hasTilaContent ? disabledWithTila : disabledWithoutTila

  const handleCheckboxClick = () => {
    setHasAgreedToTerms(!hasAgreedToTerms)
  }
  // end for ApplicationAgreements

  const handleAcceptClick = () => {
    selectedDecision({
      variables: {
        id: decisionId,
        salesforceCreditId: appId as string,
      },
    })
  }

  if (isLoadingTilaInfo || !standardLineOffer) {
    return (
      <div css={standardCreditLineOfferAcceptanceCss}>
        <Spinner centered />
      </div>
    )
  }

  return (
    <div css={standardCreditLineOfferAcceptanceCss}>
      <CustomerAssistanceButton />
      <div className='main-container fs-unmask'>
        <div className={getClassName('page-content')}>
          <div className={getClassName('body')}>
            <div className={getClassName('title')}>
              <FormattedMessage
                defaultMessage="{isCounterOffer, select,
                    true {We've got an offer for you!}
                    other {{isCrossSell, select,
                      true {Congrats! Your offer is ready.}
                      other {Your standard credit line}
                    }}
                  }"
                id='sputnik.StandardCreditLineOfferAcceptance__iqLKGL'
                values={{
                  isCounterOffer,
                  isCrossSell,
                }}
              />
            </div>
            {isMobile && (
              <div>
                <ImageWrapper
                  alt='person scanning mobile device'
                  imageName={isCounterOffer ? 'counter-offer-mobile' : 'credit-line-offer-mobile'}
                />
              </div>
            )}
            <div
              className={getClassName('approved-amount')}
              data-testid={makeTestId('approved-amount')}
            >
              <FormattedCurrency
                maximumFractionDigits={0}
                minimumFractionDigits={2}
                value={approvedAmountInCents}
              />
            </div>
            <div className={getClassName('how-it-works')}>
              <FormattedMessage
                defaultMessage='How it works:'
                id='sputnik.StandardCreditLineOfferAcceptance__UZgb4A'
              />
            </div>
            <div className={getClassName('how-it-works-list')}>
              <ul>
                <li>
                  <FormattedMessage
                    defaultMessage="Your credit limit is fixed and typically won't change."
                    id='sputnik.StandardCreditLineOfferAcceptance__kT0CVZ'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='You can request credit limit increases right from your dashboard.'
                    id='sputnik.StandardCreditLineOfferAcceptance__BwrSOE'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='Your balance is due in full at the end of each billing cycle.'
                    id='sputnik.StandardCreditLineOfferAcceptance__8LBAFn'
                  />
                </li>
              </ul>
            </div>
            {isCrossSellOrCounterOffer && (
              <div className={getClassName('standard-offer-variation-caption')}>
                <p>
                  <FormattedMessage
                    defaultMessage={`
                      {isCrossSell, select,
                        true {Based on the information you've provided, we have confirmed your pre-approval offer. If you accept this offer, we'll do some final verification and set up your account.}
                        other {{isCounterOffer, select,
                          true {With the information provided in the application we were unable to approve you for the requested amount of {desiredCredit}.}
                          other {}
                        }}
                      }
                    `}
                    id='sputnik.StandardCreditLineOfferAcceptance__OEv4nc'
                    values={{
                      isCrossSell,
                      isCounterOffer,
                      desiredCredit: (
                        <FormattedCurrency
                          maximumFractionDigits={0}
                          minimumFractionDigits={2}
                          value={desiredCredit}
                        />
                      ),
                    }}
                  />
                </p>
              </div>
            )}
            <p className={getClassName('learn-more-wrapper')}>
              <FormattedMessage
                defaultMessage='Still need more info? <textlink>Learn more</textlink>'
                id='sputnik.StandardCreditLineOfferAcceptance__VjFJsV'
                values={{
                  textlink: (chunks) => (
                    <TextLink
                      className={getClassName('learn-more')}
                      href='https://help.bill.com/direct/s/article/000003577'
                      target='_blank'
                    >
                      {chunks}
                    </TextLink>
                  ),
                }}
              />
            </p>
            {isLoggedInUserAuthorizedSigner && offerType && (
              <ApplicationAgreements
                creditApplication={creditApplication}
                decisionId={decisionId}
                hasAgreedToOfferSummary={hasAgreedToTila}
                hasAgreedToTerms={hasAgreedToTerms}
                offerType={offerType}
                onCheckboxClick={handleCheckboxClick}
              />
            )}
            {!isLoggedInUserAuthorizedSigner && (
              <div className={getClassName('unauthorized-accept-message')}>
                <FormattedMessage
                  defaultMessage='Only the authorized signer ({email}) can accept this offer.'
                  id='sputnik.StandardCreditLineOfferAcceptance__TdYnmo'
                  values={{
                    email: authSignerEmail,
                  }}
                />
              </div>
            )}
            <section className={getClassName('buttons')}>
              {!isSingleOffer && (
                <BasicButton
                  className={getClassName('previous')}
                  dataTestId={makeTestId('previous')}
                  type={BASIC_BUTTON_TYPE_FLAT}
                  onClick={() => navigate(-1)}
                >
                  <FormattedMessage
                    defaultMessage='Previous'
                    id='sputnik.StandardCreditLineOfferAcceptance__JJNc3c'
                  />
                </BasicButton>
              )}
              {isSingleOffer && (
                <GoToDashboardButton
                  buttonType={BASIC_BUTTON_TYPE_FLAT}
                  componentName={makeTestId('')}
                />
              )}
              {isLoggedInUserAuthorizedSigner && (
                <BasicButton
                  className={getClassName('accept')}
                  dataTestId={makeTestId('accept')}
                  disabled={disableAcceptOfferButton}
                  showSpinner={isSelectedDecisionLoading}
                  onClick={handleAcceptClick}
                >
                  <FormattedMessage
                    defaultMessage='Accept offer'
                    id='sputnik.StandardCreditLineOfferAcceptance__/xro5W'
                  />
                </BasicButton>
              )}
            </section>
          </div>
        </div>
      </div>
      {!isMobile && <OneColorSidebar srcImage={getSideBarImage(isCounterOffer)} />}
    </div>
  )
}

export default StandardCreditLineOfferAcceptance
