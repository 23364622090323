import React, { ReactNode } from 'react'
import { css } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import Tile from '@divvy-web/skylab.tile'

interface TipCardProps {
  title: ReactNode
  bodyText: ReactNode
}

const TipCard = ({ title, bodyText }: TipCardProps) => {
  const [makeTestId, getClassName] = useNameFormatter('TipCard')

  return (
    <Tile
      css={tipCardCss}
      dataTestId={makeTestId('')}
    >
      <div className='fs-unmask'>
        <div
          className={getClassName('title')}
          data-testid={makeTestId('title')}
        >
          {title}
        </div>
        <div
          className={getClassName('bodyText')}
          data-testid={makeTestId('bodyText')}
        >
          {bodyText}
        </div>
      </div>
    </Tile>
  )
}

const tipCardCss = ({ type }) => css`
  min-width: 272px;
  max-width: 640px;

  .TipCard-title {
    ${type.triFontDesktopLabelMediumEmphasis}
    padding-bottom: var(--tri-space-200);
  }

  .TipCard-bodyText {
    ${type.triFontDesktopCaptionLarge}
  }
`

export default TipCard
