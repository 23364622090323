/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/
import type * as _Types from '_gql'
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GetDsaAccessToken_dsaAccessToken_DsaAccessToken = {
  readonly __typename: 'DsaAccessToken'
  readonly dsaToken: string | null
}

export type GetDsaAccessToken_Query = {
  readonly __typename: 'Query'
  readonly dsaAccessToken: GetDsaAccessToken_dsaAccessToken_DsaAccessToken | null
}

export type GetDsaAccessTokenVariables = _Types.Exact<{
  creditApplicationId: _Types.Scalars['ID']['input']
}>

export type GetDsaAccessToken = GetDsaAccessToken_Query

export const GetDsaAccessTokenDocument = gql`
  query GetDsaAccessToken($creditApplicationId: ID!) {
    dsaAccessToken(creditApplicationId: $creditApplicationId) {
      dsaToken
    }
  }
`

/**
 * __useGetDsaAccessToken__
 *
 * To run a query within a React component, call `useGetDsaAccessToken` and pass it any options that fit your needs.
 * When your component renders, `useGetDsaAccessToken` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDsaAccessToken({
 *   variables: {
 *      creditApplicationId: // value for 'creditApplicationId'
 *   },
 * });
 */
export function useGetDsaAccessToken(
  baseOptions: Apollo.QueryHookOptions<GetDsaAccessToken, GetDsaAccessTokenVariables> &
    ({ variables: GetDsaAccessTokenVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDsaAccessToken, GetDsaAccessTokenVariables>(GetDsaAccessTokenDocument, options)
}
export function useGetDsaAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDsaAccessToken, GetDsaAccessTokenVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDsaAccessToken, GetDsaAccessTokenVariables>(GetDsaAccessTokenDocument, options)
}
export function useGetDsaAccessTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetDsaAccessToken, GetDsaAccessTokenVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetDsaAccessToken, GetDsaAccessTokenVariables>(GetDsaAccessTokenDocument, options)
}
export type GetDsaAccessTokenHookResult = ReturnType<typeof useGetDsaAccessToken>
export type GetDsaAccessTokenLazyQueryHookResult = ReturnType<typeof useGetDsaAccessTokenLazyQuery>
export type GetDsaAccessTokenSuspenseQueryHookResult = ReturnType<typeof useGetDsaAccessTokenSuspenseQuery>
export type GetDsaAccessTokenQueryResult = Apollo.QueryResult<GetDsaAccessToken, GetDsaAccessTokenVariables>
