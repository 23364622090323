import React, { useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { FetchResult } from '@apollo/client'

import { FormattedMessage } from '@divvy-web/i18n'
import { TOAST_TYPE_DANGER, TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'

import AccessCodeForm from '@sputnik/components/AccessCodeForm'
import SmsAccessCodeModalContent from '@sputnik/components/SmsAccessCodeModalContent'
import { RequestPhoneVerification_Mutation } from '@sputnik/pages/gql/mutations/RequestPhoneVerification.gql'
import { useSubmitPhoneVerificationCode } from '@sputnik/pages/gql/mutations/SubmitPhoneVerificationCode.gql'
import { logError, logInfo } from '@sputnik/utils/loggerUtils'

interface SmsAccessCodeFormProps {
  isLoadingResendCode: boolean
  submitPageMutation?: () => void
  requestPhoneVerification: () => Promise<FetchResult<RequestPhoneVerification_Mutation>>
}

const SmsAccessCodeForm = ({
  submitPageMutation,
  requestPhoneVerification,
  isLoadingResendCode,
}: SmsAccessCodeFormProps) => {
  const [hasError, setHasError] = useState(false)
  const { appId } = useParams()
  const { state } = useLocation()
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any
  const showSuccessToast = useToast(TOAST_TYPE_SUCCESS) as any
  const [searchParams, setSearchParams] = useSearchParams()
  const phoneNumber = state?.phoneNumber
  const phoneLastFour = phoneNumber?.slice(-4)
  const authorizedSignerId = state?.authorizedSignerId

  const [submitPhoneVerificationCode, { loading }] = useSubmitPhoneVerificationCode({
    onCompleted: () => {
      logInfo({
        attributes: {
          action: 'handleSubmitSmsCode',
          result: 'Send code button has been clicked',
        },
        eventName: 'SubmitPhoneVerificationCode',
      })

      searchParams.delete('verifySmsCodePending')
      setSearchParams(Object.fromEntries(searchParams))
      showSuccessToast(
        <FormattedMessage
          defaultMessage='Phone number has been verified'
          id='sputnik.SmsAccessCodeForm__phoneVerified'
        />,
      )
      submitPageMutation?.()
    },
    onError: (e) => {
      logError({
        attributes: {
          action: 'handleSubmitSmsCode',
          message: e?.message,
          result: 'Error submitting phone verification code',
        },
        eventName: 'SubmitPhoneVerificationCode',
      })
      setHasError(true)
    },
  })

  const handleResendClick = () => {
    requestPhoneVerification()
      .then(() => {
        logInfo({
          attributes: {
            action: 'handleResendClick',
            result: 'Resend auth code clicked',
            mfaMethod: 'sms',
          },
          eventName: 'ResendPhoneVerificationCode',
        })
        showSuccessToast(
          <FormattedMessage
            defaultMessage='Code has been resent'
            id='sputnik.SmsAccessCodeForm__codeResent'
          />,
        )
      })
      .catch(() => {
        logError({
          attributes: {
            action: 'handleResendClick',
            result: 'Error resending code',
            mfaMethod: 'sms',
          },
          eventName: 'ResendPhoneVerificationCode',
        })
        showDangerToast(
          <FormattedMessage
            defaultMessage='Error resending code'
            id='sputnik.SmsAccessCodeForm__errorResendingCode'
          />,
        )
      })
  }

  const handleSubmitAccessCode = (accessCode: string) => {
    logInfo({
      attributes: {
        action: 'handleSubmitAccessCode',
        result: 'Verify code button clicked',
        mfaMethod: 'sms',
      },
      eventName: 'SubmitPhoneVerificationCode',
    })

    submitPhoneVerificationCode({
      variables: {
        salesforceCreditId: appId || '',
        phoneNumber,
        personId: authorizedSignerId,
        accessCode,
      },
    })
  }

  const processDescirption = (
    <FormattedMessage
      defaultMessage='We sent a code to your phone number ending with {phoneLastFour} to make sure it’s you.'
      id='sputnik.SmsAccessCodeForm__yg/OcB'
      values={{ phoneLastFour }}
    />
  )

  return (
    <>
      <AccessCodeForm
        disableError={() => setHasError(false)}
        handleSubmitAccessCode={handleSubmitAccessCode}
      >
        <SmsAccessCodeModalContent
          handleResendClick={handleResendClick}
          handleSubmitAccessCode={handleSubmitAccessCode}
          hasError={hasError}
          loadingResendCode={isLoadingResendCode}
          loadingSubmitCode={loading}
          processDescription={processDescirption}
        />
      </AccessCodeForm>
    </>
  )
}

export default SmsAccessCodeForm
