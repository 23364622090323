import React, { useContext, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedCurrency, FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Spinner from '@divvy-web/skylab.spinner'
import TextLink from '@divvy-web/skylab.textlink'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'

import ApplicationAgreements from '@sputnik/components/ApplicationAgreements'
import { ApplicationAgreementsProps } from '@sputnik/components/ApplicationAgreements/ApplicationAgreements'
import CustomerAssistanceButton from '@sputnik/components/CustomerAssistanceButton'
import GoToDashboardButton from '@sputnik/components/GoToDashboardButton'
import ImageWrapper from '@sputnik/components/ImageWrapper'
import OneColorSidebar from '@sputnik/components/OneColorSidebar'
import useDeviceDetect from '@sputnik/hooks/useDeviceDetect'
import { getSideBarImage } from '@sputnik/pages/ApplicationProcessed/applicationProcessedUtils'
import { DecisionContext } from '@sputnik/pages/ApplicationProcessed/DecisionsContext'
import { useGetCreditApplicationTilaInfo } from '@sputnik/pages/gql/GetCreditApplicationTilaInfo.gql'
import { useSelectedDecision } from '@sputnik/pages/gql/mutations/CreditLineOfferSelectedDecision.gql'
import { PATHNAME_CONSTS } from '@sputnik/resources/constants'
import { logError, logInfo } from '@sputnik/utils/loggerUtils'

import { variableCreditLineOfferAcceptanceCss } from './variableCreditLineOfferAcceptanceStyles'

const VariableCreditLineOfferAcceptance = () => {
  const navigate = useNavigate()
  const { appId } = useParams()
  const { pathname } = useLocation()
  const { isMobile } = useDeviceDetect()
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any
  const [getClassName, makeTestId] = useNameFormatter('VariableCreditLineOfferAcceptance')
  const { variableLineOffer, desiredCredit, isLoggedInUserAuthorizedSigner, authSignerEmail } =
    useContext(DecisionContext)
  const { approvedAmountInCents, id: decisionId, offerType } = variableLineOffer || {}
  const { data, loading: isLoadingTilaInfo } = useGetCreditApplicationTilaInfo({
    fetchPolicy: 'network-only',
    variables: {
      creditApplicationId: appId as string,
      underwritingDecisionUuid: decisionId,
      underwritingOfferType: offerType,
      skipTila: !isLoggedInUserAuthorizedSigner,
    },
    // we don't need any of this information if the user is not the authorized signer
    skip: !variableLineOffer || !isLoggedInUserAuthorizedSigner || !appId,
  })

  const [selectedDecision, { loading: isSelectedDecisionLoading }] = useSelectedDecision({
    onCompleted: () => {
      logInfo({
        attributes: {
          action: 'selectedDecision',
          result: `accepts offer for secured credit line and send to polling (/app/${appId}/section/${PATHNAME_CONSTS.ACCEPTING_OFFER})`,
        },
        eventName: 'VariableCreditLineOfferAcceptance',
      })

      navigate(`/app/${appId}/section/${PATHNAME_CONSTS.ACCEPTING_OFFER}`)
    },
    onError: (e) => {
      logError({
        attributes: {
          action: 'selectedDecision',
          message: e?.message,
          result: 'Error accepting offer for variable credit line',
        },
        eventName: 'VariableCreditLineOfferAcceptance',
      })

      showDangerToast(
        <FormattedMessage
          defaultMessage='There was an error accepting your offer.'
          id='sputnik.VariableCreditLineOfferAcceptance__oRIVFo'
        />,
        {
          autoHideDelay: 5000,
        },
      )
    },
  })

  const isSingleOffer = !pathname.includes('credit-line-offers')
  const isCounterOffer = approvedAmountInCents !== desiredCredit
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)
  const creditApplication = (data?.creditApplication as ApplicationAgreementsProps['creditApplication']) || {}
  const tila: { content?: string; meta?: { signedAt?: string } } = creditApplication?.tila || {}
  const isLoading = isLoadingTilaInfo || isSelectedDecisionLoading
  const hasTilaContent = !!tila?.content
  const hasAgreedToTila = !!tila?.meta?.signedAt
  const disabledWithTila = (!hasAgreedToTerms ||
    isLoading ||
    (hasTilaContent && !hasAgreedToTila) ||
    !creditApplication) as boolean
  const disabledWithoutTila = (!hasAgreedToTerms || isLoading) as boolean
  const disableAcceptOfferButton = hasTilaContent ? disabledWithTila : disabledWithoutTila

  const handleCheckboxClick = () => {
    setHasAgreedToTerms(!hasAgreedToTerms)
  }

  const handleAcceptClick = () => {
    selectedDecision({
      variables: {
        id: decisionId,
        salesforceCreditId: appId as string,
      },
    })
  }

  if (isLoadingTilaInfo || !variableLineOffer) {
    return (
      <div css={variableCreditLineOfferAcceptanceCss}>
        <Spinner centered />
      </div>
    )
  }

  return (
    <div css={variableCreditLineOfferAcceptanceCss}>
      <CustomerAssistanceButton />
      <div className='main-container fs-unmask'>
        <div className={getClassName('page-content')}>
          <div
            className={getClassName('body')}
            data-testid={makeTestId('')}
          >
            <div className={getClassName('title')}>
              <FormattedMessage
                defaultMessage="{isCounterOffer, select,
                    true {We've got an offer for you!}
                    other {Your variable credit line}
                  }"
                id='sputnik.VariableCreditLineOfferAcceptance__LK+wg9'
                values={{
                  isCounterOffer,
                }}
              />
            </div>
            {isMobile && (
              <div>
                <ImageWrapper
                  alt='person scanning mobile device'
                  imageName={isCounterOffer ? 'counter-offer-mobile' : 'credit-line-offer-mobile'}
                />
              </div>
            )}
            <div
              className={getClassName('approved-amount')}
              data-testid={makeTestId('approved-amount')}
            >
              <FormattedCurrency
                maximumFractionDigits={0}
                minimumFractionDigits={2}
                value={approvedAmountInCents}
              />
            </div>
            <div className={getClassName('how-it-works')}>
              <FormattedMessage
                defaultMessage='How it works:'
                id='sputnik.VariableCreditLineOfferAcceptance__ON3IdR'
              />
            </div>
            <div className={getClassName('how-it-works-list')}>
              <ul>
                <li>
                  <FormattedMessage
                    defaultMessage='Your credit limit may fluctuate based on the cash balance in your connected bank accounts.'
                    id='sputnik.VariableCreditLineOfferAcceptance__fxcTnF'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage="We'll use a secure digital banking connection to review your account balances and determine any changes to your credit limit."
                    id='sputnik.VariableCreditLineOfferAcceptance__Ja5wwz'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='Your balance is due in full at the end of each billing cycle.'
                    id='sputnik.VariableCreditLineOfferAcceptance__8LBAFn'
                  />
                </li>
              </ul>
            </div>
            {isCounterOffer && (
              <div className={getClassName('variable-offer-variation-caption')}>
                <p>
                  <FormattedMessage
                    defaultMessage='With the information provided in the application we were unable to approve you for the requested amount of {desiredCredit}.'
                    id='sputnik.VariableCreditLineOfferAcceptance__BSHMT4'
                    values={{
                      desiredCredit: (
                        <FormattedCurrency
                          maximumFractionDigits={0}
                          minimumFractionDigits={2}
                          value={desiredCredit}
                        />
                      ),
                    }}
                  />
                </p>
              </div>
            )}
            <p className={getClassName('learn-more-wrapper')}>
              <FormattedMessage
                defaultMessage='Still need more info? <textlink>Learn more</textlink>'
                id='sputnik.VariableCreditLineOfferAcceptance__361pgg'
                values={{
                  textlink: (chunks) => (
                    <TextLink
                      className={getClassName('learn-more')}
                      href='https://help.bill.com/direct/s/article/6702952'
                      target='_blank'
                    >
                      {chunks}
                    </TextLink>
                  ),
                }}
              />
            </p>
            {isLoggedInUserAuthorizedSigner && offerType && (
              <ApplicationAgreements
                creditApplication={creditApplication}
                decisionId={decisionId}
                // TODO: change this variable name to hasAgreedToTila down the chain
                hasAgreedToOfferSummary={hasAgreedToTila}
                hasAgreedToTerms={hasAgreedToTerms}
                offerType={offerType}
                onCheckboxClick={handleCheckboxClick}
              />
            )}
            {!isLoggedInUserAuthorizedSigner && (
              <div className={getClassName('unauthorized-accept-message')}>
                <FormattedMessage
                  defaultMessage='Only the authorized signer ({email}) can accept this offer.'
                  id='sputnik.VariableCreditLineOfferAcceptance__TdYnmo'
                  values={{
                    email: authSignerEmail,
                  }}
                />
              </div>
            )}
            <section className={getClassName('buttons')}>
              {!isSingleOffer && (
                <BasicButton
                  className={getClassName('previous')}
                  dataTestId={makeTestId('previous')}
                  type={BASIC_BUTTON_TYPE_FLAT}
                  onClick={() => navigate(-1)}
                >
                  <FormattedMessage
                    defaultMessage='Previous'
                    id='sputnik.VariableCreditLineOfferAcceptance__JJNc3c'
                  />
                </BasicButton>
              )}
              {isSingleOffer && (
                <GoToDashboardButton
                  buttonType={BASIC_BUTTON_TYPE_FLAT}
                  componentName={makeTestId('')}
                />
              )}
              {isLoggedInUserAuthorizedSigner && (
                <BasicButton
                  className={getClassName('accept')}
                  dataTestId={makeTestId('accept')}
                  disabled={disableAcceptOfferButton}
                  showSpinner={isSelectedDecisionLoading}
                  onClick={handleAcceptClick}
                >
                  <FormattedMessage
                    defaultMessage='Accept offer'
                    id='sputnik.VariableCreditLineOfferAcceptance__/xro5W'
                  />
                </BasicButton>
              )}
            </section>
          </div>
        </div>
      </div>
      {!isMobile && <OneColorSidebar srcImage={getSideBarImage(isCounterOffer)} />}
    </div>
  )
}

export default VariableCreditLineOfferAcceptance
