import { css } from '@emotion/core'

export const securedCreditLineOfferAcceptanceCss = ({ mq, type }) => css`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;

  .main-container {
    flex: 2;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
  }

  .GoToDashboardButton {
    ${mq.xSmallMaxWidth(css`
      background-color: var(--tri-color-fill-primary);
    `)}
  }

  .GoToDashboardButton {
    ${mq.xSmallMaxWidth(css`
      border-radius: 0;
      height: 100%;
      width: 100%;
    `)}
  }

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-2000);
  }

  .SecuredCreditLineOfferAcceptance {
    &-unauthorized-accept-message {
      ${type.triFontDesktopBodyMediumItalic}
      color: var(--tri-color-text-disabled);
      margin: var(--tri-space-250) 0 var(--tri-space-400);
    }

    &-page-content {
      padding: 0 var(--tri-space-2300) 0 var(--tri-space-1800);
      display: flex;
      flex-direction: column;
      height: 100vh;
      ${mq.xSmallMaxWidth(css`
        padding: unset;
        height: auto;
      `)}
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      ${mq.xSmallMaxWidth(css`
        display: flex;
        padding: 0 var(--tri-space-350) var(--tri-space-1250);
        overflow: auto;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      `)}
    }

    &-title {
      ${type.triFontDesktopTitleLargeEmphasis};
      display: flex;
      ${mq.xSmallMaxWidth(type.triFontDesktopTitleSmallEmphasis)}
      ${mq.xSmallMaxWidth(css`
        padding: var(--tri-space-700) 0 var(--tri-space-100);
      `)}
    }

    &-subtitle {
      ${type.triFontDesktopHeadingSmall};
      padding: 0 0 var(--tri-space-700);
      color: var(--tri-color-text-secondary);
      ${mq.xSmallMaxWidth(type.triFontDesktopLabelSmall)}
      ${mq.xSmallMaxWidth(css`
        padding: 0;
      `)}
    }

    &-how-it-works {
      ${type.triFontDesktopTitleSmallEmphasis};
      padding-bottom: var(--tri-space-300);
      ${mq.xSmallMaxWidth(type.triFontDesktopBodyMediumEmphasis)}
      ${mq.xSmallMaxWidth(css`
        padding-bottom: var(--tri-space-200);
        align-self: flex-start;
      `)}
    }

    &-how-it-works-list {
      padding: 0 0 var(--tri-space-400) var(--tri-space-250);
      ${mq.xSmallMaxWidth(css`
        align-self: flex-start;
      `)}
    }

    &-learn-more-wrapper {
      padding-bottom: var(--tri-space-200);
      ${mq.xSmallMaxWidth(css`
        align-self: flex-start;
      `)}
    }

    &-review-disclosure {
      padding-bottom: var(--tri-space-700);
      ${mq.xSmallMaxWidth(css`
        padding-bottom: var(--tri-space-1100);
      `)};
      ${mq.mediumMinWidth(css`
        width: min(80%, 550px);
      `)};
    }

    &-buttons {
      display: flex;
      gap: var(--tri-space-300);
      ${mq.xSmallMaxWidth(css`
        bottom: 0;
        gap: 0;
        height: 88px;
        left: 0;
        position: fixed;
        width: 100%;
      `)}
    }

    &-previous {
      ${mq.xSmallMaxWidth(css`
        background-color: var(--tri-color-fill-primary);
      `)}
    }

    &-accept,
    &-previous {
      ${mq.xSmallMaxWidth(css`
        border-radius: 0;
        height: 100%;
        width: 100%;
      `)}
    }
  }

  .one-color-sidebar {
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .sidebar-image {
    height: auto;
    width: 100%;
    max-width: 550px;
    margin-bottom: var(--tri-space-700);
    align-self: flex-end;
    transform: scaleX(-1) translateX(-7%);
  }
`
