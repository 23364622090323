import React from 'react'
import { useMutation } from '@apollo/client'
import { bool, func } from 'prop-types'

import { FormattedMessage } from '@divvy-web/i18n'
import Form from '@divvy-web/skylab.form'
import Image from '@divvy-web/skylab.image'
import { TOAST_TYPE_DANGER, TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'

import { getEmailFromStorage } from '@sputnik/auth'
import TriggerPasswordResetEmail from '@sputnik/auth/gql/TriggerPasswordResetEmail.gql'
import { logError, logInfo } from '@sputnik/utils/loggerUtils'

import ResetPasswordContent from './ResetPasswordContent'
import ResetPasswordModal from './ResetPasswordModal'
import ResetPasswordPopsheet from './ResetPasswordPopsheet'
import userEmailValidations from './userEmailValidations'

const ResetPasswordContainer = ({ isShowing = false, onClose }) => {
  const initialValues = { userEmail: getEmailFromStorage() || '' }

  const showToast = useToast()

  const handleSuccess = (result, opts) => {
    if (result?.triggerPasswordResetEmail?.success) {
      logInfo({
        attributes: {
          action: 'sentPasswordResetEmail',
          result: `Sent password reset email to ${opts?.variables?.email}`,
        },
        eventName: 'TriggerPasswordResetEmail',
      })
      showToast(
        TOAST_TYPE_SUCCESS,
        <FormattedMessage
          defaultMessage='A password reset email has been sent to {email}.'
          id='sputnik.ResetPasswordContainer__5fLwGj'
          values={{ email: opts?.variables?.email }}
        ></FormattedMessage>,
        { autoHideDelay: 3000 },
      )
      onClose()
    } else {
      handleFailure(result?.triggerPasswordResetEmail?.error, opts)
    }
  }
  const handleFailure = (error, opts) => {
    const errorMessage = error?.message || error || 'Error requesting password reset'
    logError({
      attributes: {
        action: 'sentPasswordResetEmail',
        message: errorMessage,
        result: `Error. Failed to send password reset email to ${opts?.variables?.email}`,
      },
      eventName: 'TriggerPasswordResetEmail',
    })
    showToast(
      TOAST_TYPE_DANGER,
      <FormattedMessage
        defaultMessage='Failed: {error}.'
        id='sputnik.ResetPasswordContainer__ABx976'
        values={{ error: errorMessage }}
      ></FormattedMessage>,
      { autoHideDelay: 3000 },
    )
  }

  const [triggerPasswordResetEmail, { loading }] = useMutation(TriggerPasswordResetEmail, {
    onCompleted: (result, opts) => handleSuccess(result, opts),
  })

  const onSubmit = (email) =>
    triggerPasswordResetEmail({
      variables: { email },
      onError: (error) => handleFailure(error, { variables: { email } }),
    })

  const title = (
    <FormattedMessage
      defaultMessage='Trouble logging in?'
      id='sputnik.ResetPasswordContainer__pk8FH8'
    />
  )

  const headerImage = (
    <Image
      alt='arrows-circle'
      assetName='signup-forgot-password-header'
      className='reset-password-image'
    />
  )

  return (
    <div>
      <Form
        initialValues={initialValues}
        validation={userEmailValidations}
      >
        <ResetPasswordModal
          headerImage={headerImage}
          isLoading={loading}
          isShowing={isShowing}
          title={title}
          onClose={onClose}
          onSubmit={onSubmit}
        >
          <ResetPasswordContent />
        </ResetPasswordModal>
        <ResetPasswordPopsheet
          headerImage={headerImage}
          isLoading={loading}
          isShowing={isShowing}
          subtitle={title}
          onClose={onClose}
          onSubmit={onSubmit}
        >
          <ResetPasswordContent isMobile />
        </ResetPasswordPopsheet>
      </Form>
    </div>
  )
}

ResetPasswordContainer.propTypes = { isShowing: bool, onClose: func }

export default ResetPasswordContainer
