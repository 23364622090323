import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import AppProvider from '@sputnik/AppProvider'
import BdcLanding from '@sputnik/pages/BdcLanding'
import NotFound from '@sputnik/pages/NotFound'

import { createAuthenticatedRoutes, createAuthenticationRoutes, createSignInRoutes } from './legacy'

interface RoutesProps {
  showCreditAppRedesign: boolean
}

export const createApplicationRoutes = (_: RoutesProps) => {
  return createBrowserRouter([
    {
      element: <AppProvider />,
      children: [
        ...createSignInRoutes(),
        ...createAuthenticationRoutes(),
        ...createAuthenticatedRoutes(),
        {
          path: '/bdc-landing',
          element: <BdcLanding />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ])
}
