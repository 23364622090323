/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/
import type * as _Types from '_gql'
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type SubmitPhoneVerificationCode_submitPhoneVerificationCode_CreditApplicationPerson = {
  readonly __typename: 'CreditApplicationPerson'
  readonly id: string | null
  readonly cacheRole: string | null
  readonly verifiedPhoneNumber: string | null
}

export type SubmitPhoneVerificationCode_Mutation = {
  readonly __typename: 'Mutation'
  readonly submitPhoneVerificationCode: SubmitPhoneVerificationCode_submitPhoneVerificationCode_CreditApplicationPerson | null
}

export type SubmitPhoneVerificationCodeVariables = _Types.Exact<{
  salesforceCreditId: _Types.Scalars['ID']['input']
  phoneNumber: _Types.Scalars['String']['input']
  personId: _Types.Scalars['ID']['input']
  accessCode: _Types.Scalars['String']['input']
}>

export type SubmitPhoneVerificationCode = SubmitPhoneVerificationCode_Mutation

export const SubmitPhoneVerificationCodeDocument = gql`
  mutation SubmitPhoneVerificationCode(
    $salesforceCreditId: ID!
    $phoneNumber: String!
    $personId: ID!
    $accessCode: String!
  ) {
    submitPhoneVerificationCode(
      salesforceCreditId: $salesforceCreditId
      phoneNumber: $phoneNumber
      personId: $personId
      accessCode: $accessCode
    ) {
      id
      cacheRole
      verifiedPhoneNumber
    }
  }
`
export type SubmitPhoneVerificationCodeMutationFn = Apollo.MutationFunction<
  SubmitPhoneVerificationCode,
  SubmitPhoneVerificationCodeVariables
>

/**
 * __useSubmitPhoneVerificationCode__
 *
 * To run a mutation, you first call `useSubmitPhoneVerificationCode` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPhoneVerificationCode` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPhoneVerificationCode, { data, loading, error }] = useSubmitPhoneVerificationCode({
 *   variables: {
 *      salesforceCreditId: // value for 'salesforceCreditId'
 *      phoneNumber: // value for 'phoneNumber'
 *      personId: // value for 'personId'
 *      accessCode: // value for 'accessCode'
 *   },
 * });
 */
export function useSubmitPhoneVerificationCode(
  baseOptions?: Apollo.MutationHookOptions<SubmitPhoneVerificationCode, SubmitPhoneVerificationCodeVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitPhoneVerificationCode, SubmitPhoneVerificationCodeVariables>(
    SubmitPhoneVerificationCodeDocument,
    options,
  )
}
export type SubmitPhoneVerificationCodeHookResult = ReturnType<typeof useSubmitPhoneVerificationCode>
export type SubmitPhoneVerificationCodeMutationResult = Apollo.MutationResult<SubmitPhoneVerificationCode>
export type SubmitPhoneVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  SubmitPhoneVerificationCode,
  SubmitPhoneVerificationCodeVariables
>
