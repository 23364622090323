import React from 'react'
import { css } from '@emotion/core'
import { array, func, number } from 'prop-types'

import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import useOnClickOutside from '@divvy-web/hooks.useonclickoutside'
import ListItem from '@divvy-web/skylab.listitem'

const AddressSuggestions = ({ selectedAddressIdx, addressSuggestions, onItemClick, onOutsideClick }) => {
  const refOutsideClick = useOnClickOutside(onOutsideClick)
  const makeTestId = useMakeTestId('AddressSuggestions')

  if (addressSuggestions?.length === 0) return null

  return (
    <div
      ref={refOutsideClick}
      className='fs-mask'
      css={addressSuggestionCss}
      data-testid={makeTestId('')}
    >
      {addressSuggestions.map((address, idx) => {
        const {
          place_id: placeId,
          structured_formatting: { main_text: label, secondary_text: caption },
        } = address

        return (
          <ListItem
            key={placeId}
            caption={caption}
            isHighlighted={selectedAddressIdx === idx}
            label={label}
            value={placeId}
            onClick={() => onItemClick(placeId)}
          />
        )
      })}
    </div>
  )
}

AddressSuggestions.propTypes = {
  addressSuggestions: array,
  onItemClick: func,
  onOutsideClick: func,
  selectedAddressIdx: number,
}

const addressSuggestionCss = ({ colors }) => css`
  position: absolute;
  background: var(--tri-color-fill-primary);
  border: 1px solid;
  border-color: var(--tri-color-text-secondary-inverse);
  max-height: 336px;
  overflow: auto;
  width: 100%;
  z-index: 6;
`

export default AddressSuggestions
