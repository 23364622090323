import axios from 'axios'

import { logInfo } from './loggerUtils'

const getPodNumberFromOrgId = (orgId) => {
  if (!orgId) return
  return orgId?.substring(3, 5)
}

const getBillBaseUrl = (bdcPodId) =>
  ({
    '01': window.__DivvyEnvironment.BILL_POD1_URL,
    '02': window.__DivvyEnvironment.BILL_POD2_URL,
  }[bdcPodId])

export const getBillUrl = (bdcOrgId) => {
  const podId = getPodNumberFromOrgId(bdcOrgId)
  return getBillBaseUrl(podId)
}

export const getNeoUrl = (bdcOrgId) => {
  const podId = getPodNumberFromOrgId(bdcOrgId)
  const billUrl = getBillBaseUrl(podId)
  return billUrl ? `${billUrl}/neo` : ''
}

export const getTankUrl = (bdcOrgId) => {
  const podId = getPodNumberFromOrgId(bdcOrgId)
  const billUrl = getBillBaseUrl(podId)
  return billUrl ? `${billUrl}/query` : ''
}

export const logoutBill = (bdcOrgId) => {
  const tankUrl = getTankUrl(bdcOrgId)
  if (!tankUrl) return
  return axios.post(tankUrl, '{"query":"mutation Mutation { logout }","variables":{},"operationName":"Mutation"}', {
    headers: {
      'content-type': 'application/json',
    },
    withCredentials: true,
  })
}

export const navigateToBillLogin = (bdcOrgId) => {
  const neoUrl = getNeoUrl(bdcOrgId)
  logInfo({
    attributes: {
      action: 'navigateToBillLogin',
      result: `Navigates user to ${neoUrl}/login`,
    },
    eventName: 'BillLogin',
  })
  window.location.href = `${neoUrl}/login`
}
