import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { css } from '@emotion/core'
import { bool, func } from 'prop-types'

import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import Modal from '@divvy-web/skylab.modal'

import InviteCollaborators from '@sputnik/pages/Dashboard/InviteCollaborators'

const modalIconPath = 'https://app.divvy.co/assets/illustrations/customer-success'
const mainModalIconSrc = `${modalIconPath}@1x.png`
const modalIconSrcSet = `${modalIconPath}@3x.png, ${modalIconPath}@2x.png, ${modalIconPath}@1x.png`
const headerImage = (
  <img
    alt='post-it'
    src={mainModalIconSrc}
    srcSet={modalIconSrcSet}
  />
)

const IncompleteErrorModal = ({ setShowModal, showModal }) => {
  const { appId } = useParams()
  const makeTestId = useMakeTestId('IncompleteErrorModal')
  const [isShowingInviteCollaborators, setIsShowingInviteCollaborators] = useState(false)
  const toggleInviteCollaborators = () => setIsShowingInviteCollaborators((status) => !status)

  const handleInviteCollaboratorsClick = () => {
    setShowModal(false)
    toggleInviteCollaborators()
  }

  const handleDoneClick = () => {
    setShowModal(false)
  }

  const modalActionButtons = [
    <BasicButton
      key='invite-collaborators'
      dataTestId={makeTestId('invite-collaborators')}
      type={BASIC_BUTTON_TYPE_OUTLINED}
      onClick={handleInviteCollaboratorsClick}
    >
      <FormattedMessage
        defaultMessage='invite a collaborator'
        id='sputnik.IncompleteErrorModal__pjjFvi'
      />
    </BasicButton>,
    <BasicButton
      key='done'
      dataTestId={makeTestId('done')}
      onClick={handleDoneClick}
    >
      <FormattedMessage
        defaultMessage='Okay'
        id='sputnik.IncompleteErrorModal__gZlFBP'
      />
    </BasicButton>,
  ]

  return (
    <div>
      <Modal
        actions={modalActionButtons}
        css={modalStyles}
        dataTestId={makeTestId('modal')}
        headerColor='accentSoft'
        headerImage={headerImage}
        isShowing={showModal}
        title={
          <FormattedMessage
            defaultMessage='Oops!'
            id='sputnik.IncompleteErrorModal__BEbOqj'
          />
        }
      >
        <div css={modalBodyStyles}>
          <FormattedMessage
            defaultMessage='There is an error(s) on the page that must be fixed before moving on. If you need help to complete the field(s), please invite a collaborator!'
            id='sputnik.IncompleteErrorModal__iEyerm'
          />
        </div>
      </Modal>
      <div>
        <InviteCollaborators
          appId={appId}
          isShowing={isShowingInviteCollaborators}
          setIsShowing={setIsShowingInviteCollaborators}
        />
      </div>
    </div>
  )
}

IncompleteErrorModal.propTypes = {
  setShowModal: func.isRequired,
  showModal: bool.isRequired,
}

const modalStyles = ({ mq }) => css`
  .Modal-header {
    padding-bottom: var(--tri-space-400);
    padding-top: var(--tri-space-400);
  }

  .Modal-header-image {
    img {
      width: 148px;
      ${mq.xxSmallMaxWidth({ height: '132px', width: '132px' })};
    }
  }
`
const modalBodyStyles = ({ mq, type }) => css`
  ${mq.xxSmallMaxWidth(type.triFontDesktopBodyMedium)}
`

export default IncompleteErrorModal
