/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/
import type * as _Types from '_gql'
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type RequestPhoneVerification_requestPhoneVerification_CreditApplicationPersonChallenge = {
  readonly __typename: 'CreditApplicationPersonChallenge'
  readonly phoneNumber: string | null
}

export type RequestPhoneVerification_Mutation = {
  readonly __typename: 'Mutation'
  readonly requestPhoneVerification: RequestPhoneVerification_requestPhoneVerification_CreditApplicationPersonChallenge | null
}

export type RequestPhoneVerificationVariables = _Types.Exact<{
  salesforceCreditId: _Types.Scalars['ID']['input']
  phoneNumber: _Types.Scalars['String']['input']
  personId: _Types.Scalars['ID']['input']
}>

export type RequestPhoneVerification = RequestPhoneVerification_Mutation

export const RequestPhoneVerificationDocument = gql`
  mutation RequestPhoneVerification($salesforceCreditId: ID!, $phoneNumber: String!, $personId: ID!) {
    requestPhoneVerification(salesforceCreditId: $salesforceCreditId, phoneNumber: $phoneNumber, personId: $personId) {
      phoneNumber
    }
  }
`
export type RequestPhoneVerificationMutationFn = Apollo.MutationFunction<
  RequestPhoneVerification,
  RequestPhoneVerificationVariables
>

/**
 * __useRequestPhoneVerification__
 *
 * To run a mutation, you first call `useRequestPhoneVerification` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPhoneVerification` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPhoneVerification, { data, loading, error }] = useRequestPhoneVerification({
 *   variables: {
 *      salesforceCreditId: // value for 'salesforceCreditId'
 *      phoneNumber: // value for 'phoneNumber'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useRequestPhoneVerification(
  baseOptions?: Apollo.MutationHookOptions<RequestPhoneVerification, RequestPhoneVerificationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RequestPhoneVerification, RequestPhoneVerificationVariables>(
    RequestPhoneVerificationDocument,
    options,
  )
}
export type RequestPhoneVerificationHookResult = ReturnType<typeof useRequestPhoneVerification>
export type RequestPhoneVerificationMutationResult = Apollo.MutationResult<RequestPhoneVerification>
export type RequestPhoneVerificationMutationOptions = Apollo.BaseMutationOptions<
  RequestPhoneVerification,
  RequestPhoneVerificationVariables
>
