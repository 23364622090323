import { LOCAL_STORAGE_KEYS } from '@sputnik/resources/constants'

// deal source parser.
export const getDealSourceIdFromCreditApplications = (creditApplications = []) => {
  const firstAppWithPartnerCode = creditApplications.find((application) => application?.partnerCode !== null)

  if (!firstAppWithPartnerCode) return null

  const partnerCode = firstAppWithPartnerCode?.partnerCode ?? ''
  const [dealSourceId, accountId] = partnerCode.split('-')

  if (!dealSourceId || !accountId) return null

  return dealSourceId.toLowerCase()
}

// deal source storage helpers
// TODO: possibly move these to new localStorageUtils.js too
export const getDealSourceIdFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.DEAL_SOURCE_ID_KEY)
}

export const setDealSourceIdInStorage = (id) => window.localStorage.setItem(LOCAL_STORAGE_KEYS.DEAL_SOURCE_ID_KEY, id)

export const removeDealSourceIdFromStorage = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEYS.DEAL_SOURCE_ID_KEY)
}
