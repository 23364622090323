import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import PageViewWrapper from '@sputnik/components/PageViewWrapper/PageViewWrapper'
import { PAGE_NAME_STRINGS } from '@sputnik/resources/constants'

import { RE_CAPTCHA_SITE_KEY } from './signUpConstants'
import SignUpView from './SignUpView'

const SignUp = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RE_CAPTCHA_SITE_KEY}
      scriptProps={{ appendTo: 'body', defer: true }}
    >
      <PageViewWrapper pageName={PAGE_NAME_STRINGS.PAGE_SIGNUP}>
        <SignUpView />
      </PageViewWrapper>
    </GoogleReCaptchaProvider>
  )
}

export default SignUp
