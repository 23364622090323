import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { useCanary } from '@bill/canary.react'

import { useAuth } from '@sputnik/auth'
import useDivvyUUID from '@sputnik/hooks/useDivvyUUID'
import AcceptOffer from '@sputnik/pages/AcceptOffer/AcceptOffer'
import ApplicationDeclined from '@sputnik/pages/ApplicationDeclined'
import GetApplicationStatuses from '@sputnik/pages/Dashboard/gql/GetApplicationStatuses.gql'
import GetApplicationProcessedCreditData from '@sputnik/pages/gql/GetApplicationProcessedCreditData.gql'
import GetDecisions from '@sputnik/pages/gql/GetDecisions.gql'
import StandardCreditLineOffer from '@sputnik/pages/StandardCreditLineOffer'
import SuccessFrozenCredit from '@sputnik/pages/SuccessFrozenCredit'
import SuccessPreApprovedDualOffer from '@sputnik/pages/SuccessPreApprovedDualOffer'
import SuccessPreApprovedOffers from '@sputnik/pages/SuccessPreApprovedOffers/SuccessPreApprovedOffers'
import SuccessWorkingOutDetails from '@sputnik/pages/SuccessWorkingOutDetails'
import VariableCreditLineOffer from '@sputnik/pages/VariableCreditLineOffer'
import { PATHNAME_CONSTS } from '@sputnik/resources/constants'
import { matchEmail } from '@sputnik/utils/dataUtils'

import { FIXED_LINE, SECURED_LINE, VARIABLE_LINE } from './applicationProcessedUtils'
import { DecisionContext } from './DecisionsContext'
import SecuredCreditLineOfferAcceptance from './SecuredCreditLineOfferAcceptance'
import StandardCreditLineOfferAcceptance from './StandardCreditLineOfferAcceptance'
import VariableCreditLineOfferAcceptance from './VariableCreditLineOfferAcceptance'

const ApplicationProcessed = () => {
  const { appId } = useParams()
  const { email } = useAuth()
  const [companyId] = useDivvyUUID()
  const { data: decisionsData } = useQuery(GetDecisions, {
    fetchPolicy: 'network-only',
    skip: !companyId,
    variables: { companyId },
  })
  const { data: applicationData } = useQuery(GetApplicationStatuses)

  const { data: applicationProcessedCreditData } = useQuery(GetApplicationProcessedCreditData, {
    skip: !appId,
    variables: { creditApplicationId: appId },
  })
  const { financeInfo, authorizedSigner, appVersion } = applicationProcessedCreditData?.creditApplication || {}
  const desiredCredit = financeInfo?.desiredCredit
  const authSignerEmail = authorizedSigner?.email
  const isLoggedInUserAuthorizedSigner = matchEmail(authSignerEmail, email)

  const decisions = decisionsData?.decisions || []
  const decisionId = decisions?.[0]?.id

  const standardLineOffer = decisions?.find((decision) => decision?.offerType === FIXED_LINE)
  const variableLineOffer = decisions?.find((decision) => decision?.offerType === VARIABLE_LINE)
  const securedLineOffer = decisions?.find((decision) => decision?.offerType === SECURED_LINE)

  const hasOnlyOneDecision = decisions?.length === 1
  const isStandardOfferOnly = !!(hasOnlyOneDecision && standardLineOffer)
  const isVariableOfferOnly = !!(hasOnlyOneDecision && variableLineOffer)
  const isSecuredOfferOnly = !!(hasOnlyOneDecision && securedLineOffer)

  // These variables below will either be updated or removed in following MR's for the new secured line flow
  const variableOfferOnly = decisions?.length === 1 && decisions?.[0]?.underwritingMethod === 'auto_cash_underwriting'
  const filteredStandardOffer = decisions.filter((decision) => decision.underwritingMethod !== 'auto_cash_underwriting')
  const filteredVariableOffer = decisions.filter((decision) => decision.underwritingMethod === 'auto_cash_underwriting')
  const applicationCount = applicationData?.creditApplications?.length
  const hasApplications = applicationCount > 0

  const standardOfferApprovedAmount = filteredStandardOffer?.[0]?.approvedAmountInCents
  const variableOfferApprovedAmount = filteredVariableOffer?.[0]?.approvedAmountInCents
  const approvedAmount = decisions?.[0]?.approvedAmountInCents

  return (
    <>
      <DecisionContext.Provider
        value={{
          decisionId,
          decisions,
          desiredCredit,
          securedLineOffer,
          variableLineOffer,
          standardLineOffer,
          isLoggedInUserAuthorizedSigner,
          authSignerEmail,
        }}
      >
        <Routes>
          <Route
            element={<SuccessPreApprovedOffers />}
            path={PATHNAME_CONSTS.MULTI_CREDIT_LINE_OFFER_PATH}
          />
          <Route
            element={<StandardCreditLineOfferAcceptance />}
            path={PATHNAME_CONSTS.MULTI_OFFER_TO_STANDARD_OFFER_PATH}
          />
          <Route
            element={<VariableCreditLineOfferAcceptance />}
            path={PATHNAME_CONSTS.MULTI_OFFER_TO_VARIABLE_OFFER_PATH}
          />
          <Route
            element={<SecuredCreditLineOfferAcceptance />}
            path={PATHNAME_CONSTS.MULTI_OFFER_TO_SECURED_OFFER_PATH}
          />
          <Route
            element={
              <>
                {isStandardOfferOnly && <StandardCreditLineOfferAcceptance />}
                {isVariableOfferOnly && <VariableCreditLineOfferAcceptance />}
                {isSecuredOfferOnly && <SecuredCreditLineOfferAcceptance />}
              </>
            }
            path={PATHNAME_CONSTS.SINGLE_CREDIT_LINE_OFFER_PATH}
          />

          {/* Any 'dual-offer' or 'pre-approved' route will be removed once secured line is launched */}
          <Route
            element={
              <SuccessPreApprovedDualOffer
                desiredCredit={desiredCredit}
                filteredStandardOffer={filteredStandardOffer}
                filteredVariableOffer={filteredVariableOffer}
              />
            }
            path='/dual-offer'
          />
          <Route
            element={<StandardCreditLineOffer desiredCredit={desiredCredit} />}
            path='/dual-offer/standard-pre-approved'
          />
          <Route
            element={<VariableCreditLineOffer desiredCredit={desiredCredit} />}
            path='/dual-offer/variable-pre-approved'
          />
          <Route
            element={
              <>
                {!variableOfferOnly && <StandardCreditLineOffer desiredCredit={desiredCredit} />}
                {variableOfferOnly && <VariableCreditLineOffer desiredCredit={desiredCredit} />}
              </>
            }
            path='/pre-approved'
          />

          <Route
            exact
            element={<AcceptOffer approvedAmount={approvedAmount} />}
            path='/pre-approved/next-steps'
          />
          <Route
            exact
            element={<AcceptOffer approvedAmount={standardOfferApprovedAmount} />}
            path='dual-offer/standard-pre-approved/next-steps'
          />
          <Route
            exact
            element={<AcceptOffer approvedAmount={variableOfferApprovedAmount} />}
            path='dual-offer/variable-pre-approved/next-steps'
          />
          <Route
            element={<SuccessFrozenCredit />}
            path='/frozen-credit'
          />
          <Route
            element={<SuccessWorkingOutDetails />}
            path='/working-out-details'
          />
          <Route
            element={<ApplicationDeclined hasApplications={hasApplications} />}
            path='/declined'
          />
        </Routes>
      </DecisionContext.Provider>
    </>
  )
}

export default ApplicationProcessed
