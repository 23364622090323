import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import GetDivvyUUID from '@sputnik/pages/gql/GetDivvyUUID.gql'

const useDivvyUUID = (appId) => {
  const { appId: appIdParam } = useParams()
  const creditApplicationId = appId || appIdParam
  const { data, loading } = useQuery(GetDivvyUUID, { variables: { creditApplicationId } })
  const companyDivvyUuid = data?.creditApplication?.companyDivvyUuid
  return [companyDivvyUuid, { loading }]
}

export default useDivvyUUID
