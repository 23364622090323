import { flattenObject } from '@sputnik/utils/dataUtils'

const personValueChecker = (person) => {
  let cleanedPerson = {
    address: {
      city: person?.address?.city,
      countryCode: person?.address?.countryCode,
      postalCode: person?.address?.postalCode,
      state: person?.address?.state,
      street: person?.address?.street,
    },
    birthYear: person?.birthYear,
    email: person?.email,
    firstName: person?.firstName,
    lastName: person?.lastName,
    phoneNumber: person?.phoneNumber,
  }

  if (person?.passportNumberLastFour) {
    cleanedPerson.passportNumberLastFour = person?.passportNumberLastFour
    cleanedPerson.passportCountry = person?.passportCountry
    cleanedPerson.passportExpiration = person?.passportExpiration
  } else {
    cleanedPerson.ssnLastFour = person?.ssnLastFour
  }

  const flattenedAuthorizedSigner = flattenObject(cleanedPerson)
  const arrayedPerson = Object.values(flattenedAuthorizedSigner)
  const personComplete = !arrayedPerson.includes(null) && !arrayedPerson.includes(undefined)
  return personComplete
}

export const isAuthorizedSignerComplete = (creditApp) => {
  return personValueChecker(creditApp?.authorizedSigner)
}
