/* global __DivvyEnvironment */
import { ApolloLink, HttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { ApolloClient } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { ErrorLink } from '@apollo/client/link/error'
import fetch from 'cross-fetch'

import { clearAllLocalStorageValues, getTokenFromStorage } from '@sputnik/auth'
import { getCreditAppIdFromToken } from '@sputnik/auth/jwt'
import { LOCAL_STORAGE_KEYS } from '@sputnik/resources/constants'
import { logError, logInfo } from '@sputnik/utils/loggerUtils'

/**
 * Objects that are not normalized are instead embedded within their parent object in the cache.
 * You can't access these objects directly in the cache, but you can access them via their parent.
 * We use this for entities under a credit app since most of them do not resolve with an ID, so
 * that we're not overwriting entities that may belong to different credit apps.
 */
const disabledNormalizationCacheOpt = { keyFields: false }

const cache = new InMemoryCache({
  typePolicies: {
    BankInfo: disabledNormalizationCacheOpt,
    Contact: { keyFields: ['contactId'] },
    CreditApplicant: {
      fields: {
        companyDivvyUuids: { merge: false },
        salesforceCreditIds: { merge: false },
      },
      keyFields: ['authEmail', 'authId'],
    },
    CreditApplication: {
      fields: {
        beneficialOwners: { merge: false },
        validationErrors: { merge: false },
      },
      keyFields: ['salesforceCreditId'],
    },
    CreditApplicationAddress: disabledNormalizationCacheOpt,
    CreditApplicationBusinessInfo: disabledNormalizationCacheOpt,
    CreditApplicationDocument: { keyFields: ['documentUuid'] },
    CreditApplicationFinanceInfo: disabledNormalizationCacheOpt,
    CreditApplicationPerson: { keyFields: ['id', 'cacheRole'] },
    CreditApplicationValidationError: disabledNormalizationCacheOpt,
    PersonaInquiry: { keyFields: ['inquiryId'] },
  },
})

const httpLink = new HttpLink({
  fetch,
  uri: ({ operationName }) => `${__DivvyEnvironment.APP_BACKEND}?op=${operationName}`,
})

const headerLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const headerToken = operation?.getContext()?.response?.headers?.get('x-refresh-token')
    const token = getTokenFromStorage()
    const creditAppId = getCreditAppIdFromToken(token)
    const { operationName } = operation || {}

    logInfo({
      attributes: {
        action: operationName,
        message: creditAppId,
      },
      eventName: 'ApolloCall',
      message: `${operationName} was called`,
    })

    if (headerToken && headerToken !== token) {
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_STORAGE_TOKEN_KEY, headerToken)
    }
    return response
  })
})

const authLink = setContext((_, { headers, overrideAuthToken }) => {
  const token = overrideAuthToken || getTokenFromStorage()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const hasUnauthorizedGraphQLError = (graphQLErrors) => {
  if (!graphQLErrors) return false
  return graphQLErrors.some((error) => {
    const errorCode = error?.extensions?.exception?.code || error?.code || error?.extensions?.response?.status
    return errorCode === 401 || errorCode === 'UNAUTHENTICATED'
  })
}

const errorLink = new ErrorLink((error) => {
  const operationName = error?.operation?.operationName
  const gqlErrors = error?.graphQLErrors

  let creditAppId

  try {
    const token = getTokenFromStorage()
    creditAppId = getCreditAppIdFromToken(token)
  } catch {
    creditAppId = null
  }

  if (gqlErrors) {
    gqlErrors.forEach((error) => {
      const errorCode = error?.extensions?.exception?.code || error?.code
      const errorMessage = error?.extensions?.exception?.message || error?.message
      const result = error?.extensions?.exception || error
      logError({
        attributes: {
          action: operationName,
          errorCode: errorCode,
          message: `Credit App ID: ${creditAppId}`,
          result: result,
        },
        eventName: 'ApolloCallError',
        message: errorMessage,
      })

      if (hasUnauthorizedGraphQLError(gqlErrors)) {
        clearAllLocalStorageValues()
        if (!getTokenFromStorage()) {
          logInfo({
            attributes: {
              action: `Unauthorized: ${operationName}`,
              errorCode: errorCode,
              result: 'Logs out and sends user to /auth',
              message: `UnauthorizedGraphQLError for Credit App ID: ${creditAppId}`,
            },
            eventName: 'ForcedCreditAppLogOut',
            message: `Unauthorized: ${errorMessage}`,
          })
          window.location.reload()
        }
      }
    })
  }
})

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([headerLink, authLink, errorLink, httpLink]),
  name: 'sputnik',
  resolvers: {},
  version: __DivvyEnvironment?.DEPLOY_VERSION ?? 'dev',
})

export default client
