import React, { useEffect, useState } from 'react'

import Spinner from '@divvy-web/skylab.spinner'

const DelayedLoader = (props) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(true)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return visible ? <Spinner {...props} /> : null
}

export default DelayedLoader
