/* global __DivvyEnvironment */
import React from 'react'
import { hotjar } from 'react-hotjar'
import PropTypes from 'prop-types'

import { useCanary } from '@bill/canary.react'
import useDidMount from '@divvy-web/hooks.usedidmount'

import MaintenanceScreen from '@sputnik/components/MaintenanceScreen'

export const Root = ({ children }) => {
  const maintMode = useCanary('sputnik-maint-mode')

  useDidMount(() => {
    const { HOT_JAR_ID, HOT_JAR_VERSION } = __DivvyEnvironment
    hotjar.initialize(HOT_JAR_ID, HOT_JAR_VERSION)
  })

  if (maintMode) return <MaintenanceScreen />

  return (
    <div
      data-testid='root'
      id='main-content'
    >
      {children}
    </div>
  )
}

Root.propTypes = {
  children: PropTypes.node,
}
