import { CreditApplicationPerson } from '_gql'

import { useAuth } from '@sputnik/auth'
import { matchEmail } from '@sputnik/utils/dataUtils'

interface UseGetIsLoggedInAsAuthSignerProps {
  authSignerEmail: CreditApplicationPerson['email']
}

const useGetIsLoggedInAsAuthSigner = ({ authSignerEmail }: UseGetIsLoggedInAsAuthSignerProps) => {
  const { email } = useAuth()
  const isLoggedInUserAuthorizedSigner = matchEmail(authSignerEmail, email)

  return { isLoggedInUserAuthorizedSigner }
}

export default useGetIsLoggedInAsAuthSigner
