import React, { useContext, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Spinner from '@divvy-web/skylab.spinner'
import TextLink from '@divvy-web/skylab.textlink'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'

import ApplicationAgreements from '@sputnik/components/ApplicationAgreements'
import { ApplicationAgreementsProps } from '@sputnik/components/ApplicationAgreements/ApplicationAgreements'
import CustomerAssistanceButton from '@sputnik/components/CustomerAssistanceButton'
import GoToDashboardButton from '@sputnik/components/GoToDashboardButton'
import ImageWrapper from '@sputnik/components/ImageWrapper'
import OneColorSidebar from '@sputnik/components/OneColorSidebar'
import useDeviceDetect from '@sputnik/hooks/useDeviceDetect'
import { getSideBarImage } from '@sputnik/pages/ApplicationProcessed/applicationProcessedUtils'
import { DecisionContext } from '@sputnik/pages/ApplicationProcessed/DecisionsContext'
import { useGetCreditApplicationTilaInfo } from '@sputnik/pages/gql/GetCreditApplicationTilaInfo.gql'
import { useSelectedDecision } from '@sputnik/pages/gql/mutations/CreditLineOfferSelectedDecision.gql'
import { PATHNAME_CONSTS, PRODUCT_CONST_STRINGS } from '@sputnik/resources/constants'
import { logError, logInfo } from '@sputnik/utils/loggerUtils'

import { securedCreditLineOfferAcceptanceCss } from './securedCreditLineOfferAcceptanceStyles'

const SecuredCreditLineOfferAcceptance = () => {
  const navigate = useNavigate()
  const { appId } = useParams()
  const { pathname } = useLocation()
  const { isMobile } = useDeviceDetect()
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any
  const { BILL_SPEND_AND_EXPENSE } = PRODUCT_CONST_STRINGS
  const [getClassName, makeTestId] = useNameFormatter('SecuredCreditLineOfferAcceptance')
  const { securedLineOffer, isLoggedInUserAuthorizedSigner, authSignerEmail } = useContext(DecisionContext)
  const { id: decisionId, offerType } = securedLineOffer || {}
  const { data, loading: isLoadingTilaInfo } = useGetCreditApplicationTilaInfo({
    fetchPolicy: 'network-only',
    variables: {
      creditApplicationId: appId as string,
      underwritingDecisionUuid: decisionId,
      underwritingOfferType: offerType,
      skipTila: !isLoggedInUserAuthorizedSigner,
    },
    // we don't need any of this information if the user is not the authorized signer
    skip: !securedLineOffer || !isLoggedInUserAuthorizedSigner || !appId,
  })

  const [selectedDecision, { loading: isSelectedDecisionLoading }] = useSelectedDecision({
    onCompleted: () => {
      logInfo({
        attributes: {
          action: 'selectedDecision',
          result: `accepts offer for secured credit line and send to polling (/app/${appId}/section/${PATHNAME_CONSTS.ACCEPTING_OFFER})`,
        },
        eventName: 'SecuredCreditLineOfferAcceptance',
      })

      navigate(`/app/${appId}/section/${PATHNAME_CONSTS.ACCEPTING_OFFER}`)
    },
    onError: (e) => {
      logError({
        attributes: {
          action: 'selectedDecision',
          message: e?.message,
          result: 'Error accepting offer for secured credit line',
        },
        eventName: 'SecuredCreditLineOfferAcceptance',
      })

      showDangerToast(
        <FormattedMessage
          defaultMessage='There was an error accepting your offer.'
          id='sputnik.SecuredCreditLineOfferAcceptance__oRIVFo'
        />,
        {
          autoHideDelay: 5000,
        },
      )
    },
  })

  const isSingleOffer = !pathname.includes('credit-line-offers')

  // for ApplicationAgreements
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)

  const creditApplication = (data?.creditApplication as ApplicationAgreementsProps['creditApplication']) || {}
  const tila: { content?: string; meta?: { signedAt?: string } } = creditApplication?.tila || {}

  const isLoading = isLoadingTilaInfo || isSelectedDecisionLoading
  const hasTilaContent = !!tila?.content
  const hasAgreedToTila = !!tila?.meta?.signedAt
  const disabledWithTila = (!hasAgreedToTerms ||
    isLoading ||
    (hasTilaContent && !hasAgreedToTila) ||
    !creditApplication) as boolean
  const disabledWithoutTila = (!hasAgreedToTerms || isLoading) as boolean
  const disableAcceptOfferButton = hasTilaContent ? disabledWithTila : disabledWithoutTila

  const handleCheckboxClick = () => {
    setHasAgreedToTerms(!hasAgreedToTerms)
  }

  const handleAcceptClick = () => {
    selectedDecision({
      variables: {
        id: decisionId,
        salesforceCreditId: appId as string,
      },
    })
  }

  if (isLoadingTilaInfo || !securedLineOffer) {
    return (
      <div css={securedCreditLineOfferAcceptanceCss}>
        <Spinner centered />
      </div>
    )
  }

  return (
    <div css={securedCreditLineOfferAcceptanceCss}>
      <CustomerAssistanceButton />
      <div className='main-container fs-unmask'>
        <div className={getClassName('page-content')}>
          <div
            className={getClassName('body')}
            data-testid={makeTestId('')}
          >
            <div className={getClassName('title')}>
              <FormattedMessage
                defaultMessage='The rewards machine'
                id='sputnik.SecuredCreditLineOfferAcceptance__QzFxBy'
              />
            </div>
            <div className={getClassName('subtitle')}>
              <FormattedMessage
                defaultMessage='The {BILL_SPEND_AND_EXPENSE} secured credit line with a cash back advantage.'
                id='sputnik.SecuredCreditLineOfferAcceptance__P8MxuK'
                values={{ BILL_SPEND_AND_EXPENSE }}
              />
            </div>
            {isMobile && (
              <div>
                <ImageWrapper
                  alt='person scanning mobile device'
                  imageName='credit-line-offer-mobile'
                />
              </div>
            )}
            <div className={getClassName('how-it-works')}>
              <FormattedMessage
                defaultMessage='How it works:'
                id='sputnik.SecuredCreditLineOfferAcceptance__ON3IdR'
              />
            </div>
            <div className={getClassName('how-it-works-list')}>
              <ul>
                <li>
                  <FormattedMessage
                    defaultMessage='<b>Super charged cash back—</b>Earn 1.5% cash back on all eligible purchases with no minimum spend—and unlock 1.75% cash back if you reach the $100k a month minimum.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__UdyeSy'
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='<b>Flexible security deposit—</b>Fund your credit limit with a cash security deposit, which is adjustable and refundable based on your balance.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__ubAmhc'
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='<b>Choose your billing cycle—</b>Your balance is due in full at the end of each billing cycle.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__ajyDej'
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='<b>Payments your way—</b>Make payments using your cash security deposit or another funding source.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__DZSLqa'
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </li>
              </ul>
            </div>
            <p className={getClassName('learn-more-wrapper')}>
              <FormattedMessage
                defaultMessage='Still need more info? <textlink>Learn more</textlink>'
                id='sputnik.SecuredCreditLineOfferAcceptance__rloIn8'
                values={{
                  textlink: (chunks) => (
                    <TextLink
                      className={getClassName('learn-more')}
                      href='https://help.bill.com/direct/s/article/000003645'
                      target='_blank'
                    >
                      {chunks}
                    </TextLink>
                  ),
                }}
              />
            </p>
            {isLoggedInUserAuthorizedSigner && offerType && (
              <ApplicationAgreements
                creditApplication={creditApplication}
                decisionId={decisionId}
                // TODO: change this variable name to hasAgreedToTila down the chain
                hasAgreedToOfferSummary={hasAgreedToTila}
                hasAgreedToTerms={hasAgreedToTerms}
                offerType={offerType}
                onCheckboxClick={handleCheckboxClick}
              />
            )}
            {!isLoggedInUserAuthorizedSigner && (
              <div className={getClassName('unauthorized-accept-message')}>
                <FormattedMessage
                  defaultMessage='Only the authorized signer ({email}) can accept this offer.'
                  id='sputnik.SecuredCreditLineOfferAcceptance__igv+K/'
                  values={{
                    email: authSignerEmail,
                  }}
                />
              </div>
            )}
            <section className={getClassName('buttons')}>
              {!isSingleOffer && (
                <BasicButton
                  className={getClassName('previous')}
                  dataTestId={makeTestId('previous')}
                  type={BASIC_BUTTON_TYPE_FLAT}
                  onClick={() => navigate(-1)}
                >
                  <FormattedMessage
                    defaultMessage='Previous'
                    id='sputnik.SecuredCreditLineOfferAcceptance__JJNc3c'
                  />
                </BasicButton>
              )}
              {isSingleOffer && (
                <GoToDashboardButton
                  buttonType={BASIC_BUTTON_TYPE_FLAT}
                  componentName={makeTestId('')}
                />
              )}
              {isLoggedInUserAuthorizedSigner && (
                <BasicButton
                  className={getClassName('accept')}
                  dataTestId={makeTestId('accept')}
                  disabled={disableAcceptOfferButton}
                  showSpinner={isSelectedDecisionLoading}
                  onClick={handleAcceptClick}
                >
                  <FormattedMessage
                    defaultMessage='Accept offer'
                    id='sputnik.SecuredCreditLineOfferAcceptance__/xro5W'
                  />
                </BasicButton>
              )}
            </section>
          </div>
        </div>
      </div>
      {!isMobile && <OneColorSidebar srcImage={getSideBarImage()} />}
    </div>
  )
}

export default SecuredCreditLineOfferAcceptance
