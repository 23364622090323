import React, { useState } from 'react'
import { css } from '@emotion/core'
import { bool, string } from 'prop-types'

import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import { FormTextInput } from '@divvy-web/skylab.form'
import Tooltip, { TOOLTIP_POSITION_TOP } from '@divvy-web/skylab.tooltip'

import FormElementWrapper from './FormElementWrapper'

const FullNameInput = ({
  dataTestId,
  formFieldClassName,
  name,
  preferredFullName,
  readOnly,
  alwaysShowError,
  disabled,
  isAdmin,
  showTooltipAtStart,
}) => {
  const [showTooltip, setShowTooltip] = useState(showTooltipAtStart)
  const closeTooltip = () => setShowTooltip(false)

  const labelAndPlaceholder = isAdmin ? 'Admin full name' : 'Full name'

  const tooltipContent = (
    <div className='tooltip-content-wrapper'>
      <BasicButton
        color='background'
        icon='close'
        name='close'
        type={BASIC_BUTTON_TYPE_FLAT}
        onClick={closeTooltip}
      />
      <FormattedMessage
        defaultMessage='
          Unfortunately, we are not able to print more than 21 characters for the name on your card.
          {br}{br}
          Please write out how you would like your full name to appear on your card in 21 or fewer characters.'
        id='sputnik.FullNameInput__OaIeMn'
        values={{ br: <br /> }}
      />
    </div>
  )
  return (
    <div css={fullNameStyles}>
      <Tooltip
        interactive
        html={tooltipContent}
        open={showTooltip}
        position={TOOLTIP_POSITION_TOP}
      >
        <FormElementWrapper>
          <FormTextInput
            alwaysShowError={alwaysShowError}
            autoComplete='none'
            className='fs-mask'
            dataTestId={dataTestId}
            disabled={disabled}
            formFieldClassName={formFieldClassName}
            label={
              <FormattedMessage
                defaultMessage='{label}'
                id='sputnik.FullNameInput__j8y+qc'
                values={{ label: labelAndPlaceholder }}
              />
            }
            maxLength={21}
            name={name}
            placeholder={
              <FormattedMessage
                defaultMessage='{placeholder}'
                id='sputnik.FullNameInput__8SayhS'
                values={{ placeholder: labelAndPlaceholder }}
              />
            }
            readOnly={readOnly}
            rightIcon={readOnly ? null : <span>{preferredFullName ? preferredFullName.length : 0}/21</span>}
          />
        </FormElementWrapper>
      </Tooltip>
    </div>
  )
}

const fullNameStyles = css`
  .Tooltip-label {
    display: block;
  }

  .tooltip-content-wrapper {
    max-width: 280px;
    margin-bottom: var(--tri-space-200);
    .BasicButton {
      margin-left: auto;
      margin-right: 0;
    }
  }
`

FullNameInput.propTypes = {
  alwaysShowError: bool,
  dataTestId: string,
  disabled: bool,
  formFieldClassName: string,
  isAdmin: bool,
  name: string,
  preferredFullName: string,
  readOnly: bool,
  showTooltipAtStart: bool,
}

export default FullNameInput
