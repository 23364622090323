import { css } from '@emotion/core'

export const offerTileWebCss = ({ mq, type }) => css`
  .OfferTile {
    &-content-hasClick {
      height: 546px;
      max-width: 342px;
      min-width: 342px;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 0 var(--tri-space-350) 0;
    }

    &-info-chip {
      width: 110%;
      display: flex;
      padding: var(--tri-space-100);

      .Badge {
        height: 32px;
      }
    }

    &-image-and-text-wrapper {
      height: 75%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-captions {
      flex-grow: 1;
    }

    .program-image {
      max-width: var(--tri-space-1900);
    }

    &-desktop-title {
      ${type.triFontDesktopTitleSmallEmphasis}
      padding-top: var(--tri-space-100);
    }

    &-amount-placeholder-text {
      ${type.triFontDesktopTitleSmallEmphasis}
      padding-top: var(--tri-space-100);
    }

    &-offer-tile-approved-amount {
      ${type.triFontDesktopTitleMediumEmphasis};
      height: 32px;
    }

    &-top-caption {
      text-align: left;
      padding: var(--tri-space-350) 0 0;
    }

    &-bottom-caption {
      text-align: left;
      padding-top: var(--tri-space-250);
    }

    &-footer {
      margin-bottom: var(--tri-space-400);
    }
  }
`

export const offerTileMobileCss = ({ mq, type }) => css`
  .OfferTile {
    &-content-hasClick {
      height: var(--tri-space-1600);
      max-width: 350px;
      display: flex;
      margin: 0 var(--tri-space-250);
      padding: 0;
    }

    &-image-and-text-wrapper {
      max-width: 100%;
      display: flex;
      align-items: center;
      text-align: left;
    }

    &-text-wrapper {
      flex-direction: column;
    }

    .program-image {
      max-width: var(--tri-space-800);
    }

    &-amount-placeholder-text {
      ${type.triFontDesktopTitleSmallEmphasis}
      padding-top: var(--tri-space-100);
    }

    &-offer-tile-approved-amount {
      display: flex;
      flex-direction: column;
      gap: var(--tri-space-50);
      margin-left: var(--tri-space-100);
    }

    &-mobile-amount {
      ${type.triFontDesktopTitleSmallEmphasis}
    }

    &-mobile-title {
      ${type.triFontDesktopBodyXSmallEmphasis}
    }

    &-top-caption {
      text-align: left;
      ${type.triFontDesktopBodySmall}
      padding: unset;
    }

    &-footer {
      align-self: center;
    }
  }
`
