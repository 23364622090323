import React from 'react'
import { css } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'

import ImageWrapper from '@sputnik/components/ImageWrapper'

interface SideSectionImageProps {
  imageName: string
  isSvg: boolean
}

const SideSectionImage = ({ imageName, isSvg }: SideSectionImageProps) => {
  const [makeTestId, getclassName] = useNameFormatter('SideSectionImage')

  if (!imageName) {
    return null
  }

  return (
    <div css={sideSectionImageCss}>
      <div className={getclassName(`${imageName}`)}>
        <ImageWrapper
          dataTestId={makeTestId('image-wrapper')}
          imageName={imageName}
          isSvg={isSvg}
        />
      </div>
    </div>
  )
}

const sideSectionImageCss = ({ mq }) => css`
  .program-image {
    display: flex;
    position: fixed;
    width: 100%;

    ${mq.mediumMaxWidth({ display: 'none' })};
  }
`

export default SideSectionImage
