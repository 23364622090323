import { matchEmail } from '@sputnik/utils/dataUtils'
import {
  cityValidation,
  dobValidation,
  emailValidation,
  illegalCharactersForHumanNameMessage,
  legalCharactersForHumanNamePattern,
  passportCountryValidation,
  passportExpirationValidation,
  passportNumberValidation,
  phoneNumberValidation,
  postalCodeWithForeignAddressValidation,
  ssnValidation,
  stateValidation,
  streetValidation,
} from '@sputnik/utils/validationUtils'

export default (owner, formValues) => {
  switch (owner) {
    case '1':
      return owner0Validation
    case '2':
      return owner1Validation
    case '3':
      return owner2Validation
    case '4':
      return owner3Validation
    case null:
      return {
        ...(formValues && formValues?.majorityHolders === 'yes' && formValues.owner0_isCreated && owner0Validation),
        ...(formValues && formValues?.majorityHolders === 'yes' && formValues.owner1_isCreated && owner1Validation),
        ...(formValues && formValues?.majorityHolders === 'yes' && formValues.owner2_isCreated && owner2Validation),
        ...(formValues && formValues?.majorityHolders === 'yes' && formValues.owner3_isCreated && owner3Validation),
        majorityHolders: {
          presence: { message: 'Please make a selection before clicking next' },
        },
      }
    default:
      throw new Error('No validations available for owner ' + owner)
  }
}

const beneficialOwnerEmailValidation =
  (options = {}) =>
  (value, allAttributes) => {
    const isAuthSignerDuplicate =
      !!value && !allAttributes[options.key + '_isAuthorizedSigner'] && matchEmail(allAttributes.email, value)
    if (isAuthSignerDuplicate) {
      return {
        format: { message: 'Same email is associated with the authorized signer.', pattern: '.' },
      }
    }

    const isOwnerDuplicate = ['owner0', 'owner1', 'owner2', 'owner3']
      .filter((k) => k !== options.key)
      .some((k) => !!value && matchEmail(allAttributes[k + '_email'], value))
    if (isOwnerDuplicate) {
      return { format: { message: 'Same email is associated with another owner.', pattern: '.' } }
    }

    return emailValidation(value)
  }

const owner0Validation = {
  owner0_addressCity: cityValidation(),
  owner0_addressCountryCode: {
    presence: {
      message: 'Please select a country',
    },
  },
  owner0_addressPostalCode: postalCodeWithForeignAddressValidation({ prefix: 'owner0_' }),
  owner0_addressState: stateValidation({ prefix: 'owner0_' }),
  owner0_addressStreet: streetValidation(),
  owner0_addressStreetAdditional: streetValidation(false),
  owner0_dobDisplay: (_, attributes) => dobValidation(attributes, 'owner0_'),
  owner0_email: beneficialOwnerEmailValidation({ key: 'owner0' }),
  owner0_firstName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'First name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a first name',
    },
  },
  owner0_lastName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'Last name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a last name',
    },
  },
  owner0_passportCountry: (_, attributes) => passportCountryValidation(attributes, 'owner0_'),
  owner0_passportExpiration: (_, attributes) => passportExpirationValidation(attributes, 'owner0_'),
  owner0_passportNumberDisplay: (_, attributes) => passportNumberValidation(attributes, 'owner0_'),
  owner0_phoneNumber: phoneNumberValidation,
  owner0_ssnDisplay: (_, attributes) => ssnValidation(attributes, 'owner0_'),
}

const owner1Validation = {
  owner1_addressCity: {
    presence: {
      message: 'Please enter a city',
    },
  },
  owner1_addressCountryCode: {
    presence: {
      message: 'Please select a country',
    },
  },
  owner1_addressPostalCode: postalCodeWithForeignAddressValidation({ prefix: 'owner1_' }),
  owner1_addressState: stateValidation({ prefix: 'owner1_' }),
  owner1_addressStreet: streetValidation(),
  owner1_addressStreetAdditional: streetValidation(false),
  owner1_dobDisplay: (_, attributes) => dobValidation(attributes, 'owner1_'),
  owner1_email: beneficialOwnerEmailValidation({ key: 'owner1' }),
  owner1_firstName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'First name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a first name',
    },
  },
  owner1_lastName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'Last name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a last name',
    },
  },
  owner1_passportCountry: (_, attributes) => passportCountryValidation(attributes, 'owner1_'),
  owner1_passportExpiration: (_, attributes) => passportExpirationValidation(attributes, 'owner1_'),
  owner1_passportNumberDisplay: (_, attributes) => passportNumberValidation(attributes, 'owner1_'),
  owner1_phoneNumber: phoneNumberValidation,
  owner1_ssnDisplay: (_, attributes) => ssnValidation(attributes, 'owner1_'),
}

const owner2Validation = {
  owner2_addressCity: {
    presence: {
      message: 'Please enter a city',
    },
  },
  owner2_addressCountryCode: {
    presence: {
      message: 'Please select a country',
    },
  },
  owner2_addressPostalCode: postalCodeWithForeignAddressValidation({ prefix: 'owner2_' }),
  owner2_addressState: stateValidation({ prefix: 'owner2_' }),
  owner2_addressStreet: streetValidation(),
  owner2_addressStreetAdditional: streetValidation(false),
  owner2_dobDisplay: (_, attributes) => dobValidation(attributes, 'owner2_'),
  owner2_email: beneficialOwnerEmailValidation({ key: 'owner2' }),
  owner2_firstName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'First name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a first name',
    },
  },
  owner2_lastName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'Last name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a last name',
    },
  },
  owner2_passportCountry: (_, attributes) => passportCountryValidation(attributes, 'owner2_'),
  owner2_passportExpiration: (_, attributes) => passportExpirationValidation(attributes, 'owner2_'),
  owner2_passportNumberDisplay: (_, attributes) => passportNumberValidation(attributes, 'owner2_'),
  owner2_phoneNumber: phoneNumberValidation,
  owner2_ssnDisplay: (_, attributes) => ssnValidation(attributes, 'owner2_'),
}

const owner3Validation = {
  owner3_addressCity: {
    presence: {
      message: 'Please enter a city',
    },
  },
  owner3_addressCountryCode: {
    presence: {
      message: 'Please select a country',
    },
  },
  owner3_addressPostalCode: postalCodeWithForeignAddressValidation({ prefix: 'owner3_' }),
  owner3_addressState: stateValidation({ prefix: 'owner3_' }),
  owner3_addressStreet: streetValidation(),
  owner3_addressStreetAdditional: streetValidation(false),
  owner3_dobDisplay: (_, attributes) => dobValidation(attributes, 'owner3_'),
  owner3_email: beneficialOwnerEmailValidation({ key: 'owner3' }),
  owner3_firstName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'First name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a first name',
    },
  },
  owner3_lastName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'Last name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a last name',
    },
  },
  owner3_passportCountry: (_, attributes) => passportCountryValidation(attributes, 'owner3_'),
  owner3_passportExpiration: (_, attributes) => passportExpirationValidation(attributes, 'owner3_'),
  owner3_passportNumberDisplay: (_, attributes) => passportNumberValidation(attributes, 'owner3_'),
  owner3_phoneNumber: phoneNumberValidation,
  owner3_ssnDisplay: (_, attributes) => ssnValidation(attributes, 'owner3_'),
}
