import React, { useState } from 'react'
import { bool, func, string } from 'prop-types'

import { FormattedMessage } from '@divvy-web/i18n'
import Form from '@divvy-web/skylab.form'
import Image from '@divvy-web/skylab.image'
import { TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'

import { useAuth } from '@sputnik/auth'
import { getOriginalAccessToken } from '@sputnik/auth/tokenUtils'

import CreatePasswordContent from './CreatePasswordContent'
import CreatePasswordModal from './CreatePasswordModal'
import CreatePasswordPopsheet from './CreatePasswordPopsheet'
import passwordValidations from './passwordValidations'

const CreatePasswordContainer = ({ isShowing, onSubmit, usernameEmail }) => {
  const { handleResetAuthPassword, logout } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const showToast = useToast()
  const originalAccessToken = getOriginalAccessToken()

  const onSubmitResetPasswordComplete = () => {
    setIsSubmitting(false)
    showToast(
      TOAST_TYPE_SUCCESS,
      <FormattedMessage
        defaultMessage='New password has been set successfully.'
        id='sputnik.CreatePasswordContainer__Yqy3Bs'
      ></FormattedMessage>,
      { autoHideDelay: 3000 },
    )
    onSubmit()
  }

  const handleSubmit = (password) => {
    setIsSubmitting(true)
    handleResetAuthPassword({
      accessToken: originalAccessToken,
      newPassword: password,
      onComplete: onSubmitResetPasswordComplete,
      setIsSubmitting,
      showToast,
      isCreatingNewPassword: true,
    })
  }

  const initialValues = {
    confirmPassword: '',
    confirmPasswordDisplay: '',
    password: '',
    passwordDisplay: '',
    username: usernameEmail || '',
  }

  const title = (
    <FormattedMessage
      defaultMessage="Let's create your login password"
      id='sputnik.CreatePasswordContainer__RdOb5T'
    />
  )

  const headerImage = (
    <Image
      alt='password-lock'
      assetFolder='illustrations'
      assetName='signup-create-password-modal'
      className='create-password-image'
    />
  )

  const passwordInfoSubtitle = (
    <div className='password-modal-subtitle'>
      <FormattedMessage
        defaultMessage='This is the information that you will use to login in the future'
        id='sputnik.CreatePasswordContainer__4drGXy'
      />
    </div>
  )

  return (
    <div>
      <Form
        initialValues={initialValues}
        validation={passwordValidations}
      >
        <CreatePasswordModal
          headerImage={headerImage}
          isLoading={isSubmitting}
          isShowing={isShowing}
          title={title}
          onLogout={logout}
          onSubmit={handleSubmit}
        >
          {passwordInfoSubtitle}
          <CreatePasswordContent
            isMobile={false}
            shouldShowUsername={true}
          />
        </CreatePasswordModal>
        <CreatePasswordPopsheet
          headerImage={headerImage}
          isLoading={isSubmitting}
          isShowing={isShowing}
          subtitle={title}
          onLogout={logout}
          onSubmit={handleSubmit}
        >
          {passwordInfoSubtitle}
          <CreatePasswordContent
            isMobile
            shouldShowUsername={false}
          />
        </CreatePasswordPopsheet>
      </Form>
    </div>
  )
}

CreatePasswordContainer.propTypes = { isShowing: bool, onSubmit: func, usernameEmail: string }

export default CreatePasswordContainer
