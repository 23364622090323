import React from 'react'
import { css } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import TextLink from '@divvy-web/skylab.textlink'
import Tile from '@divvy-web/skylab.tile'

import ImageWrapper from '@sputnik/components/ImageWrapper'
import { PRODUCT_CONST_STRINGS } from '@sputnik/resources/constants'

const TypesOfCreditInfoBox = () => {
  const { BILL_DIVVY_RESERVE_CARD, BILL_SPEND_AND_EXPENSE } = PRODUCT_CONST_STRINGS
  const [makeTestId, getClassName] = useNameFormatter('TypesOfCreditInfoBox')
  return (
    <Tile
      css={wrapperCss}
      dataTestId={makeTestId('')}
    >
      <div className={getClassName('info-wrap')}>
        <div className={getClassName('title')}>
          <FormattedMessage
            defaultMessage='{BILL_SPEND_AND_EXPENSE} offers 3 types of credit:'
            id='sputnik.TypesOfCreditInfoBox__3ULLF4'
            values={{ BILL_SPEND_AND_EXPENSE }}
          />
        </div>
        <div
          className={getClassName('standard-credit-container')}
          css={infoBlockCss}
          data-testid={makeTestId('standard-credit-container')}
        >
          <div className={getClassName('image-container')}>
            <ImageWrapper imageName='spot-standard-credit-line' />
          </div>
          <div className={getClassName('title-and-info-container')}>
            <div className={getClassName('subtitle')}>
              <FormattedMessage
                defaultMessage='Standard credit line'
                id='sputnik.TypesOfCreditInfoBox__WQCeHH'
              />
            </div>
            <div className={getClassName('info')}>
              <FormattedMessage
                defaultMessage='Your credit limit is fixed and typically won’t change.'
                id='sputnik.TypesOfCreditInfoBox__1J9Z2H'
              />
            </div>
          </div>
        </div>
        <div
          className={getClassName('variable-credit-container')}
          css={infoBlockCss}
          data-testid={makeTestId('variable-credit-container')}
        >
          <div className={getClassName('image-container')}>
            <ImageWrapper imageName='spot-variable-credit-line' />
          </div>
          <div className={getClassName('title-and-info-container')}>
            <div className={getClassName('subtitle')}>
              <FormattedMessage
                defaultMessage='Variable credit line'
                id='sputnik.TypesOfCreditInfoBox__HGPe1H'
              />
            </div>
            <div className={getClassName('info')}>
              <FormattedMessage
                defaultMessage='Your credit limit may adjust based on your bank account balance. <textlink>Learn more</textlink>'
                id='sputnik.TypesOfCreditInfoBox__2BzZ1L'
                values={{
                  textlink: (chunks) => (
                    <TextLink
                      className={getClassName('learn-more')}
                      href='https://help.bill.com/direct/s/article/6702952'
                      target='_blank'
                    >
                      {chunks}
                    </TextLink>
                  ),
                }}
              />
            </div>
          </div>
        </div>

        <div
          className={getClassName('secured-credit-container')}
          css={infoBlockCss}
          data-testid={makeTestId('secured-line-container')}
        >
          <div className={getClassName('image-container')}>
            <ImageWrapper imageName='spot-secured-credit-line' />
          </div>
          <div className={getClassName('title-and-info-container')}>
            <div className={getClassName('subtitle')}>
              <FormattedMessage
                defaultMessage='{BILL_DIVVY_RESERVE_CARD}'
                id='sputnik.TypesOfCreditInfoBox__Zm8wJX'
                values={{ BILL_DIVVY_RESERVE_CARD }}
              />
            </div>
            <div className={getClassName('info')}>
              <FormattedMessage
                defaultMessage='Your credit limit is funded by your own cash deposit, which you can adjust at any time. <textlink>Learn more</textlink>'
                id='sputnik.TypesOfCreditInfoBox__JWYBC1'
                values={{
                  textlink: (chunks) => (
                    <TextLink
                      className={getClassName('learn-more')}
                      href='https://help.bill.com/direct/s/article/000003645'
                      target='_blank'
                    >
                      {chunks}
                    </TextLink>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Tile>
  )
}

const wrapperCss = ({ mq }) => css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  max-width: 498px;
  max-height: 100%;
  flex-shrink: 0;
  gap: var(--tri-space-1800);
  background: var(--tri-color-fill-accent-primary);
  margin-bottom: var(--tri-space-500);

  .TypesOfCreditInfoBox-wrapper {
    ${mq.xSmallMaxWidth({ margin: '0 0 var(--tri-space-300) 0' })}
  }

  .TypesOfCreditInfoBox-info-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--tri-space-400);
    background: var(--tri-color-text-primary-inverse);
    ${mq.xSmallMaxWidth({ gap: '0' })};
  }
`

const infoBlockCss = ({ mq, type }) => css`
  display: flex;
  align-items: center;

  .TypesOfCreditInfoBox-image-container {
    img {
      max-width: 80px;
      ${mq.xSmallMaxWidth({ maxWidth: '48px' })}
    }
  }

  .TypesOfCreditInfoBox-title {
    ${type.triFontDesktopTitleSmall}
  }

  .TypesOfCreditInfoBox-title-and-info-container {
    display: flex;
    flex-direction: column;
    gap: var(--tri-space-100);
    .TypesOfCreditInfoBox-subtitle {
      ${type.triFontDesktopBodyMediumEmphasis}
    }

    .TypesOfCreditInfoBox-info {
      ${type.triFontDesktopBodyMedium};
      ${mq.xSmallMaxWidth(type.triFontDesktopBodySmall)}
    }
  }
`

export default TypesOfCreditInfoBox
