import React, { useState } from 'react'
import { SignableCreditApplicationDocumentMeta, UnderwritingOffer } from '_gql'
import { css } from '@emotion/core'
import { GetCreditApplicationTilaInfo_creditApplication_CreditApplication as CreditApplicationType } from 'src/pages/gql/GetCreditApplicationTilaInfo.gql'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { TOAST_TYPE_DANGER, TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'

import useDeviceDetect from '@sputnik/hooks/useDeviceDetect'
import { useAcceptTilaAgreement } from '@sputnik/pages/gql/mutations/AcceptTilaAgreement.gql'
import { logError, logInfo } from '@sputnik/utils/loggerUtils'

import AgreementLink from './AgreementLink'
import AgreementModal from './AgreementModal'
import AgreementPopsheet from './AgreementPopsheet'
import ApplicationTerms from './ApplicationTerms'

export interface ApplicationAgreementsProps {
  creditApplication: CreditApplicationType
  hasAgreedToTerms: boolean
  offerType?: UnderwritingOffer | null
  decisionId?: SignableCreditApplicationDocumentMeta['underwritingDecisionUuid']
  hasAgreedToOfferSummary: boolean
  onCheckboxClick: (e: React.ChangeEvent) => void
}

const ApplicationAgreements = ({
  creditApplication,
  hasAgreedToTerms,
  // below refers to hasAgreedToTila
  hasAgreedToOfferSummary,
  offerType,
  decisionId,
  onCheckboxClick,
}: ApplicationAgreementsProps) => {
  const { isMobile } = useDeviceDetect()
  const showSuccessToast = useToast(TOAST_TYPE_SUCCESS) as any
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any
  const [makeTestId] = useNameFormatter('ApplicationAgreements')
  const [isShowingAgreementModal, setIsShowingAgreementModal] = useState(false)
  const { tila, salesforceCreditId } = creditApplication || {}
  const needsTilaAgreement = !!tila
  const tilaContent = tila?.content
  const disableApplicationTerms = needsTilaAgreement && !hasAgreedToOfferSummary

  const [acceptTilaAgreement, { loading: isAcceptingTilaAgreement }] = useAcceptTilaAgreement({
    onCompleted: () => {
      setIsShowingAgreementModal(false)

      logInfo({
        attributes: {
          action: 'acceptTilaAgreement',
          result: 'accepts agreement terms for application',
        },
        eventName: 'applicationAgreements',
      })

      showSuccessToast(
        <FormattedMessage
          defaultMessage='You have successfully agreed to your offer summary'
          id='sputnik.ApplicationAgreements__VFXi23'
        />,
      )
    },
    onError: (e) => {
      setIsShowingAgreementModal(false)

      logError({
        attributes: {
          action: 'acceptTilaAgreement',
          message: e?.message,
          result: 'Error accepting agreement terms for application',
        },
        eventName: 'applicationAgreements',
      })

      showDangerToast(
        <FormattedMessage
          defaultMessage='There was an error agreeing to your offer summary.'
          id='sputnik.ApplicationAgreements__9fJ3od'
        />,
      )
    },
  })

  const handleAgreeToOfferSummary = () => {
    if (offerType) {
      acceptTilaAgreement({
        variables: {
          salesforceCreditId: salesforceCreditId as string,
          underwritingDecisionUuid: decisionId,
          underwritingOfferType: offerType,
        },
        refetchQueries: ['GetCreditApplicationTilaInfo'],
      })
    }
  }

  return (
    <div
      css={applicationAgreementsCss}
      data-testid={makeTestId('')}
    >
      {needsTilaAgreement && (
        <AgreementLink
          hasAgreedToOfferSummary={hasAgreedToOfferSummary}
          onClick={() => setIsShowingAgreementModal(true)}
        />
      )}
      <ApplicationTerms
        creditApplication={creditApplication}
        disableApplicationTerms={disableApplicationTerms}
        hasAgreedToTerms={hasAgreedToTerms}
        onCheckboxClick={onCheckboxClick}
      />
      {!isMobile && tilaContent && offerType && (
        <AgreementModal
          hasAgreedToOfferSummary={hasAgreedToOfferSummary}
          isAcceptingTilaAgreement={isAcceptingTilaAgreement}
          isShowing={isShowingAgreementModal}
          tilaContent={tilaContent}
          onAgreeClick={handleAgreeToOfferSummary}
          onCancel={() => setIsShowingAgreementModal(false)}
        />
      )}
      {isMobile && tilaContent && offerType && (
        <AgreementPopsheet
          hasAgreedToOfferSummary={hasAgreedToOfferSummary}
          isAcceptingTilaAgreement={isAcceptingTilaAgreement}
          isShowing={isShowingAgreementModal}
          tilaContent={tilaContent}
          onAgreeClick={handleAgreeToOfferSummary}
          onCancel={() => setIsShowingAgreementModal(false)}
        />
      )}
    </div>
  )
}

const applicationAgreementsCss = css`
  display: flex;
  flex-direction: column;
  gap: var(--tri-space-450);
  margin-top: var(--tri-space-200);
  margin-bottom: var(--tri-space-450);
`

export default ApplicationAgreements
