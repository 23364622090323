import React, { LegacyRef, RefAttributes, useState } from 'react'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import useOnClickOutside from '@divvy-web/hooks.useonclickoutside'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'

import ImageWrapper from '@sputnik/components/ImageWrapper'
import useDeviceDetect from '@sputnik/hooks/useDeviceDetect'

import { buttonStyles, mobileButtonStyles } from './customerAssistanceButtonStyles'

type UseOnClickOutsideType = RefAttributes<HTMLDivElement> & { ref?: LegacyRef<HTMLDivElement> }

const CustomerAssistanceButton = () => {
  const { isMobile } = useDeviceDetect()
  const [isExpanded, setIsExpanded] = useState(false)
  const [makeTestId] = useNameFormatter('CustomerAssistanceButton')
  const onClick = (e) => {
    setIsExpanded(!isExpanded)
    // Need to manually focus because Safari may not focus on button clicks
    e.target.focus()
  }

  const refOutsideClick = useOnClickOutside(() => {
    setIsExpanded(false)
  }) as UseOnClickOutsideType

  return (
    <div
      ref={refOutsideClick as React.LegacyRef<HTMLDivElement>}
      className='customer-assistance-wrapper'
      css={isMobile ? mobileButtonStyles() : buttonStyles()}
    >
      <BasicButton
        className={`${isExpanded ? 'assistance-button-expanded' : ''} customer-assistance-button`}
        dataTestId={makeTestId('customer-assistance')}
        onClick={onClick}
      >
        <a
          className={`${isExpanded ? 'assistance-button-expanded' : ''} assistance-link`}
          data-testid={makeTestId('assistance-link')}
          href='https://help.bill.com/direct/s/?ref=marketing'
          rel='noopener noreferrer'
          target='_blank'
        >
          <div className='button-content-wrapper'>
            <div className='assistance-icon'>
              <ImageWrapper
                alt='Assistance button'
                dataTestId={makeTestId('assistance-icon')}
                imageName='application-assistance'
              />
            </div>
            <div className={`${isExpanded ? 'assistance-button-expanded' : ''} button-text`}>
              <FormattedMessage
                defaultMessage='Need assistance?'
                id='sputnik.CustomerAssistanceButton__oKsmJc'
              />
            </div>
          </div>
        </a>
      </BasicButton>
    </div>
  )
}

export default CustomerAssistanceButton
