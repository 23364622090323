import React, { ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { css } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'

interface StatusButtonsProps {
  appId?: string
  dataTestId?: string
  hidePrimary?: boolean
  hideSecondary?: boolean
  onPrimaryClick?: () => void
  onSecondaryClick?: () => void
  primaryLoading?: boolean
  primaryPath?: string
  primaryText: ReactNode
  secondaryLoading?: boolean
  secondaryPath?: string
  secondaryText?: ReactNode
}

// TODO: Break each status button into its own component and put where it's used.
const StatusButtons = ({
  appId,
  dataTestId,
  hidePrimary = false,
  hideSecondary = false,
  onPrimaryClick = () => {},
  onSecondaryClick = () => {},
  primaryLoading,
  primaryPath,
  primaryText,
  secondaryLoading,
  secondaryPath,
  secondaryText,
}: StatusButtonsProps) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParams = search ?? ''
  const [getClassName, makeTestId] = useNameFormatter('StatusButtons', { dataTestId })

  const handleSecondaryClick = () => {
    onSecondaryClick()

    secondaryPath &&
      navigate({
        pathname: `/app/${appId}/section/${secondaryPath}`,
        search: searchParams,
      })
  }

  const handlePrimaryClick = () => {
    !!onPrimaryClick && onPrimaryClick()

    primaryPath &&
      navigate(
        {
          pathname: `/app/${appId}/section/${primaryPath}`,
          search: searchParams,
        },
        {
          state: {
            autoRedirectIndex: primaryPath === 'business-info' ? 0 : undefined,
          },
        },
      )
  }

  return (
    <div
      className={getClassName('')}
      css={statusButtonWrapperCss}
      data-testid={makeTestId('')}
    >
      {!hidePrimary && (
        <BasicButton
          className='primary-button'
          dataTestId={makeTestId('primary')}
          disabled={primaryLoading}
          showSpinner={primaryLoading}
          onClick={handlePrimaryClick}
        >
          {primaryText}
        </BasicButton>
      )}

      {!hideSecondary && (
        <BasicButton
          className='secondary-button'
          dataTestId={makeTestId('secondary')}
          disabled={secondaryLoading}
          showSpinner={secondaryLoading}
          type={BASIC_BUTTON_TYPE_OUTLINED}
          onClick={handleSecondaryClick}
        >
          {secondaryText}
        </BasicButton>
      )}
    </div>
  )
}

const statusButtonWrapperCss = css`
  display: flex;
  padding: var(--tri-space-300) 0 0;
  min-width: 480px;

  .secondary-button {
    margin-left: var(--tri-space-400);
  }
`

export default StatusButtons
