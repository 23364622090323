import React from 'react'
import { css } from '@emotion/core'
import { bool, func } from 'prop-types'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_COLOR_NEUTRAL, BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'

import ImageWrapper from '@sputnik/components/ImageWrapper'

const StandardCreditLineInfoMobilePopsheet = ({ isShowing, toggleStandardCreditLineInfoPopsheet }) => {
  const [getClassName, makeTestId] = useNameFormatter('StandardCreditLineInfoMobilePopsheet')

  const dismissButton = (
    <BasicButton
      className={getClassName('standard-popsheet-footer-button')}
      color={BASIC_BUTTON_COLOR_NEUTRAL}
      dataTestId={makeTestId('dismiss-standard-popsheet')}
      type={BASIC_BUTTON_TYPE_FLAT}
      onClick={() => toggleStandardCreditLineInfoPopsheet()}
    >
      <FormattedMessage
        defaultMessage='Dismiss'
        id='sputnik.StandardCreditLineInfoMobilePopsheet__TDaF6J'
      />
    </BasicButton>
  )

  return (
    <PopSheetControl isShowing={isShowing}>
      <PopSheet
        className={getClassName('')}
        css={standardCreditLineMobilePopsheetCss}
        dataTestId={makeTestId('')}
        footer={dismissButton}
        subtitle={
          <FormattedMessage
            defaultMessage="What is a 'standard credit line'?"
            id='sputnik.StandardCreditLineInfoMobilePopsheet__9RNpI8'
          />
        }
        title={
          <ImageWrapper
            alt='virtual-card-shapes'
            className='popsheet-title-image'
            imageName='pre-approved-dual-offer-virtualcard-1'
          />
        }
      >
        <p className={getClassName('standard-popsheet-body-text')}>
          <FormattedMessage
            defaultMessage='A traditional line of credit with a limit that does not fluctuate. Eligibility and credit limit are based on a number of business factors. The credit limit may be increased over time based on activity, payment history and other factors.'
            id='sputnik.StandardCreditLineInfoMobilePopsheet__+mGenJ'
          />
        </p>
      </PopSheet>
    </PopSheetControl>
  )
}

StandardCreditLineInfoMobilePopsheet.propTypes = {
  isShowing: bool,
  toggleStandardCreditLineInfoPopsheet: func,
}

const standardCreditLineMobilePopsheetCss = ({ type }) => css`
  display: flex;
  flex-direction: column;

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-2800);
  }

  .StandardCreditLineInfoMobilePopsheet-popsheet-title-image {
    max-width: var(--tri-space-2800);
  }

  .StandardCreditLineInfoMobilePopsheet-header {
    text-align: center;
  }

  .StandardCreditLineInfoMobilePopsheet-header-subtitle {
    ${type.triFontDesktopTitleMediumEmphasis};
    color: var(--tri-color-text-primary);
  }

  .StandardCreditLineInfoMobilePopsheet-standard-popsheet-footer-button {
    border-radius: 0;
    height: var(--tri-space-1100);
    width: 100%;
    border-top: 1px solid var(--tri-color-stroke-tertiary);
  }
`

export default StandardCreditLineInfoMobilePopsheet
