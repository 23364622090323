import React from 'react'
import { RouterProvider } from 'react-router-dom'

import { useCanary } from '@bill/canary.react'

import { createApplicationRoutes } from './router'

export const App = () => {
  const showCreditAppRedesign = useCanary('rollout-credit-app-redesign') as boolean

  return <RouterProvider router={createApplicationRoutes({ showCreditAppRedesign })} />
}

export default App
