import { LOCAL_STORAGE_KEYS } from '@sputnik/resources/constants'

// clearing localStorage
export const clearAllLocalStorageValues = () => {
  return window.localStorage.clear()
}

export const getAuthIdFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.APPLICANT_AUTH_ID)
}

export const getEmailFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.APPLICANT_EMAIL_KEY)
}

export const getTokenFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_STORAGE_TOKEN_KEY)
}

export const getSalesforceCreditIdsFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.SALESFORCE_CREDIT_IDS)
}

export const setApplicantDataFromUPFromStorage = (value) => {
  return window.localStorage.setItem(LOCAL_STORAGE_KEYS.APPLICANT_DATA_FROM_UP, value)
}

export const getApplicantDataFromUPFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.APPLICANT_DATA_FROM_UP)
}

export const removeApplicantDataFromUPFromStorage = () => {
  return window.localStorage.removeItem(LOCAL_STORAGE_KEYS.APPLICANT_DATA_FROM_UP)
}

export const setLandingPageForStorage = (value) => {
  return window.localStorage.setItem(LOCAL_STORAGE_KEYS.LT_LANDING_PAGE, value)
}

export const getLandingPageFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.LT_LANDING_PAGE)
}
