// include team's acronyms in the description for easier search
export default [
  {
    description: 'when enabled, shorten the time that the timeout warning modal is displayed and active to 15 seconds',
    name: 'reduceAccessTokenTimeout',
    value: false,
  },
  {
    description: 'when enabled, enables new authentication logic',
    name: 'useNewAuth',
    value: false,
  },
  {
    description: 'when enabled, enables advanced onboarding return flow logic',
    name: 'advanced-onboarding-return-flow',
    value: false,
  },
  {
    description: 'UW: shows secured line flow - UW accept offer pages',
    name: 'show-secured-line',
    value: true,
  },
  {
    description: 'COM: shows the AP secured line flow for application (this is separate from show-secured-line)',
    name: 'ap-secured-line',
    value: false,
  },
  {
    description: 'COM: when enabled, show web bank changes',
    name: 'show-web-bank',
    value: false,
  },
  {
    description: 'COM: when enabled, show MFA logic',
    name: 'show-mfa-logic',
    value: false,
  },
  {
    description: 'COM: when enabled, show credit app redesign',
    name: 'rollout-credit-app-redesign',
    value: false,
  },
]
