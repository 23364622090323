export const CLEARED = 'CLEARED'

const YearsInBusiness = {
  TWO_OR_LESS_YEARS: 'TWO_OR_LESS_YEARS',
  TWO_TO_FOUR_YEARS: 'TWO_TO_FOUR_YEARS',
  FIVE_TO_SEVEN_YEARS: 'FIVE_TO_SEVEN_YEARS',
  EIGHT_OR_MORE_YEARS: 'EIGHT_OR_MORE_YEARS',
}

const AnnualRevenue = {
  LESS_THAN_500K: 'LESS_THAN_500K',
  BETWEEN_500K_AND_5_MIL: 'BETWEEN_500K_AND_5_MIL',
  BETWEEN_5_MIL_AND_10_MIL: 'BETWEEN_5_MIL_AND_10_MIL',
  BETWEEN_10_MIL_AND_20_MIL: 'BETWEEN_10_MIL_AND_20_MIL',
  OVER_20_MIL: 'OVER_20_MIL',
}

const CurrentCash = {
  LESS_THAN_25K: 'LESS_THAN_25K',
  BETWEEN_25K_AND_100K: 'BETWEEN_25K_AND_100K',
  BETWEEN_100K_AND_300K: 'BETWEEN_100K_AND_300K',
  OVER_300K: 'OVER_300K',
}

const CreditRange = {
  LESS_THAN_640: 'LESS_THAN_640',
  BETWEEN_640_AND_700: 'BETWEEN_640_AND_700',
  BETWEEN_701_AND_740: 'BETWEEN_701_AND_740',
  OVER_740: 'OVER_740',
  UNREPORTED: 'UNREPORTED',
}

export const yearsInBusinessOptions = [
  {
    label: 'less than 2 years',
    value: YearsInBusiness.TWO_OR_LESS_YEARS,
  },
  {
    label: '2-4 years',
    value: YearsInBusiness.TWO_TO_FOUR_YEARS,
  },
  {
    label: '5-7 years',
    value: YearsInBusiness.FIVE_TO_SEVEN_YEARS,
  },
  {
    label: '8 or more years',
    value: YearsInBusiness.EIGHT_OR_MORE_YEARS,
  },
]

export const annualRevenueOptions = [
  {
    label: 'less than $500k',
    value: AnnualRevenue.LESS_THAN_500K,
  },
  {
    label: '$500k - $5m',
    value: AnnualRevenue.BETWEEN_500K_AND_5_MIL,
  },
  {
    label: '$5-$10m',
    value: AnnualRevenue.BETWEEN_5_MIL_AND_10_MIL,
  },
  {
    label: '$10-$20m',
    value: AnnualRevenue.BETWEEN_10_MIL_AND_20_MIL,
  },
  {
    label: '$20m+',
    value: AnnualRevenue.OVER_20_MIL,
  },
]

export const currentCashOptions = [
  {
    label: 'less than $25k',
    value: CurrentCash.LESS_THAN_25K,
  },
  {
    label: '$25k-$100k',
    value: CurrentCash.BETWEEN_25K_AND_100K,
  },
  {
    label: '$100k-$300k',
    value: CurrentCash.BETWEEN_100K_AND_300K,
  },
  {
    label: '$300k+',
    value: CurrentCash.OVER_300K,
  },
]

export const creditRangeOptions = [
  {
    label: 'less than 640',
    value: CreditRange.LESS_THAN_640,
  },
  {
    label: '640-700',
    value: CreditRange.BETWEEN_640_AND_700,
  },
  {
    label: '701-740',
    value: CreditRange.BETWEEN_701_AND_740,
  },
  {
    label: '741+',
    value: CreditRange.OVER_740,
  },
  {
    label: 'I don’t know/don’t wish to provide',
    value: CreditRange.UNREPORTED,
  },
]
