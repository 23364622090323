import React from 'react'
import { bool, func, node } from 'prop-types'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'
import { useForm } from '@divvy-web/skylab.form'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'
import TextLink from '@divvy-web/skylab.textlink'

import { popsheetStyles } from './styles'

const CreatePasswordPopsheet = ({ children, headerImage, isLoading, isShowing, onLogout, onSubmit, subtitle }) => {
  const [getClassName, makeTestId] = useNameFormatter('CreatePasswordPopsheet')
  const { getFormValue, validationErrors } = useForm()

  const actionButton = (
    <BasicButton
      dataTestId={makeTestId('submit')}
      disabled={!!validationErrors || isLoading}
      showSpinner={isLoading}
      onClick={() => onSubmit(getFormValue('passwordDisplay'))}
    >
      <FormattedMessage
        defaultMessage='Submit'
        id='sputnik.CreatePasswordPopsheet__wSZR47'
      />
    </BasicButton>
  )

  return (
    <PopSheetControl
      css={popsheetStyles}
      isShowing={isShowing}
    >
      <PopSheet
        className={getClassName('')}
        dataTestId={makeTestId('')}
        footer={actionButton}
        subtitle={subtitle}
        title={headerImage}
      >
        <div className={getClassName('')}>
          <TextLink
            className={getClassName('back-button')}
            dataTestId={makeTestId('back-button')}
            onClick={onLogout}
          >
            <FormattedMessage
              defaultMessage='Logout'
              id='sputnik.CreatePasswordPopsheet__C81/uG'
            />
          </TextLink>
          {children}
        </div>
      </PopSheet>
    </PopSheetControl>
  )
}

CreatePasswordPopsheet.propTypes = {
  children: node,
  headerImage: node,
  isLoading: bool,
  isShowing: bool,
  onLogout: func,
  onSubmit: func,
  subtitle: node,
}

export default CreatePasswordPopsheet
