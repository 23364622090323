import { CreditApplication } from '_gql'

import useDidMount from '@divvy-web/hooks.usedidmount'

import { useAuth } from '@sputnik/auth'

interface UseApplicantTokenOnMountProps {
  salesforceCreditId: CreditApplication['salesforceCreditId']
}

const useApplicantTokenOnMount = ({ salesforceCreditId }: UseApplicantTokenOnMountProps) => {
  const { appIdFromToken, requestApplicantToken } = useAuth()

  useDidMount(() => {
    if (appIdFromToken() !== salesforceCreditId) requestApplicantToken(salesforceCreditId)
  })
}

export default useApplicantTokenOnMount
