import { error, info, warn } from '@divvy-web/utils.log'

import { getAuthIdFromStorage, getTokenFromStorage } from '@sputnik/auth/localStorageUtils'

const getCurrentAuthId = () => {
  const token = getTokenFromStorage()
  if (!token) {
    return {}
  }

  const authId = getAuthIdFromStorage()
  if (!authId) {
    return {}
  }

  return { session_id: authId }
}

// Logs error to logging service
export const logError = ({ attributes = {}, eventName = '', message = '' }) => {
  error({
    attributes,
    eventName,
    logType: 'ERROR',
    message,
    ...getCurrentAuthId(),
    squad: 'Vibranium',
  })
}

// Logs warning to logging service
export const logWarning = ({ attributes = {}, eventName = '', message = '' }) => {
  warn({
    attributes,
    eventName,
    logType: 'WARNING',
    message,
    ...getCurrentAuthId(),
    squad: 'Vibranium',
  })
}

// Logs info to logging service
export const logInfo = ({ attributes = {}, eventName = '', message = '' }) => {
  info({
    attributes,
    eventName,
    logType: 'INFO',
    message,
    ...getCurrentAuthId(),
    squad: 'Vibranium',
  })
}
