import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/core'
import parsePhoneNumber, { AsYouType, getCountryCallingCode, isSupportedCountry } from 'libphonenumber-js'
import { Metadata } from 'libphonenumber-js/core'
import minMetadata from 'libphonenumber-js/min/metadata'
import { array, bool, node, string } from 'prop-types'

import { useCanary } from '@bill/canary.react'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import Dropdown from '@divvy-web/skylab.dropdown'
import { FormContext } from '@divvy-web/skylab.form'
import FormField from '@divvy-web/skylab.formfield'
import Icon, { ICON_SIZE_SMALL } from '@divvy-web/skylab.icon'
import ListItem from '@divvy-web/skylab.listitem'
import TextInput from '@divvy-web/skylab.textinput'

import { getCountryFlag, isPhoneNumberVerified } from '@sputnik/components/utils'
import GetCountryCodes from '@sputnik/pages/gql/GetCountryCodes.gql'
import { countriesMapper } from '@sputnik/utils/countryUtils'
import { PhoneNumberFieldInputContext } from '@sputnik/utils/PhoneNumberFieldInputProvider'

const PhoneNumberInput = ({
  alwaysShowError,
  disabled,
  inputId,
  label,
  readOnly,
  showPhoneVerificationIcon,
  supportedCountryCodes = [],
}) => {
  const { getFormValue, validationErrors, setFormValue } = useContext(FormContext)
  const [getClassName, makeTestId] = useNameFormatter(inputId)
  const { data } = useQuery(GetCountryCodes)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const showMfaLogic = useCanary('show-mfa-logic')
  const [initiallySetCountryCode, setInitiallySetCountryCode] = useState(null)
  const [countrySearch, setCountrySearch] = useState(undefined)
  const [phoneNumberMaxLength, setPhoneNumberMaxLength] = useState(20)
  const [shouldFocusOnPhoneNumber, setShouldFocusOnPhoneNumber] = useState(true)
  const inputValue = getFormValue(inputId)
  const errors = validationErrors?.[inputId]?.[0]
  const phoneNumberFieldInputContext = useContext(PhoneNumberFieldInputContext)
  const inputRef = phoneNumberFieldInputContext?.inputRef
  const phoneMetadata = useMemo(() => new Metadata(minMetadata), [])
  selectedCountry && phoneMetadata.selectNumberingPlan(selectedCountry.value)
  const shouldShowPhoneVerificationIcon = showMfaLogic && showPhoneVerificationIcon

  const selectedCountryCodeLength = selectedCountry?.label?.length ?? 7

  const getPhoneVerifiedIcon = () => {
    if (!shouldShowPhoneVerificationIcon || !isPhoneNumberVerified(getFormValue)) return null

    return (
      <Icon
        name='check'
        size={ICON_SIZE_SMALL}
      />
    )
  }

  const getCountryLabel = useCallback(
    (countryCode, callingCode) => `${getCountryFlag(countryCode)} +${callingCode}`,
    [],
  )

  const countries = useMemo(
    () =>
      countriesMapper(data?.countryCodes)
        .filter((item) => {
          return supportedCountryCodes.length
            ? supportedCountryCodes.includes(item.value) && isSupportedCountry(item.value)
            : isSupportedCountry(item.value)
        })
        .map(({ label, value }) => {
          const callingCode = getCountryCallingCode(value)
          return {
            callingCode,
            id: value,
            label: getCountryLabel(value, callingCode),
            name: label,
            value,
          }
        })
        .filter((item) => item.callingCode),
    [data, supportedCountryCodes, getCountryLabel],
  )

  const getFieldValue = useCallback(() => {
    if (!initiallySetCountryCode) return ''

    const callingCodeLength = getCallingCodeLength(selectedCountry)
    const num = inputValue.slice(callingCodeLength)
    const formattedNum = new AsYouType(selectedCountry?.value).input(num)

    // This check handles a bug where the last character is a closing parenthesis user cannot delete it
    return formattedNum.endsWith(')') ? num : formattedNum
  }, [initiallySetCountryCode, inputValue, selectedCountry])

  const handleSelectCountry = (newCountry) => {
    const callingCodeLength = getCallingCodeLength(selectedCountry)
    const domesticNum = inputValue.slice(callingCodeLength)
    setSelectedCountry(newCountry)
    setFormValue(inputId, '+' + getSafeCallingCode(newCountry) + domesticNum)
  }

  const getCallingCodeLength = (country) => {
    const callingCode = country?.callingCode
    return callingCode ? callingCode.length + 1 : 0
  }

  const handleFieldChange = (event) => {
    setFormValue(inputId, '+' + getSafeCallingCode(selectedCountry) + event.target.value)
  }

  const getSafeCallingCode = (country) => country?.callingCode ?? '1'

  const exitCountrySearch = () => {
    if (countrySearch) {
      const countrySearchStr = countrySearch
      setCountrySearch(null)
      const callingCodeLength = getCallingCodeLength(selectedCountry)
      const domesticNum = inputValue.slice(callingCodeLength)
      const newCountry =
        countrySearchStr === '+1'
          ? countries.find((country) => country.value === (parsePhoneNumber('+1' + domesticNum)?.country ?? 'US'))
          : countries.find((country) => '+' + country.callingCode === countrySearchStr)

      if (newCountry) {
        setFormValue(inputId, '+' + getSafeCallingCode(newCountry) + domesticNum)
        setSelectedCountry(newCountry)
      }
    }
    if (shouldFocusOnPhoneNumber) focusOnNumberField()
  }

  const handleTabOut = (event) => {
    if (event.key === 'Tab') {
      setShouldFocusOnPhoneNumber(false)
    }
  }

  const focusOnNumberField = () => {
    if (document?.getElementById instanceof Function) {
      const numberField = document.getElementById(inputId)
      numberField?.focus && numberField.focus()
    }
  }

  useEffect(() => {
    if (initiallySetCountryCode && !countrySearch && phoneMetadata?.numberingPlan?.possibleLengths) {
      const lengthOfNonNumericChars = getFieldValue().match(/[^\d+]/g)?.length ?? 0
      setPhoneNumberMaxLength(Math.max(...phoneMetadata.numberingPlan.possibleLengths()) + lengthOfNonNumericChars)
    }
  }, [getFormValue, inputId, phoneMetadata, countrySearch, inputValue, getFieldValue, initiallySetCountryCode])

  useEffect(() => {
    if (!initiallySetCountryCode && countries.length) {
      const countryCode = parsePhoneNumber(inputValue)?.country
      const country = countries.find((country) => country.value === (countryCode ?? 'US'))
      setSelectedCountry(country)
      if (country && !inputValue.includes('+')) {
        const newFieldValue = '+' + getSafeCallingCode(country) + inputValue
        setFormValue(inputId, newFieldValue)
      }
      setInitiallySetCountryCode(true)
    }
  }, [countries, getFormValue, inputId, inputValue, setFormValue, initiallySetCountryCode])

  return (
    <FormField
      className={`${getClassName('form-field')} fs-unmask`}
      css={formFieldStyle({ selectedCountryCodeLength, readOnly })}
      dataTestId={makeTestId('form-field')}
      disabled={disabled}
      inputId={inputId}
      label={label}
    >
      <div
        ref={inputRef}
        className='fs-mask'
        css={countryInputCss(readOnly)}
      >
        <Dropdown
          hideSearchIcon
          isSearchable
          disabled={disabled}
          inputValue={countrySearch}
          readOnly={readOnly}
          value={selectedCountry}
          onBlur={exitCountrySearch}
          onFocus={() => setShouldFocusOnPhoneNumber(true)}
          onInputValueChanged={(val) => setCountrySearch(val?.toLowerCase())}
          onKeyDown={handleTabOut}
          onSelect={handleSelectCountry}
        >
          {useCallback(
            ({ getItemProps, highlightedIndex }) => {
              const searchCountries = (countries) => {
                if (!countrySearch) return countries
                return countries.filter(({ callingCode, name, value }) =>
                  ('+' + callingCode + name + value).toLowerCase().includes(countrySearch),
                )
              }

              return searchCountries(countries).map((item, index) => (
                <ListItem
                  key={item.label}
                  isHighlighted={highlightedIndex === index}
                  label={item.label}
                  value={item.value}
                  {...getItemProps({
                    index,
                    item,
                  })}
                />
              ))
            },
            [countries, countrySearch],
          )}
        </Dropdown>
      </div>
      <TextInput
        alwaysShowError={alwaysShowError}
        className='fs-mask phone-number-textInput'
        dataTestId={makeTestId('text-input')}
        disabled={disabled}
        errorCaption={errors}
        hasError={!!errors}
        id={inputId}
        inputMode='numeric'
        maxLength={phoneNumberMaxLength}
        name={inputId}
        placeholder={
          readOnly ? (
            ''
          ) : (
            <FormattedMessage
              defaultMessage='Phone number'
              id='sputnik.PhoneNumberInput__jdJhOL'
            />
          )
        }
        readOnly={readOnly}
        rightIcon={getPhoneVerifiedIcon()}
        value={getFieldValue()}
        onChange={handleFieldChange}
      />
    </FormField>
  )
}

PhoneNumberInput.propTypes = {
  alwaysShowError: bool,
  disabled: bool,
  inputId: string.isRequired,
  label: node.isRequired,
  readOnly: bool,
  showPhoneVerificationIcon: bool,
  supportedCountryCodes: array,
}

const formFieldStyle =
  ({ selectedCountryCodeLength, readOnly }) =>
  ({ mq, type }) =>
    css`
      height: 56px;
      ${mq.xSmallMaxWidth({ height: 'var(--tri-space-900)' })}

      .phone-number-textInput {
        margin-left: ${readOnly ? '0' : 'var(--tri-space-1400)'};
        width: -webkit-fill-available;
      }

      .TextInput-read-only .TextInput-input-wrapper {
        padding-left: ${(selectedCountryCodeLength - 1) * 8}px;
      }
    `

const countryInputCss = (readOnly) => css`
  position: absolute;
  width: 120px !important; // unfortunately this is needed to fix a Firefox CSS issue
  padding-top: ${readOnly ? '2px' : '0'};

  .DropdownInputContainer {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ul {
    z-index: 6;
  }

  .SingleSelectDropdown-input-container {
    z-index: 1;
    min-width: auto;
  }

  .SingleSelectDropdown-menu-container {
    width: 100%;
  }
`

export default PhoneNumberInput
