import { useQuery } from '@apollo/client'

import GetSessionInfo from '@sputnik/pages/Dashboard/gql/GetSessionInfo.gql'

export const useBillSessionInfo = (apolloClient) => {
  const { data, loading } = useQuery(GetSessionInfo, {
    client: apolloClient,
    skip: !apolloClient,
  })

  if (loading || !apolloClient) {
    return { data: undefined }
  }

  return {
    data: {
      isConsole: data?.sessionInfo?.isConsole,
      organizationId: data?.sessionInfo?.organizationId,
      organizationName: data?.sessionInfo?.organization?.name,
      ossType: data?.sessionInfo?.organization?.ossType,
    },
  }
}
