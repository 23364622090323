import React, { useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { number } from 'prop-types'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedCurrency, FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'

import CustomerAssistanceButton from '@sputnik/components/CustomerAssistanceButton/CustomerAssistanceButton'
import ImageWrapper from '@sputnik/components/ImageWrapper'
import OneColorSidebar from '@sputnik/components/OneColorSidebar/OneColorSidebar'
import { DecisionContext } from '@sputnik/pages/ApplicationProcessed/DecisionsContext'
import SelectedDecision from '@sputnik/pages/gql/mutations/SelectedDecision.gql'
import { PATHNAME_CONSTS } from '@sputnik/resources/constants'

import { VariableCreditLineOfferCss } from './variableCreditLineOfferStyles'

const VariableCreditLineOffer = ({ desiredCredit }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { appId } = useParams()
  const [getClassName, makeTestId] = useNameFormatter('VariableCreditLineOffer')
  const [selectedDecision] = useMutation(SelectedDecision)
  const showToast = useToast()
  const { decisions } = useContext(DecisionContext)
  const validMethods = new Set(['auto_cash_underwriting', 'manual_cash_underwriting'])
  const variableDecision = decisions?.filter((decision) => validMethods.has(decision.underwritingMethod))
  const approvedAmount = variableDecision?.[0]?.approvedAmountInCents
  const isCounterOffer = approvedAmount !== desiredCredit
  const decisionId = variableDecision?.[0]?.id
  const businessTransactionPath = 'https://app.divvy.co/assets/illustrations/business-transaction-01'
  const businessTransactionSrcSet = `${businessTransactionPath}@3x.png, ${businessTransactionPath}@2x.png, ${businessTransactionPath}@1x.png`
  const counterOfferPath = 'https://app.divvy.co/assets/illustrations/gift-02'
  const counterOfferSrcSet = `${counterOfferPath}@3x.png, ${counterOfferPath}@2x.png, ${counterOfferPath}@1x.png`

  const sidebarImage = isCounterOffer ? counterOfferSrcSet : businessTransactionSrcSet

  const showErrorToast = (message) => {
    showToast(TOAST_TYPE_DANGER, message, { autoHideDelay: 5000 })
  }

  const handleAcceptClick = () => {
    selectedDecision({
      variables: {
        id: decisionId,
        salesforceCreditId: appId,
      },
    })
      .then(() => {
        navigate('next-steps')
      })
      .catch(() =>
        showErrorToast(
          <FormattedMessage
            defaultMessage='Error accepting pre approved amount.'
            id='sputnik.VariableCreditLineOffer__cxmoLn'
          />,
        ),
      )
  }

  const handleBackToDashboardClick = () => {
    navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
  }

  const handlePreviousClick = () => {
    navigate(-1)
  }

  const isComingFromDualOffer = location.pathname.includes('dual-offer')

  return (
    <>
      <div css={VariableCreditLineOfferCss}>
        <CustomerAssistanceButton />
        <div className='main-container fs-unmask'>
          <main className={getClassName('page-content')}>
            <div
              className={getClassName('body')}
              data-testid={makeTestId('')}
            >
              <p
                className={getClassName('title')}
                data-testid={makeTestId('title')}
              >
                <FormattedMessage
                  defaultMessage="{isCounterOffer, select,
                    true {We've got an offer for you!}
                    other {Congrats! You're pre-approved.}
                  }"
                  id='sputnik.VariableCreditLineOffer__LX55sV'
                  values={{
                    isCounterOffer,
                  }}
                />
              </p>
              {/* The image directly below is only to be rendered once the mobile media query is hit */}
              <div>
                {isCounterOffer && (
                  <section
                    className={getClassName('mobile-splash-businessTransaction-image-div')}
                    data-testid={makeTestId('mobile-splash-counter-offer-image')}
                  >
                    <ImageWrapper
                      alt='giving-gift'
                      className={getClassName('mobile-splash-counter-offer-image')}
                      imageName='counter-offer-mobile'
                    />
                  </section>
                )}
                {!isCounterOffer && (
                  <section
                    className={getClassName('mobile-splash-businessTransaction-image-div')}
                    data-testid={makeTestId('mobile-splash-businessTransaction-image')}
                  >
                    <ImageWrapper
                      alt='business-transaction'
                      className={getClassName('mobile-splash-business-transaction-image')}
                      imageName='credit-line-offer-mobile'
                    />
                  </section>
                )}
              </div>
              <p className={getClassName('subtitle')}>
                <FormattedMessage
                  defaultMessage='Variable credit line:'
                  id='sputnik.VariableCreditLineOffer__LSxgXn'
                />
              </p>
              <div
                className={getClassName('approved-amount')}
                data-testid={makeTestId('approved-amount')}
              >
                <FormattedCurrency
                  maximumFractionDigits={0}
                  minimumFractionDigits={2}
                  value={approvedAmount}
                />
              </div>
              <div
                className={getClassName('approved-amount-caption')}
                data-testid={makeTestId('approved-caption')}
              >
                {isCounterOffer && (
                  <p
                    className='counter-offer-details'
                    data-testid={makeTestId('counter-offer-details')}
                  >
                    <FormattedMessage
                      defaultMessage='With the information provided in the application we were unable to approve you for the requested amount of {desiredCredit}.'
                      id='sputnik.VariableCreditLineOffer__BSHMT4'
                      values={{
                        desiredCredit: (
                          <FormattedCurrency
                            maximumFractionDigits={0}
                            minimumFractionDigits={2}
                            value={desiredCredit}
                          />
                        ),
                      }}
                    />
                  </p>
                )}
                <p className={getClassName('variable-credit-line-content-title')}>
                  <FormattedMessage
                    defaultMessage='Details of your variable credit line offer.'
                    id='sputnik.VariableCreditLineOffer__hev4cz'
                  />
                </p>
                <div
                  className={getClassName('variable-credit-line-details-list')}
                  data-testid={makeTestId('list')}
                >
                  <ul>
                    <li data-testid={makeTestId('list-item-1')}>
                      <FormattedMessage
                        defaultMessage='Your credit limit may fluctuate based on the cash balance of the bank account(s).'
                        id='sputnik.VariableCreditLineOffer__VfBdGQ'
                      />
                    </li>
                    <li data-testid={makeTestId('list-item-2')}>
                      <FormattedMessage
                        defaultMessage='When your bank balance changes, your credit limit may increase or decrease accordingly.'
                        id='sputnik.VariableCreditLineOffer__ab8UrG'
                      />
                    </li>
                    <li data-testid={makeTestId('list-item-3')}>
                      <FormattedMessage
                        defaultMessage="We'll use a secure digital banking connection to review your account balance(s) and determine any changes to your credit limit."
                        id='sputnik.VariableCreditLineOffer__psLjhr'
                      />
                    </li>
                  </ul>
                </div>
                <p className={getClassName('variable-credit-line-body-content')}>
                  <FormattedMessage
                    defaultMessage='This is the pre-approved credit line amount for your company. When you accept this offer, we will complete the final review of your application and begin setting up your account. Remember that your balance will be due in full at the end of each billing cycle.'
                    id='sputnik.VariableCreditLineOffer__uVKJ+r'
                  />
                </p>
              </div>
              <p className={getClassName('body-caption')}>
                <FormattedMessage
                  defaultMessage='Pre-approval is based on the information currently available and is contingent upon final review of your application.'
                  id='sputnik.VariableCreditLineOffer__VjFJsV'
                />
              </p>
              <section className={getClassName('buttons')}>
                <BasicButton
                  className={getClassName('accept')}
                  dataTestId={makeTestId('variable-credit-line-accept')}
                  onClick={handleAcceptClick}
                >
                  <FormattedMessage
                    defaultMessage='Accept offer'
                    id='sputnik.VariableCreditLineOffer__/xro5W'
                  />
                </BasicButton>
                {!isComingFromDualOffer ? (
                  <BasicButton
                    className={getClassName('dashboard')}
                    dataTestId={makeTestId('variable-credit-line-dashboard')}
                    type={BASIC_BUTTON_TYPE_FLAT}
                    onClick={handleBackToDashboardClick}
                  >
                    <FormattedMessage
                      defaultMessage='Back to dashboard'
                      id='sputnik.VariableCreditLineOffer__Na5xdK'
                    />
                  </BasicButton>
                ) : (
                  <BasicButton
                    className={getClassName('previous')}
                    dataTestId={makeTestId('variable-credit-line-previous')}
                    type={BASIC_BUTTON_TYPE_FLAT}
                    onClick={handlePreviousClick}
                  >
                    <FormattedMessage
                      defaultMessage='Previous'
                      id='sputnik.VariableCreditLineOffer__JJNc3c'
                    />
                  </BasicButton>
                )}
              </section>
            </div>
          </main>
        </div>
        <OneColorSidebar
          className={isCounterOffer ? 'counter-offer-side-bar-image' : ''}
          srcImage={sidebarImage}
        />
      </div>
    </>
  )
}

VariableCreditLineOffer.propTypes = {
  desiredCredit: number,
}

export default VariableCreditLineOffer
