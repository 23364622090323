import React from 'react'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'

interface ImageWrapperProps {
  dataTestId?: string
  className?: string
  assetsPath?: string // default will be 'illustrations'
  imageName: string // file name without extension ex: 'bill-logo-color'
  isSvg?: boolean
  alt?: string // making optional for legacy components, but for new stuff, please pass one in.
}
const ImageWrapper = ({
  dataTestId = '',
  className = '',
  imageName,
  isSvg = false,
  alt,
  assetsPath = 'illustrations',
}: ImageWrapperProps) => {
  const [makeTestId, getClassName] = useNameFormatter('ImageWrapper')
  const imagePath = `https://app.divvy.co/assets/${assetsPath}/` + imageName
  const altName = alt ? alt : 'program-img'

  if (isSvg) {
    return (
      <img
        alt={altName}
        className={`program-image ${getClassName(className)}`}
        data-testid={makeTestId(dataTestId)}
        src={`${imagePath}.svg`}
      />
    )
  }

  const defaultImg = `${imagePath}@1x.png`
  const mediumImg = `${imagePath}@2x.png`
  const largeImg = `${imagePath}@3x.png`
  const imageSrcSet = `${mediumImg}, ${largeImg}`

  return (
    <img
      alt={altName}
      className={`program-image ${getClassName(className)}`}
      data-testid={makeTestId(dataTestId)}
      src={defaultImg}
      srcSet={imageSrcSet}
    />
  )
}

export default ImageWrapper
