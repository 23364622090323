import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'
import Modal from '@divvy-web/skylab.modal'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'

import CustomerAssistanceButton from '@sputnik/components/CustomerAssistanceButton/CustomerAssistanceButton'
import ImageWrapper from '@sputnik/components/ImageWrapper'
import OneColorSidebar from '@sputnik/components/OneColorSidebar/OneColorSidebar'
import GetAuthorizedSigner from '@sputnik/pages/gql/GetAuthorizedSigner.gql'
import { PATHNAME_CONSTS } from '@sputnik/resources/constants'

import SuccessFrozenCreditPopsheet from './SuccessFrozenCreditPopsheet'
import { modalCss, successFrozenCreditContainer } from './successFrozenCreditStyles'

const SuccessFrozenCredit = () => {
  const { appId } = useParams()
  const navigate = useNavigate()
  const [getClassName, makeTestId] = useNameFormatter('SuccessFrozenCredit')
  const [isShowing, setIsShowing] = useState(false)
  const [isShowingPopsheet, setIsShowingPopsheet] = useState(false)

  const { data } = useQuery(GetAuthorizedSigner, {
    variables: { creditApplicationId: appId },
  })

  const peopleBalloonPath = 'https://app.divvy.co/assets/illustrations/people-balloon-01-01'
  const peopleBalloonSrcSet = `${peopleBalloonPath}@3x.png, ${peopleBalloonPath}@2x.png, ${peopleBalloonPath}@1x.png`

  const authorizedSignerFirstName = data?.creditApplication?.authorizedSigner?.firstName
  const authorizedSignerLastName = data?.creditApplication?.authorizedSigner?.lastName

  const hasAuthorizedSigner = authorizedSignerFirstName && authorizedSignerLastName

  const authorizedSigner = hasAuthorizedSigner
    ? `${authorizedSignerFirstName} ${authorizedSignerLastName}`
    : 'The signer'

  const navigateToDashboard = () => {
    navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
  }

  const navigateToExperianFreezeCenter = () => {
    window.open('https://www.experian.com/freeze/center.html', '_blank')
  }

  const learnMoreLink = () => {
    window.open('http://help.getdivvy.com/en/articles/6156143', '_blank')
  }

  const onToggleModal = () => {
    setIsShowing(!isShowing)
  }

  const onTogglePopsheet = () => {
    setIsShowingPopsheet(!isShowingPopsheet)
  }

  const modalActions = [
    <TextLink
      key='learn-more'
      dataTestId={makeTestId('learn-more-link')}
      size={TextLinkSizes.REGULAR}
      onClick={learnMoreLink}
    >
      <FormattedMessage
        defaultMessage='Learn more'
        id='sputnik.SuccessFrozenCredit__TdTXXf'
      />
    </TextLink>,
    <BasicButton
      key='go-to-experian'
      dataTestId={makeTestId('go-to-experian')}
      onClick={navigateToExperianFreezeCenter}
    >
      <FormattedMessage
        defaultMessage='Go to Experian'
        id='sputnik.SuccessFrozenCredit__OF1DEA'
      />
    </BasicButton>,
  ]

  return (
    <>
      <div css={successFrozenCreditContainer}>
        <CustomerAssistanceButton />
        <div className='main-container fs-unmask'>
          <main className={getClassName('page-content')}>
            <div
              className={getClassName('body')}
              data-testid={makeTestId('body')}
            >
              {/* The image directly below is only to be rendered once the mobile media query is hit */}
              <div
                className={getClassName('frozen-credit-mobile-image-div')}
                data-testid={makeTestId('frozen-credit-mobile-image-div')}
              >
                <ImageWrapper
                  alt='person-holding-a-balloon'
                  className='frozen-credit-mobile-image'
                  imageName='frozen-credit-mobile'
                />
              </div>
              <p
                className={getClassName('body-title')}
                data-testid={makeTestId('body-title')}
              >
                <FormattedMessage
                  defaultMessage='Your credit is frozen'
                  id='sputnik.SuccessFrozenCredit__hKuIGJ'
                />
              </p>
              <p
                className={`${getClassName('body-subtitle')} fs-mask`}
                data-testid={makeTestId('body-subtitle')}
              >
                <FormattedMessage
                  defaultMessage="{authorizedSigner}'s credit is frozen. Please remove the security freeze with Experian and try again."
                  id='sputnik.SuccessFrozenCredit__A6AZd3'
                  values={{ authorizedSigner }}
                />
              </p>
              <p
                className={getClassName('body-caption')}
                data-testid={makeTestId('body-caption')}
              >
                <FormattedMessage
                  defaultMessage="We never do hard inquiries on credit. However, we do a soft pull on the authorized signer's credit to verify identity and gather information for the application process. Please lift your security freeze with Experian and then visit the application dashboard to resubmit your saved application."
                  id='sputnik.SuccessFrozenCredit__x8paU8'
                />
              </p>
              <div
                className={getClassName('buttons-and-links')}
                data-testid={makeTestId('buttons-and-links')}
              >
                <BasicButton
                  className={getClassName('close')}
                  dataTestId={makeTestId('close')}
                  onClick={navigateToDashboard}
                >
                  <FormattedMessage
                    defaultMessage='Close'
                    id='sputnik.SuccessFrozenCredit__rbrahO'
                  />
                </BasicButton>
                <TextLink
                  className={getClassName('desktop-experian-link')}
                  dataTestId={makeTestId('desktop-experian-link')}
                  size={TextLinkSizes.SMALL}
                  onClick={onToggleModal}
                >
                  <FormattedMessage
                    defaultMessage='How do I lift my credit freeze?'
                    id='sputnik.SuccessFrozenCredit__tp7iH9'
                  />
                </TextLink>
                <TextLink
                  className={getClassName('mobile-experian-link')}
                  dataTestId={makeTestId('mobile-experian-link')}
                  size={TextLinkSizes.SMALL}
                  onClick={onTogglePopsheet}
                >
                  <FormattedMessage
                    defaultMessage='How do I lift my credit freeze?'
                    id='sputnik.SuccessFrozenCredit__tp7iH9'
                  />
                </TextLink>
              </div>
            </div>
          </main>
        </div>
        <OneColorSidebar srcImage={peopleBalloonSrcSet} />
        <Modal
          actions={modalActions}
          className={getClassName('frozen-credit-modal')}
          css={modalCss}
          dataTestId={makeTestId('frozen-credit-modal')}
          isShowing={isShowing}
          title={
            <FormattedMessage
              defaultMessage='Contact Experian'
              id='sputnik.SuccessFrozenCredit__PnveGM'
            />
          }
          onCloseClick={onToggleModal}
        >
          <FormattedMessage
            defaultMessage='We use Experian to run all credit. The best way to unfreeze your credit is to visit the Experian {experianLink} and submit a request to remove the freeze. Removing a credit freeze can take up to 6 hours to process.'
            id='sputnik.SuccessFrozenCredit__8omSup'
            values={{
              experianLink: (
                <TextLink
                  size={TextLinkSizes.REGULAR}
                  onClick={navigateToExperianFreezeCenter}
                >
                  {' '}
                  <FormattedMessage
                    defaultMessage='security freeze center'
                    id='sputnik.SuccessFrozenCredit__xUwWNg'
                  />
                </TextLink>
              ),
            }}
          />
        </Modal>
        <SuccessFrozenCreditPopsheet
          isShowingPopsheet={isShowingPopsheet}
          togglePopsheet={onTogglePopsheet}
        />
      </div>
    </>
  )
}

export default SuccessFrozenCredit
