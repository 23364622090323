import { css } from '@emotion/core'

import { curry } from '@divvy-web/utils'

export const formElementWrapperStyles = curry(({ isMac, isMaskedFormInput, readOnly }, { mq }) => {
  return css`
    .FormField,
    .SingleSelectDropdown {
      height: 56px;
      width: ${readOnly && isMaskedFormInput && !isMac ? 'fit-content' : ''};
      ${mq.xSmallMaxWidth({ height: '72px', width: '100%' })}
    }

    .FormField span {
      right: ${readOnly && isMaskedFormInput && '0'};
      ${mq.xSmallMaxWidth({ marginTop: 'calc(var(--tri-space-100) * -1);', right: '0' })};
    }

    .FormField ul {
      z-index: 6;
    }

    .Icon-warningFilled {
      color: var(--tri-color-text-danger);
    }
  `
})
