import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { css } from '@emotion/core'

import useDidMount from '@divvy-web/hooks.usedidmount'
import { FormattedMessage } from '@divvy-web/i18n'
import Spinner from '@divvy-web/skylab.spinner'

import { useAuth } from '@sputnik/auth'
import EmailAccessCodeForm from '@sputnik/components/EmailAccessCodeForm/EmailAccessCodeForm'
import MiscPageWrapper from '@sputnik/components/MiscPageWrapper/MiscPageWrapper'
import PageViewWrapper from '@sputnik/components/PageViewWrapper/PageViewWrapper'
import { PAGE_NAME_STRINGS } from '@sputnik/resources/constants'
import { logInfo } from '@sputnik/utils/loggerUtils'

const VerifyCode = () => {
  const [loading, setLoading] = useState(true)
  const { email, goToAuth, requestAuthCode, setReferrer, verifyAuthCode } = useAuth()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const params = {
    accessCode: queryParams.get('access_code')?.trim(),
    email: queryParams.get('email')?.trim()?.replace(/ /g, '+'),
    referrer: queryParams.get('referrer'),
  }

  useDidMount(() => {
    if (params?.email && params?.accessCode) {
      verifyAuthCode(params?.accessCode, params?.email, params?.referrer || null, search).catch(() => {
        requestAuthCodeFromQueryParams()
      })
    } else if (params?.email) {
      requestAuthCodeFromQueryParams()
      if (params?.referrer) {
        setReferrer(params?.referrer)
      }
    } else if (!email) {
      goToAuth(true)
    } else {
      setLoading(false)
    }

    logInfo({
      attributes: {
        result: 'Verify access code page loaded',
      },
      eventName: 'PageLoad',
    })
  })

  const requestAuthCodeFromQueryParams = () => {
    requestAuthCode(params?.email)
      .then(() => setLoading(false))
      .catch(() => goToAuth(true))
  }

  if (loading) return <Spinner fullPage />

  return (
    <PageViewWrapper pageName={PAGE_NAME_STRINGS.PAGE_VERIFY_CODE}>
      <div css={mainContentWrapper}>
        <MiscPageWrapper
          headingStyles={null}
          pageName='VerifyEmail'
          pageStyles={null}
          pageSubTitle={
            <FormattedMessage
              defaultMessage="We're so excited you're here!"
              id='sputnik.VerifyCode__dg2Cyr'
            />
          }
          pageTitle={
            <FormattedMessage
              defaultMessage='Welcome'
              id='sputnik.VerifyCode__PwaN2o'
            />
          }
        >
          <div data-testid='access-code'>{<EmailAccessCodeForm />}</div>
        </MiscPageWrapper>
      </div>
    </PageViewWrapper>
  )
}

const mainContentWrapper = css`
  position: relative;
  height: 100vh;
`

export default VerifyCode
