/* global __DivvyEnvironment */
import React, { useContext, useEffect, useState } from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { array, bool, func, node, string } from 'prop-types'

import { FormContext, FormTextInput } from '@divvy-web/skylab.form'

import AddressSuggestions from '@sputnik/components/AddressSuggestions'
import { parseAddressDetails, parseState } from '@sputnik/components/AddressSuggestions/utils'
import FormElementWrapper from '@sputnik/components/FormInputs/FormElementWrapper'
import { ADDRESS_DEBOUNCE_TIME } from '@sputnik/resources/constants'

const StreetInput = ({
  alwaysShowError = false,
  caption,
  companyOwnersClass = '',
  disabled = false,
  getInputName,
  isForeignAddress = false,
  label,
  readOnly = false,
  stateOptions = [],
}) => {
  const { setFormValue, setFormValueFromEvent } = useContext(FormContext)
  const [showAutoComplete, setShowAutoComplete] = useState(false)
  const [selectedAddressIdx, setSelectedAddress] = useState(null)

  const [streetKey, streetAdditionalKey, cityKey, stateKey, postalCodeKey] = [
    'Street',
    'StreetAdditional',
    'City',
    'State',
    'PostalCode',
  ].map(getInputName)

  const { getPlacePredictions, placePredictions, placesService } = usePlacesService({
    apiKey: __DivvyEnvironment.GOOGLE_PLACES_API_KEY ?? 'sandbox',
    debounce: ADDRESS_DEBOUNCE_TIME,
    options: {
      componentRestrictions: { country: ['us'] },
      types: ['address'],
    },
  })

  const handleAutocompleteClick = (placeId) => {
    if (!placesService || !placeId) return setShowAutoComplete(false)
    placesService.getDetails({ placeId }, saveAutocompletedAddressDetails)
  }

  const saveAutocompletedAddressDetails = (addressDetails) => {
    const parsedAddress = parseAddressDetails(addressDetails)
    if (Reflect.ownKeys(parsedAddress).length < 1) return

    const {
      // We only provide predictions for US addresses at this time
      // country: countryCode,
      administrative_area_level_1: state,
      locality: city,
      postal_code: zip,
      route,
      street_number: street,
    } = parsedAddress

    const streetString = `${street?.longName ?? ''}${route?.longName ? ` ${route?.longName}` : ''}`.trim()

    setFormValue(streetKey, streetString)
    setFormValue(cityKey, city?.longName ?? '')
    setFormValue(stateKey, parseState(stateOptions, state))
    setFormValue(postalCodeKey, zip?.longName ?? '')

    if (document?.getElementById instanceof Function) {
      const streetAdditionalElem = document.getElementById(streetAdditionalKey)
      streetAdditionalElem?.focus && streetAdditionalElem.focus()
    }

    setShowAutoComplete(false)
  }

  const handleChange = (event) => {
    if (!isForeignAddress) {
      getPlacePredictions({ input: event.target.value })
      setShowAutoComplete(true)
    }
    return setFormValueFromEvent(event)
  }

  useEffect(() => {
    if (showAutoComplete) {
      setSelectedAddress(0)
    }
  }, [showAutoComplete])

  const handleAddressSelect = (event) => {
    switch (event?.key) {
      case 'Enter':
        handleAutocompleteClick(placePredictions[selectedAddressIdx].place_id)
        break
      case 'ArrowDown':
        setSelectedAddress((selectedAddressIdx + 1) % placePredictions.length)
        break
      case 'ArrowUp': {
        const newSelectedAddress = selectedAddressIdx - 1
        setSelectedAddress(newSelectedAddress >= 0 ? newSelectedAddress : placePredictions.length - 1)
      }
    }
  }

  const handleTabOut = (event) => {
    if (event?.key === 'Tab') {
      setShowAutoComplete(false)
    }
  }

  return (
    <div className={`field-row ${companyOwnersClass}`}>
      <div className={`field-container-full fs-unmask ${companyOwnersClass}`}>
        <FormElementWrapper>
          <FormTextInput
            alwaysShowError={alwaysShowError}
            caption={caption}
            className='fs-mask'
            disabled={disabled}
            label={label}
            maxLength={99}
            name={streetKey}
            placeholder={label}
            readOnly={readOnly}
            onChange={handleChange}
            onKeyDown={handleTabOut}
            onKeyUp={handleAddressSelect}
          />
        </FormElementWrapper>
        {showAutoComplete && (
          <AddressSuggestions
            addressSuggestions={placePredictions}
            selectedAddressIdx={selectedAddressIdx}
            onItemClick={handleAutocompleteClick}
            onOutsideClick={() => setShowAutoComplete(false)}
          />
        )}
      </div>
    </div>
  )
}

StreetInput.propTypes = {
  alwaysShowError: bool,
  caption: node,
  companyOwnersClass: string,
  disabled: bool,
  getInputName: func.isRequired,
  isForeignAddress: bool,
  label: node.isRequired,
  readOnly: bool,
  stateOptions: array,
}

export default StreetInput
