import React from 'react'
import { css } from '@emotion/core'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'

const BillSideBarIcon = () => {
  const billLogo = 'https://app.divvy.co/assets/logos/bill-logo-color'
  const defaultLogo = `${billLogo}@1x.png`
  const mediumLogo = `${billLogo}@2x.png`
  const largeLogo = `${billLogo}@3x.png`

  const [getClassName] = useNameFormatter('BillSideBarIcon')
  return (
    <div
      className={getClassName('')}
      css={billLogoContainerCss}
    >
      <img
        alt='bill-logo'
        className='bill-logo-img'
        data-testid='image-bill-logo'
        src={defaultLogo}
        srcSet={`${mediumLogo}, ${largeLogo}`}
      />
    </div>
  )
}

const billLogoContainerCss = css`
  align-self: flex-end;
  height: 34px;
  margin: var(--tri-space-500);

  img {
    width: 52px;
  }
`

export default BillSideBarIcon
