import { useCallback, useContext, useState } from 'react'

import { FormContext } from '@divvy-web/skylab.form'

import { MANUAL_ID } from '@sputnik/pages/FinanceInfo/financeInfoConstants'

const useFinanceInfoHooks = () => {
  const { getFormValue, setFormValue } = useContext(FormContext)
  const [isUnavailableBankModalShowing, setIsUnavailableBankModalShowing] = useState(false)

  const maybeSetBankNameAndRoutingNumber = useCallback(
    (bankAccounts) => {
      const routingNumbers = bankAccounts
        .map((c) => c.routingNumber)
        .filter((value, index, self) => self.indexOf(value) === index)

      if (!getFormValue('routingNumber') && routingNumbers.length === 1 && routingNumbers[0].length === 9) {
        setFormValue('routingNumber', routingNumbers[0])
      }

      const bankNames = bankAccounts
        .map((c) => c.bankName)
        .filter((value, index, self) => self.indexOf(value) === index)

      if (!getFormValue('bankName') && bankNames.length === 1) {
        setFormValue('bankName', bankNames[0])
      }
    },
    [getFormValue, setFormValue],
  )

  const setSelectedExternalId = useCallback((id) => setFormValue('selectedExternalId', id), [setFormValue])

  const switchToManualBankOption = useCallback(() => {
    setSelectedExternalId(MANUAL_ID)
  }, [setSelectedExternalId])

  const handleUnverifiedBankOptions = useCallback(
    (bankAccounts) => {
      setIsUnavailableBankModalShowing(true)
      maybeSetBankNameAndRoutingNumber(bankAccounts)
      switchToManualBankOption()
    },
    [maybeSetBankNameAndRoutingNumber, switchToManualBankOption],
  )

  const closeUnavailableBankModal = useCallback(() => {
    setIsUnavailableBankModalShowing(false)
  }, [])

  const clearSelectedExternalId = useCallback(() => {
    setSelectedExternalId(null)
  }, [setSelectedExternalId])

  return {
    clearSelectedExternalId,
    closeUnavailableBankModal,
    handleUnverifiedBankOptions,
    isUnavailableBankModalShowing,
    setSelectedExternalId,
    switchToManualBankOption,
  }
}

export default useFinanceInfoHooks
