import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { arrayOf, number, shape, string } from 'prop-types'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedCurrency, FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_COLOR_NEUTRAL, BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Icon from '@divvy-web/skylab.icon'
import TextLink from '@divvy-web/skylab.textlink'
import Tile from '@divvy-web/skylab.tile'

import CustomerAssistanceButton from '@sputnik/components/CustomerAssistanceButton/CustomerAssistanceButton'
import ImageWrapper from '@sputnik/components/ImageWrapper'
import { PATHNAME_CONSTS } from '@sputnik/resources/constants'

import StandardCreditLineInfoMobilePopsheet from './StandardCreditLineInfoMobilePopsheet'
import SuccessPreApprovedDualOfferSidebar from './SuccessPreApprovedDualOfferSidebar'
import { successPreApprovedDualOfferCss } from './successPreApprovedDualOfferStyles'
import VariableCreditLineInfoMobilePopsheet from './VariableCreditLineInfoMobilePopsheet'

const SuccessPreApprovedDualOffer = ({ filteredStandardOffer, filteredVariableOffer }) => {
  const navigate = useNavigate()
  const [isShowingStandardPopsheet, setIsShowingStandardPopsheet] = useState(false)
  const [isShowingVariablePopsheet, setIsShowingVariablePopsheet] = useState(false)
  const [isStandardOfferSelected, setIsStandardOfferSelected] = useState(false)
  const [isVariableOfferSelected, setIsVariableOfferSelected] = useState(false)
  const [getClassName, makeTestId] = useNameFormatter('SuccessPreApprovedDualOffer')

  const standardApprovedAmount = filteredStandardOffer?.[0]?.approvedAmountInCents
  const variableApprovedAmount = filteredVariableOffer?.[0]?.approvedAmountInCents

  const handleStandardOfferTileClick = () => {
    setIsStandardOfferSelected(!isStandardOfferSelected)
    setIsVariableOfferSelected(false)
  }

  const handleVariableOfferTileClick = () => {
    setIsVariableOfferSelected(!isVariableOfferSelected)
    setIsStandardOfferSelected(false)
  }

  const isOfferSelected = isStandardOfferSelected || isVariableOfferSelected

  const handleNextClick = () => {
    if (isVariableOfferSelected === true) {
      navigate('variable-pre-approved')
    } else {
      navigate('standard-pre-approved')
    }
  }

  const toggleStandardCreditLineInfoPopsheet = () => {
    setIsShowingStandardPopsheet(!isShowingStandardPopsheet)
  }

  const toggleVariableCreditLineInfoPopsheet = () => {
    setIsShowingVariablePopsheet(!isShowingVariablePopsheet)
  }

  const handleBackToDashboardClick = () => {
    navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
  }

  return (
    <>
      <div css={successPreApprovedDualOfferCss}>
        <CustomerAssistanceButton />
        <div className='main-container fs-unmask'>
          <main className={getClassName('page-content')}>
            <div className={getClassName('body')}>
              <p
                className={getClassName('title')}
                data-testid={makeTestId('title')}
              >
                <FormattedMessage
                  defaultMessage="Congrats! You're pre-approved!"
                  id='sputnik.SuccessPreApprovedDualOffer__U+gg8L'
                />
              </p>
              <p className={getClassName('subtitle')}>
                <FormattedMessage
                  defaultMessage='Welcome to the BILL Spend & Expense family'
                  id='sputnik.SuccessPreApprovedDualOffer__daQEiN'
                />
              </p>
              <div className={getClassName('tile-captions-and-content')}>
                <p className={getClassName('tile-caption')}>
                  <FormattedMessage
                    defaultMessage='You have been approved for the following credit options. Please select your preferred option below:'
                    id='sputnik.SuccessPreApprovedDualOffer__/4zAR+'
                  />
                </p>
                <Tile
                  className={getClassName('standard-credit-line-tile')}
                  dataTestId={makeTestId('standard-credit-line')}
                  isSelected={isStandardOfferSelected}
                  selectedColor='primary'
                  onClick={handleStandardOfferTileClick}
                >
                  <div
                    className={getClassName('standard-credit-line-tile-content')}
                    data-testid={makeTestId('standard-credit-line-tile-content')}
                  >
                    <div className={getClassName('standard-credit-line-tile-image-wrapper')}>
                      <ImageWrapper
                        alt='security-lock'
                        className='standard-credit-line-tile-image'
                        imageName='spot-security-lock'
                      />
                    </div>
                    <div>
                      <p className={getClassName('standard-credit-line-tile-approved-amount')}>
                        <FormattedCurrency
                          maximumFractionDigits={0}
                          minimumFractionDigits={2}
                          value={standardApprovedAmount}
                        />
                      </p>
                      <p className={getClassName('standard-credit-line-tile-title')}>
                        <FormattedMessage
                          defaultMessage='Standard credit line'
                          id='sputnik.SuccessPreApprovedDualOffer__WQCeHH'
                        />
                      </p>
                      <p className={getClassName('standard-credit-line-tile-text')}>
                        <FormattedMessage
                          defaultMessage='Credit limit is fixed and does not fluctuate.'
                          id='sputnik.SuccessPreApprovedDualOffer__4S6qkx'
                        />
                      </p>
                    </div>
                  </div>
                </Tile>
                {/* The Icon and TextLink will only render on media query hit */}
                <div className={getClassName('standard-mobile-popsheet-information')}>
                  <Icon
                    dataTestId={makeTestId('standard-credit-line-mobile-info')}
                    name='info'
                    size='large'
                  />
                  <TextLink
                    className={getClassName('standard-credit-line-textlink-caption')}
                    dataTestId={makeTestId('standard-credit-line-mobile-info')}
                    onClick={() => toggleStandardCreditLineInfoPopsheet()}
                  >
                    <FormattedMessage
                      defaultMessage="What is a 'standard credit line'?"
                      id='sputnik.SuccessPreApprovedDualOffer__9RNpI8'
                    />
                  </TextLink>
                </div>
                <Tile
                  className={getClassName('variable-credit-line-tile')}
                  dataTestId={makeTestId('variable-credit-line')}
                  isSelected={isVariableOfferSelected}
                  selectedColor='primary'
                  onClick={handleVariableOfferTileClick}
                >
                  <div
                    className={getClassName('variable-credit-line-tile-content')}
                    data-testid={makeTestId('variable-credit-line-tile-content')}
                  >
                    <div className={getClassName('variable-credit-line-tile-image-wrapper')}>
                      <ImageWrapper
                        alt='device-payment'
                        className={getClassName('variable-credit-line-tile-image')}
                        imageName='spot-device-payment'
                      />
                    </div>
                    <div>
                      <p className={getClassName('variable-credit-line-tile-approved-amount')}>
                        <FormattedCurrency
                          maximumFractionDigits={0}
                          minimumFractionDigits={2}
                          value={variableApprovedAmount}
                        />
                      </p>
                      <p className={getClassName('variable-credit-line-tile-title')}>
                        <FormattedMessage
                          defaultMessage='Variable credit line'
                          id='sputnik.SuccessPreApprovedDualOffer__8ugHZu'
                        />
                      </p>
                      <p className={getClassName('variable-credit-line-tile-text')}>
                        <FormattedMessage
                          defaultMessage='Credit limit may fluctuate based on the cash balance in your bank account.'
                          id='sputnik.SuccessPreApprovedDualOffer__Ia3Yam'
                        />
                      </p>
                    </div>
                  </div>
                </Tile>
                {/* The Icon and TextLink will only render on media query hit */}
                <div className={getClassName('variable-mobile-popsheet-information')}>
                  <Icon
                    dataTestId={makeTestId('variable-credit-line-mobile-info')}
                    name='info'
                    size='large'
                  />
                  <TextLink
                    className={getClassName('variable-credit-line-textlink-caption')}
                    dataTestId={makeTestId('variable-credit-line-mobile-info')}
                    onClick={() => toggleVariableCreditLineInfoPopsheet()}
                  >
                    <FormattedMessage
                      defaultMessage="What is a 'variable credit line'?"
                      id='sputnik.SuccessPreApprovedDualOffer__x83Whl'
                    />
                  </TextLink>
                </div>
              </div>
            </div>
            <div className={getClassName('dashboard-and-next-button-group')}>
              <BasicButton
                className={getClassName('dashboard-button')}
                color={BASIC_BUTTON_COLOR_NEUTRAL}
                dataTestId={makeTestId('back-to-dashboard')}
                type={BASIC_BUTTON_TYPE_FLAT}
                onClick={handleBackToDashboardClick}
              >
                <FormattedMessage
                  defaultMessage='Back to dashboard'
                  id='sputnik.SuccessPreApprovedDualOffer__Na5xdK'
                />
              </BasicButton>
              <BasicButton
                className={getClassName('next-button')}
                dataTestId={makeTestId('next-button')}
                disabled={!isOfferSelected}
                onClick={handleNextClick}
              >
                <FormattedMessage
                  defaultMessage='Next'
                  id='sputnik.SuccessPreApprovedDualOffer__9+Ddtu'
                />
              </BasicButton>
            </div>
          </main>
        </div>
        <SuccessPreApprovedDualOfferSidebar />
        {/* The popsheets will only render in the mobile view */}
        <div className={getClassName('mobile-popsheets')}>
          <StandardCreditLineInfoMobilePopsheet
            isShowing={isShowingStandardPopsheet}
            toggleStandardCreditLineInfoPopsheet={toggleStandardCreditLineInfoPopsheet}
          />
          <VariableCreditLineInfoMobilePopsheet
            isShowing={isShowingVariablePopsheet}
            toggleVariableCreditLineInfoPopsheet={toggleVariableCreditLineInfoPopsheet}
          />
        </div>
      </div>
    </>
  )
}

SuccessPreApprovedDualOffer.propTypes = {
  filteredStandardOffer: arrayOf(
    shape({
      approvedAmountInCents: number,
      companyId: string,
      creditLimitId: string,
      id: string,
      underwritingMethod: string,
      underwritingVersion: string,
    }),
  ),
  filteredVariableOffer: arrayOf(
    shape({
      approvedAmountInCents: number,
      companyId: string,
      creditLimitId: string,
      id: string,
      underwritingMethod: string,
      underwritingVersion: string,
    }),
  ),
}

export default SuccessPreApprovedDualOffer
