import { sharedFields } from '@sputnik/pages/SignUp/signupValidations'
import { logError } from '@sputnik/utils/loggerUtils'

import authorizedSignerValidations from './authorizedSignerValidations'
import businessValidations from './businessValidations'
import companyOwnerValidations from './companyOwnerValidations'
import financeValidations from './financeValidations'

export default ({ formValues, owner, pageName, requestedCorrections }) => {
  switch (pageName) {
    case 'business-info':
      return businessValidations
    case 'authorized-signer':
      return authorizedSignerValidations
    case 'finance-info':
      return financeValidations({ recordType: formValues?._recordType, requestedCorrections })
    case 'company-owners':
      return companyOwnerValidations(owner, formValues)
    case 'review-and-sign':
      return {
        ...sharedFields,
        ...businessValidations,
        ...authorizedSignerValidations,
        ...financeValidations({ recordType: formValues?._recordType, requestedCorrections }),
        ...companyOwnerValidations(owner, formValues),
      }
    default:
      logError({
        attributes: {
          message: `No validations for ${pageName}`,
        },
        eventName: 'GetValidations',
      })
      return {}
  }
}
