import React from 'react'

import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT, BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import { TOAST_TYPE_BASIC, useToast } from '@divvy-web/skylab.toast'

import { useAuth } from '@sputnik/auth'
import { logoutBill, navigateToBillLogin } from '@sputnik/utils/billUtils'
import { logInfo } from '@sputnik/utils/loggerUtils'

interface LogoutButtonProps {
  bdcOrgId?: string
  dataTestId?: string
  buttonType?: typeof BASIC_BUTTON_TYPE_FLAT | typeof BASIC_BUTTON_TYPE_OUTLINED
}
const LogoutButton = ({
  bdcOrgId,
  buttonType = BASIC_BUTTON_TYPE_FLAT,
  dataTestId = 'LogoutButton',
}: LogoutButtonProps) => {
  const { logout, resetAuth } = useAuth()
  const showBasicToast = useToast(TOAST_TYPE_BASIC) as any

  const handlePostLogout = () => {
    showBasicToast(
      <FormattedMessage
        defaultMessage='Log out successful!'
        id='sputnik.LogoutButton__VckeyC'
      />,
      {
        autoHideDelay: 5000,
        dataTestId: 'logout-success-toast',
      },
    )
    logInfo({
      attributes: {
        action: 'handleOnClick',
        result: 'logging user out of the application',
      },
      eventName: 'logout',
    })
  }

  const handleOnClick = async () => {
    if (bdcOrgId) {
      await logoutBill(bdcOrgId)
      resetAuth()
      handlePostLogout()
      navigateToBillLogin(bdcOrgId)
    } else {
      logout()
      handlePostLogout()
    }
  }

  return (
    <BasicButton
      className='logout-btn'
      dataTestId={dataTestId}
      name='logout'
      type={buttonType}
      onClick={handleOnClick}
    >
      <FormattedMessage
        defaultMessage='Log out'
        id='sputnik.LogoutButton__PlBReU'
      />
    </BasicButton>
  )
}

export default LogoutButton
