import React from 'react'

import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'

import { useAuth } from '@sputnik/auth'
import { StatusButtons } from '@sputnik/components'
import { onContactUsClick } from '@sputnik/components/ContactUsButton/contactUsUtils'
import ImageWrapper from '@sputnik/components/ImageWrapper'
import StatusTile from '@sputnik/pages/Dashboard/StatusTile/StatusTile'

interface StatusErrorTileProps {
  refetch: () => void
}

const StatusErrorTile = ({ refetch }: StatusErrorTileProps) => {
  const makeTestId = useMakeTestId('StatusErrorTile')
  const { email } = useAuth()

  return (
    <div data-testid={makeTestId('')}>
      <StatusTile
        isAnErrorTile
        image={
          <ImageWrapper
            alt='customer service representative with headset and speech bubble'
            imageName='dashboard-application-error-status'
          />
        }
        primaryInfo={
          <FormattedMessage
            defaultMessage='Oops... something went wrong. We weren’t able to find an application under the email {email}. Please
          try again or begin a new application.'
            id='sputnik.StatusErrorTile__LtF1Jj'
            values={{ email }}
          />
        }
        title={
          <FormattedMessage
            defaultMessage='Error'
            id='sputnik.StatusErrorTile__KN7zKn'
          />
        }
      >
        <StatusButtons
          appId={''}
          primaryText={
            <FormattedMessage
              defaultMessage='Contact us'
              id='sputnik.StatusErrorTile__WnQ/AZ'
            />
          }
          secondaryText={
            <FormattedMessage
              defaultMessage='Try again'
              id='sputnik.StatusErrorTile__FazwRl'
            />
          }
          onPrimaryClick={onContactUsClick}
          onSecondaryClick={refetch}
        />
      </StatusTile>
    </div>
  )
}

export default StatusErrorTile
