import React, { useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { useCanary } from '@bill/canary.react'
import useDidMount from '@divvy-web/hooks.usedidmount'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import Form, { FormTextInput } from '@divvy-web/skylab.form'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'

import { setMaybeNeedsNewPassword, useAuth } from '@sputnik/auth'
import { setApplicantDataFromUPFromStorage, setLandingPageForStorage } from '@sputnik/auth/localStorageUtils'
import { FormButtons } from '@sputnik/components'
import FormElementWrapper from '@sputnik/components/FormInputs/FormElementWrapper'
import InformativeBanner from '@sputnik/components/InformativeBanner'
import MiscPageWrapper from '@sputnik/components/MiscPageWrapper/MiscPageWrapper'
import PageViewWrapper from '@sputnik/components/PageViewWrapper/PageViewWrapper'
import PrivacyPolicyLink from '@sputnik/components/PrivacyPolicyLink'
import RecaptchaDisclaimer from '@sputnik/components/RecaptchaDisclaimer'
import TermsAndConditionsLink from '@sputnik/components/TermsAndConditionsLink/TermsAndConditionsLink'
import { PAGE_NAME_STRINGS, PATHNAME_CONSTS, PRODUCT_CONST_STRINGS, URL } from '@sputnik/resources/constants'
import { removeDealSourceIdFromStorage } from '@sputnik/utils/dealSourceUtils'
import { logInfo } from '@sputnik/utils/loggerUtils'

import { headingStyles, signinStyles } from './signInStyles'
import { signinValidation } from './signInValidation'

const SignIn = () => {
  const migrationPath = useRef(false)
  const navigate = useNavigate()
  const useNewAuth = useCanary('useNewAuth')
  const showWebBank = useCanary('show-web-bank')
  const [makeTestId] = useNameFormatter('SignIn')
  const { getAuthenticationMethod, requestAuthCode, setEmail, setToken } = useAuth()
  const { pathname, search, state } = useLocation()
  const [hasError, setHasError] = useState(false)
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(true)

  const { BILL_SPEND_AND_EXPENSE, BILL_DIVVY_CARD, BILL, DIVVY_PAY_LLC } = PRODUCT_CONST_STRINGS
  const { PAGE_SIGN_IN } = PAGE_NAME_STRINGS
  const { DIVVY_PROD } = URL
  /* This can be self / forced or na
   * if self we want to show the logged out successfully msg
   * if forced we want to show logged out banner with instructions
   * if na the user has landed on the page for the first time and
   * we do not want to show either
   */
  const logoutType = state?.logout ?? 'na'
  const [unAuthReason, setUnAuthReason] = useState(logoutType)
  const isMigration = migrationPath.current
  const resetError = () => hasError && setHasError(false)

  const executeGetAuthMethod = (email) => {
    getAuthenticationMethod(email).then((authMethod) => {
      if (authMethod === 'PASSWORD') {
        navigate(PATHNAME_CONSTS.VERIFY_PASSWORD_PATH, { state: { email } })
      } else {
        useNewAuth && setMaybeNeedsNewPassword()
        executeRequestAuthCode(email)
      }
    })
  }

  const executeRequestAuthCode = (email) =>
    requestAuthCode(email)
      .then(() => {
        logInfo({
          attributes: {
            action: 'requestAuthCode',
            result: 'Sending user to verify access code page',
          },
          eventName: 'SignIn',
        })
        navigate(
          {
            pathname: PATHNAME_CONSTS.VERIFY_EMAIL_PATH,
            search: search,
          },
          { state },
        )
      })
      .catch(() => {
        setHasError(true)
        setLoading(false)
      })

  const handleSubmit = (_, values) => {
    setLoading(true)
    const { signInEmail } = values

    logInfo({
      attributes: {
        action: 'handleSubmit',
        result: 'Sign in button clicked',
      },
      eventName: 'SignIn',
    })

    useNewAuth ? executeGetAuthMethod(signInEmail) : executeRequestAuthCode(signInEmail)
    setEmail(signInEmail)
  }

  const clearHistoryState = () => {
    // If the user refreshes the browser we do not want to show logout messages
    // a second time.  This will clear the history state without a render.

    navigate(
      {
        pathname: migrationPath.current ? PATHNAME_CONSTS.MIGRATION_PATH : PATHNAME_CONSTS.AUTH_PATH,
        search: search ?? '',
      },
      {
        replace: true,
        state: { logout: 'na' },
      },
    )
  }

  useDidMount(() => {
    migrationPath.current = pathname === PATHNAME_CONSTS.MIGRATION_PATH
    setUnAuthReason(logoutType)
    clearHistoryState()
    removeDealSourceIdFromStorage()

    logInfo({
      attributes: {
        result: 'Sign In page loaded',
      },
      eventName: 'PageLoad',
    })

    if (search) {
      const getDecodedString = () => {
        try {
          return JSON.parse(atob(search?.slice(1)))
        } catch (error) {
          return []
        }
      }
      const decodedSearchArray = getDecodedString()
      const getApplicantData = () => {
        return decodedSearchArray.find((item) => {
          return item?.applicantData
        })
      }

      const hasApplicantData = !!getApplicantData()
      if (hasApplicantData) {
        setApplicantDataFromUPFromStorage(search)
      }

      const getLtLandingPage = () => {
        const ltItem = decodedSearchArray.find((item) => item.lt && item.lt.landingPage)
        return ltItem ? ltItem.lt.landingPage : ''
      }

      const hasLandingPage = !!getLtLandingPage()
      if (hasLandingPage) setLandingPageForStorage(getLtLandingPage())
    }

    if (searchParams.get('referrer') === 'mobile-doc-upload' || searchParams.get('authToken')) {
      setToken(searchParams.get('authToken'))
      navigate({ pathname: PATHNAME_CONSTS.MOBILE_DOCUMENT_UPLOAD_PATH })
    }

    if (searchParams.get('email')) {
      executeRequestAuthCode(searchParams.get('email'))
    }
  })

  const showLogoutMessage = () => {
    if (unAuthReason === 'forced') {
      logInfo({
        attributes: {
          action: 'showLogoutMessage',
          message: 'forced logout as session timed out',
          result: 'Sign In page loaded',
        },
        eventName: 'PageLoad',
      })

      return (
        <InformativeBanner
          isDismissable
          isVisible={isVisible}
          message={
            <FormattedMessage
              defaultMessage='Sign in to continue your application'
              id='sputnik.SignIn__7L6VK1'
            />
          }
          title={
            <FormattedMessage
              defaultMessage='Your session has timed out.'
              id='sputnik.SignIn__fSlbLN'
            />
          }
          onClose={() => setIsVisible(false)}
        />
      )
    }
    return null
  }

  return (
    <PageViewWrapper pageName={PAGE_SIGN_IN}>
      <MiscPageWrapper
        headingStyles={headingStyles}
        pageName={PAGE_SIGN_IN}
        pageStyles={signinStyles}
        pageSubTitle={
          <FormattedMessage
            defaultMessage='Enter your email below to start your application, pick up where you left off, or check the status of a submitted application.'
            id='sputnik.SignIn__EFJVRU'
          />
        }
        pageTitle={
          <FormattedMessage
            defaultMessage='{showWebBank, select, 
            true {Welcome to {BILL_SPEND_AND_EXPENSE}! Start your {BILL_DIVVY_CARD} application today!}
            other {Welcome to the {BILL_SPEND_AND_EXPENSE} application}
            }'
            id='sputnik.SignIn__SShePs'
            values={{ BILL_SPEND_AND_EXPENSE, BILL_DIVVY_CARD, showWebBank }}
          />
        }
      >
        {showLogoutMessage()}
        <div
          className='sign-in-form'
          data-testid='auth-page'
        >
          <Form
            dataTestId='sign-in-form'
            validation={signinValidation}
            onChange={resetError}
            onSubmit={handleSubmit}
          >
            <div className='field-container fs-unmask'>
              <FormElementWrapper
                errorCaption={
                  <FormattedMessage
                    defaultMessage='An error has occured. Please contact a sales representative.'
                    id='sputnik.SignIn__v/JjHw'
                  />
                }
                hasError={hasError}
              >
                <FormTextInput
                  dataTestId='SignIn-Field'
                  label={
                    <FormattedMessage
                      defaultMessage='Enter your business email'
                      id='sputnik.SignIn__7/X4+G'
                    />
                  }
                  name='signInEmail'
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Enter your business email'
                      id='sputnik.SignIn__7/X4+G'
                    />
                  }
                  type='email'
                />
              </FormElementWrapper>
            </div>
            <FormButtons
              hideExit
              hidePrevious
              disableNext={loading || hasError}
              nextButtonType='submit'
              pageName={PAGE_SIGN_IN}
              showNextSpinner={loading && !hasError}
            />
          </Form>
          <div className='disclaimers-container'>
            <div className='terms-and-conditions fs-unmask'>
              <FormattedMessage
                defaultMessage='By continuing, you agree to {BILL_SPEND_AND_EXPENSE}’s {termsLink} and {privacyLink}.'
                id='sputnik.SignIn__/HqPJQ'
                values={{
                  BILL_SPEND_AND_EXPENSE,
                  privacyLink: <PrivacyPolicyLink />,
                  termsLink: <TermsAndConditionsLink />,
                }}
              />
            </div>
            {!isMigration && (
              <div className='already-use fs-unmask'>
                <FormattedMessage
                  defaultMessage='Already use {BILL_SPEND_AND_EXPENSE}? {divvyAppLink}'
                  id='sputnik.SignIn__4o7PXu'
                  values={{
                    divvyAppLink: (
                      <TextLink
                        href={DIVVY_PROD}
                        size={TextLinkSizes.REGULAR}
                        target='_blank'
                      >
                        <FormattedMessage
                          defaultMessage='Click here to sign in'
                          id='sputnik.SignIn__/uaxMj'
                        />
                      </TextLink>
                    ),

                    BILL_SPEND_AND_EXPENSE,
                  }}
                />
              </div>
            )}
            <RecaptchaDisclaimer />
            {showWebBank && (
              <div
                className='bill-description'
                data-testid={makeTestId('bill-description')}
              >
                <FormattedMessage
                  defaultMessage='{BILL} is a financial technology company, not a bank, and provides the software that brings you the features and solutions of {BILL_SPEND_AND_EXPENSE}. 
                WebBank, Cross River Bank, member FDIC, or Wex Bank are the issuers of the {BILL_DIVVY_CARD}.  
                References to "we," "us," and "our" refer to the applicable issuing bank and/or {DIVVY_PAY_LLC} as the servicer.'
                  id='sputnik.SignIn__7JkrM+'
                  values={{
                    BILL,
                    BILL_DIVVY_CARD,
                    BILL_SPEND_AND_EXPENSE,
                    DIVVY_PAY_LLC,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </MiscPageWrapper>
    </PageViewWrapper>
  )
}

export default SignIn
