import React from 'react'
import { bool, func } from 'prop-types'

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'

import ImageWrapper from '@sputnik/components/ImageWrapper'

import { successFrozenCreditPopsheetContainer } from './successFrozenCreditStyles'

const SuccessFrozenCreditPopsheet = ({ isShowingPopsheet, togglePopsheet }) => {
  const [getClassName, makeTestId] = useNameFormatter('SuccessFrozenCreditPopsheet')

  const navigateToExperianFreezeCenter = () => {
    window.open('https://www.experian.com/freeze/center.html', '_blank')
  }

  const learnMoreLink = () => {
    window.open('http://help.getdivvy.com/en/articles/6156143', '_blank')
  }

  const popSheetActions = (
    <BasicButton
      name='cancel'
      type={BASIC_BUTTON_TYPE_FLAT}
      onClick={togglePopsheet}
    >
      <FormattedMessage
        defaultMessage='Cancel'
        id='sputnik.SuccessFrozenCreditPopsheet__47FYwb'
      />
    </BasicButton>
  )

  return (
    <PopSheetControl
      css={successFrozenCreditPopsheetContainer}
      isShowing={isShowingPopsheet}
      onScrimClick={togglePopsheet}
    >
      <PopSheet
        actions={popSheetActions}
        className={getClassName('frozen-credit-popsheet')}
        title=''
      >
        <div className='fs-unmask'>
          <div className={getClassName('popsheet-body')}>
            <div className={getClassName('mobile-popsheet-image-wrapper')}>
              <ImageWrapper
                alt='group-looking-at-phone'
                className='contact-experian-mobile-image'
                imageName='contact-experian-mobile'
              />
            </div>
            <div className={getClassName('popsheet-text-content')}>
              <p
                className={getClassName('popsheet-body-title')}
                data-testid={makeTestId('popsheet-body-title')}
              >
                <FormattedMessage
                  defaultMessage='Contact Experian'
                  id='sputnik.SuccessFrozenCreditPopsheet__PnveGM'
                />
              </p>
              <p className={getClassName('popsheet-copy')}>
                <FormattedMessage
                  defaultMessage='We use Experian to run all credit. The best way to unfreeze your credit is to visit the Experian {experianLink} and submit a request to remove the freeze. Removing a credit freeze can take up to 6 hours to process.'
                  id='sputnik.SuccessFrozenCreditPopsheet__8omSup'
                  values={{
                    experianLink: (
                      <TextLink
                        className={getClassName('experian-freeze-center-link')}
                        size={TextLinkSizes.REGULAR}
                        onClick={navigateToExperianFreezeCenter}
                      >
                        {' '}
                        <FormattedMessage
                          defaultMessage='security freeze center'
                          id='sputnik.SuccessFrozenCreditPopsheet__xUwWNg'
                        />
                      </TextLink>
                    ),
                  }}
                />
              </p>
              <TextLink
                className={getClassName('learn-more-link')}
                size={TextLinkSizes.SMALL}
                onClick={learnMoreLink}
              >
                <FormattedMessage
                  defaultMessage='Learn More'
                  id='sputnik.SuccessFrozenCreditPopsheet__7DIW6+'
                />
              </TextLink>
            </div>
          </div>
          <BasicButton
            className={getClassName('go-to-experian-button')}
            onClick={navigateToExperianFreezeCenter}
          >
            <FormattedMessage
              defaultMessage='Go to Experian'
              id='sputnik.SuccessFrozenCreditPopsheet__OF1DEA'
            />
          </BasicButton>
        </div>
      </PopSheet>
    </PopSheetControl>
  )
}

SuccessFrozenCreditPopsheet.propTypes = {
  isShowingPopsheet: bool,
  togglePopsheet: func,
}

export default SuccessFrozenCreditPopsheet
