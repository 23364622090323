import React from 'react'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import PropTypes from 'prop-types'

import GlobalThemeProvider from '@divvy-web/skylab.globalthemeprovider'
import SkylabThemeProvider from '@divvy-web/skylab.themeprovider'

const ThemeProvider = ({ children }) => {
  const themeName = 'bill'
  return (
    <SkylabThemeProvider
      applyGlobals
      theme={themeName}
    >
      {({ theme }) => (
        <>
          <GlobalThemeProvider
            applyTokensToDocument
            theme={themeName}
          >
            <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
          </GlobalThemeProvider>
        </>
      )}
    </SkylabThemeProvider>
  )
}

ThemeProvider.displayName = 'SputnikThemeProvider'

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default ThemeProvider
