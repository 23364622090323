import React from 'react'
import { Outlet } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { node } from 'prop-types'

import { IntlProvider } from '@divvy-web/i18n'
import { ToastProvider } from '@divvy-web/skylab.toast'

import client from './apollo/ApolloClient'
import { AuthProvider } from './auth'
import { GlobalToastStyle } from './components/Page/PageGlobalStyles'
import PageErrorBoundary from './components/PageErrorBoundary'
import Root from './components/Root'
import ThemeProvider from './components/theme-provider'

const AppProvider = () => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider apolloClient={client}>
        <ThemeProvider>
          <IntlProvider
            locale='en'
            textComponent='span'
          >
            <GlobalToastStyle />
            <ToastProvider>
              <Root>
                <PageErrorBoundary>
                  <Outlet />
                </PageErrorBoundary>
              </Root>
            </ToastProvider>
          </IntlProvider>
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

AppProvider.propTypes = {
  children: node,
}

export default AppProvider
