import { css } from '@emotion/core'

import { curry } from '@divvy-web/utils'

const sharedButtonStyles = curry(
  ({ mq, type }) => css`
    z-index: 1000;
    position: fixed;
    bottom: 20px;
    left: 20px;
    ${mq.xSmallMaxWidth({
      bottom: '96px',
      left: 'auto',
      right: '16px',
    })}
    height: 48px;
    max-width: 48px;
    transition: max-width 500ms;
    padding: 0 var(--tri-space-100);
    background: var(--tri-color-icon-primary);

    .button-content-wrapper {
      display: flex;
      .assistance-icon {
        position: absolute;
        top: -6px;
        left: -6px;
        .program-image {
          width: 64px;
          rotate: 12deg;
        }
      }
      .button-text {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding-right: var(--tri-space-100);
        margin-left: var(--tri-space-500);
        ${type.triFontDesktopLabelMedium};
        opacity: 0;
        transition: opacity 100ms linear 50ms;
      }
    }
  `,
)

export const mobileButtonStyles = curry(
  ({ mq, type }) => css`
    .customer-assistance-button {
      ${sharedButtonStyles({ mq, type })}
      .assistance-button-expanded.assistance-link {
        pointer-events: auto;
        cursor: pointer;
        text-decoration: none;
        color: var(--tri-color-fill-primary);
      }

      .assistance-link {
        pointer-events: none;
        cursor: default;
        text-decoration: none;
        color: var(--tri-color-fill-primary);
      }
    }

    .assistance-button-expanded.customer-assistance-button {
      max-width: 300px;
      background: var(--tri-color-icon-primary);
      .assistance-button-expanded.button-text {
        transition: opacity 100ms linear 300ms;
        opacity: 1;
      }
    }
  `,
)

export const buttonStyles = curry(
  ({ mq, type }) => css`
    .customer-assistance-button {
      ${sharedButtonStyles({ mq, type })}

      .assistance-link {
        text-decoration: none;
        color: var(--tri-color-fill-primary);
      }
      .button-content-wrapper .button-text {
        span {
          max-height: 0;
          transition: max-height 200ms;
        }
      }
    }

    .customer-assistance-button:hover {
      max-width: 300px;
      background: var(--tri-color-icon-primary);
      .button-text {
        transition: opacity 100ms linear 300ms;
        opacity: 1;
        span {
          max-height: 200px;
        }
      }
    }
  `,
)
