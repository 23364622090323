import { initialValues } from '@sputnik/pages/FormPages/initialValues'
import { CREDIT_APPLICATION, MIGRATION, PAGE_NAME_STRINGS } from '@sputnik/resources/constants'

const sectionHasErrors = (sectionValues, validationErrors) => {
  return Object.keys(sectionValues).some((fieldName) => {
    return !!validationErrors?.[fieldName]
  })
}

export const getSectionHasSaved = (formValues, pageIndex) => {
  const savedSections = formValues?.savedSections || formValues?._savedSections

  switch (pageIndex) {
    case 0:
      return savedSections?.includes('BUSINESS_INFO')
    case 1:
      return savedSections?.includes('FINANCE_INFO')
    case 2:
      return savedSections?.includes('AUTH_SIGNER')
    case 3:
      return savedSections?.includes('COMPANY_OWNERS')
  }
}

export const getAppCompletionPercentage = (creditApp, validationErrors) => {
  const isMigration = creditApp?.status?.includes(MIGRATION)

  const creditAppValues = initialValues(creditApp, CREDIT_APPLICATION)
  const businessFields = initialValues(creditApp, PAGE_NAME_STRINGS.PAGE_BUSINESS_INFO)
  const financeFields = initialValues(creditApp, PAGE_NAME_STRINGS.PAGE_FINANCE_INFO)
  const authSignerFields = initialValues(creditApp, PAGE_NAME_STRINGS.PAGE_AUTHORIZED_SIGNER)

  const businessInfoComplete =
    !sectionHasErrors(businessFields, validationErrors) && getSectionHasSaved(creditAppValues, 0)

  const financeInfoComplete =
    isMigration || (!sectionHasErrors(financeFields, validationErrors) && getSectionHasSaved(creditAppValues, 1))

  const authorizedSignerComplete =
    !sectionHasErrors(authSignerFields, validationErrors) && getSectionHasSaved(creditAppValues, 2)

  const companyOwnersComplete = creditAppValues?.majorityHolders && getSectionHasSaved(creditAppValues, 3)

  const sectionsCompleted = [
    true, // in order to avoid showing 0% on a started app, adding a constant true here
    businessInfoComplete,
    financeInfoComplete,
    authorizedSignerComplete,
    companyOwnersComplete,
  ]

  const numberOfSectionsCompleted = sectionsCompleted.filter((section) => !!section).length

  return numberOfSectionsCompleted * 18
}
